import React, { useEffect, useState } from "react";
import { store } from "../redux/store";
import styled from "styled-components";
import { navigate } from "@reach/router";
import { connect } from "react-redux";
import { format } from "date-fns";
import { checkUserLoggedIn } from "../helperFunctions.js";
import { xcpihOrXcpi, parseXcpiGrades } from "../utilities";
import NavigationTab from "../components/NavigationTab";
import CompetitionTable from "../components/CompetitionTable";
import LoadingSpinner from "../components/LoadingSpinner";
import PreCompetitionWatchlist from "../components/PreCompetitionWatchlist";
import Explainer from "../components/Explainer";
import { theme, Spacer, Text, Dropdown } from "equiratings-components";

import {
  getUpcomingCompetitionOverview,
  getUpcomingCompetitionWatchlists,
  getGrades
} from "../services/preCompetition.js";

const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  justify-content: space-between;
`;

const PreCompetition = ({ selectedUpcomingCompetition }) => {
  useEffect(() => {
    checkUserLoggedIn();
  }, []);

  const [compOverview, setOverview] = useState([]);
  const [competition, setCompetition] = useState(undefined);
  const [watchlists, setWatchlists] = useState([]);
  const [watchlistErr, setWatchlistErr] = useState(false);
  const [overviewErr, setOverviewErr] = useState(false);
  const [classTitle, setClassTitle] = useState();
  const [grades, setGrades] = useState([]);

  useEffect(() => {
    getGrades()
      .then((response) => setGrades(parseXcpiGrades(response.data.data)))
      .catch((error) => console.log("ERROR", error));

  }, []);

  useEffect(() => {
    getUpcomingCompetitionOverview(
      competition && [competition],
      selectedUpcomingCompetition.attributes.venue_id
    )
      .then((response) => setOverview(response.data.data))
      .catch((error) => setOverviewErr(true));
    getUpcomingCompetitionWatchlists(
      selectedUpcomingCompetition.id,
      competition
    )
      .then((response) => setWatchlists(response.data.data))
      .catch((error) => setWatchlistErr(true));
  }, [
    competition,
    selectedUpcomingCompetition.attributes.venue_id,
    selectedUpcomingCompetition.id,
  ]);

  const dataIsPresent = compOverview.length > 0;

  const xcpihOrXcpiTitle = xcpihOrXcpi(
    store.getState().UserState.providerId,
    "ERQI 2.0",
    "HFI"
  )

  return dataIsPresent ? (
    <>
      <NavigationTab />
      <Explainer
        text={`The following page shows two tables. The first, is a year on year
          risk overview of your upcoming show. The second, shows watchlists
          which display all horses that are entering with a red
          ${xcpihOrXcpiTitle}, across all competitions, as well as the
          watchlists which show every competitor at a given competition, and
          their ${xcpihOrXcpiTitle} value going into said competition.`}
      />
      <Spacer size={theme.spacing.x4} />
      <TitleContainer>
        <Text
          tag="h1"
          size={[theme.fontScale.x5]}
          weight={theme.fontWeights.bold}
          lineHeight={theme.lineHeights.heading}
        >
          {selectedUpcomingCompetition.attributes.name}{" "}
          {format(selectedUpcomingCompetition.attributes.start_date, "YYYY")}
        </Text>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/pre-competition")}
        >
          <p>{"<"}</p>
        </div>
      </TitleContainer>
      <Spacer size={theme.spacing.x2} />
      <Text
        tag="h1"
        size={[theme.fontScale.x4]}
        weight={theme.fontWeights.normal}
        lineHeight={theme.lineHeights.heading}
      >
        {format(
          selectedUpcomingCompetition.attributes.start_date,
          "DD/MM/YYYY"
        )}
      </Text>
      <Spacer size={theme.spacing.x5} />
      <Wrapper>
        <Text
          tag="h1"
          size={[theme.fontScale.x4]}
          weight={theme.fontWeights.bold}
          lineHeight={theme.lineHeights.heading}
        >
          Choose a Competition
        </Text>
        <Spacer size={theme.spacing.x3} />
        <Dropdown
          onChange={(level) => {
            setCompetition(level.er_level);
            setClassTitle(level.title);
          }}
          readOnly={true}
          items={selectedUpcomingCompetition.attributes.er_levels}
          itemToString={(level) => level.title}
          initialItem={selectedUpcomingCompetition.attributes.er_levels[0]}
          handleFilter={() => () => true}
          displaySuggestion={(level) => (
            <Text
              tag="h1"
              size={[theme.fontScale.x4]}
              weight={theme.fontWeights.normal}
              lineHeight={theme.lineHeights.heading}
            >
              {level.title}
            </Text>
          )}
        />
      </Wrapper>
      <Spacer size={theme.spacing.x5} />
      <CompetitionTable
        selectedComp={selectedUpcomingCompetition}
        rows={compOverview}
        requestFailed={overviewErr}
      />
      <Spacer size={theme.spacing.x5} />
      <PreCompetitionWatchlist
        selectedCompetition={selectedUpcomingCompetition}
        compClass={classTitle}
        watchlists={watchlists}
        grades={grades}
        requestFailed={watchlistErr}
      />
    </>
  ) : (
    <LoadingSpinner />
  );
};

export default connect(
  ({ PostCompetitionState }) => ({
    selectedUpcomingCompetition:
      PostCompetitionState.selectedUpcomingCompetition,
  }),
  {}
)(PreCompetition);
