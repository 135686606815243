import React from 'react'
import styled from 'styled-components'
import { Text, Spacer, theme } from 'equiratings-components'

const Container = styled.div`
  background: ${theme.colors.white};
  border-radius: ${theme.spacing.x2};
  padding: ${theme.spacing.x3};
  border-left: 6px solid ${theme.colors.primary};
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  margin-bottom: ${theme.spacing.x2};
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  width: 100%;
  flex-direction: column;
  cursor: pointer;
  align-items: flex-start;
  @media (${theme.breakpoints.notSmall}) {
    flex-direction: row;
  }
`

const Byte = styled.div`
 display: flex;
 flex-direction: column;
 align-items: center;
 max-width: 100px;
 margin-bottom: ${theme.spacing.x3};
 &:last-child {
  margin-bottom: 0;
 }
 @media (${theme.breakpoints.notSmall}) { 
  margin-bottom: 0;
  align-items: flex-start;
 }
`


const XcpiByLvl = ({ bytes }) => {
  const setXCPICategory = (value) => {
    let xcpi = value.toFixed(2)
    if(xcpi >= 5) {
        // A++
      return {
        border:'4px solid rgba(97, 202, 49, 1)',
        tooltip: 'A++'
      }
    } else if (xcpi >=4 && xcpi < 5) {
        // A+
      return {
        border: '4px solid rgba(97, 202, 49, 0.7)',
        tooltip: 'A+'
      }    
    } else if (xcpi >=3 && xcpi < 4) {
        // A
      return {
        border: '4px solid rgba(97, 202, 49, 0.4)',
        tooltip: 'A'
      }     
    } else if(xcpi >=2 && xcpi < 3) {
        // B
      return {
        border: '4px solid rgba(97, 202, 49, 0.2)',
      tooltip: 'B'
      }
    }  else if(xcpi >= 1 && xcpi < 2) {
        // C
      return {
        border: '4px solid rgb(255, 215, 148)',
        tooltip: 'C'
      }
    }  else if(xcpi >=0 && xcpi < 1) {
        // D
      return {
        border:'4px solid rgba(255, 188, 71, 0.8)',
        tooltip: 'D'
      }
    }  else if(xcpi < 0) {
        // E
      return {
        border: '4px solid rgb(239, 124, 124)',
        tooltip: 'E'
      }
    } else {
      return '4px solid rgba(255, 188, 73, 1)'
    }
  }
  
  return (
  <Container>
  {bytes.map(item => {
    return (
      <Byte>
        <label title={setXCPICategory(item.value).tooltip}>
        <Text 
          tag="h1"
          size={[theme.fontScale.x5]}
          weight={theme.fontWeights.bold}
          lineHeight={theme.lineHeights.heading}
          style={{
            borderBottom: setXCPICategory(item.value).border
          }}
        >
	  {item.value.toFixed(2) || '-'}
          </Text>
          <Spacer size={theme.spacing.x2} />
          
          <Text
          tag="h1"
          size={[theme.fontScale.x3]}
          weight={theme.fontWeights.normal}
          lineHeight={theme.lineHeights.heading}
        >
	  {item.title}
        </Text>
      </label>
      </Byte>
    )
  })}
  </Container>
  )
} 

export default XcpiByLvl 
