export const currentYear = new Date().getFullYear()

const yearFroms = {
  4: 2013,
  110: 2013,
  11: 2013,
  12: 2013,
  'default': 2013
};

export const years = (providerId) => {
  let yearFrom = yearFroms[providerId] || yearFroms['default'];
  let yearTo = currentYear
  const years = [];

  while (yearTo >= yearFrom) {
    years.push(yearTo);
    yearTo += -1
  }

  return years
};

export const xcpihProviders = [7, 10, 11, 12];

export const xcpihOrXcpi = (providerId, xcpihReturn, xcpiReturn) => {
  return xcpihProviders.includes(parseInt(providerId))
    ? xcpihReturn || "xcpih"
    : xcpiReturn || "xcpi"
    ;
};

export const isXcpihProvider = (providerId) => {
  return xcpihProviders.includes(parseInt(providerId))
    ? true
    : false
    ;
};

export const hexColourFromRgba = (rgba) => {
  const match = rgba.match(/^rgba\((\d+),\s*(\d+),\s*(\d+),?\s*(\d*\.?\d*)?/);

  if (!match) {
    return null;
  }

  const [r, g, b] = match.slice(1).map(Number);

  if (r > 255 || g > 255 || b > 255) {
    return null;
  }

  const hex_r = ("00" + r.toString(16)).slice(-2);
  const hex_g = ("00" + g.toString(16)).slice(-2);
  const hex_b = ("00" + b.toString(16)).slice(-2);

  return '#' + hex_r + hex_g + hex_b;
};

export const hexOpacityFromRgba = (rgba) => {
  const match = rgba.match(/^rgba\((\d+),\s*(\d+),\s*(\d+),?\s*(\d*\.?\d*)?/);

  if (!match) {
    return null;
  }

  const [a] = match.slice(4).map(Number);

  if (!a || a > 1) {
    return 1;
  }

  if (a < 0) {
    return 0;
  }

  return a;
};

export const parseXcpiGrades = (grades) => {
  if (grades) {
    const displayGrades = grades.map((item) => {
      const { xcpi_from, xcpi_to } = item.attributes;

      let xcpiRange = "";

      if (!xcpi_from) {
        xcpiRange = "< " + xcpi_to.replace(".0", "");
      } else if (!xcpi_to) {
        xcpiRange = xcpi_from.replace(".0", "") + "+"
      } else {
        xcpiRange = xcpi_from.replace(".0", "") + " - " + `${(xcpi_to - 0.1).toFixed(1)}`.replace(".0", "")
      }

      return { ...item.attributes, xcpi_range: xcpiRange }
    });

    return displayGrades.sort((a, b) => b.display_order - a.display_order);
  }

  return [];
};
