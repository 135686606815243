import axios from "axios";
import { format, differenceInCalendarDays } from "date-fns";
import { xcpihOrXcpi } from "../utilities";

let provId = parseInt(localStorage.getItem("providerId"));
const getAccessToken = () => {
  const tokenIsValid =
    format(
      differenceInCalendarDays(Date.now(), localStorage.getItem("timeOfLogin")),
      "DD"
    ) < 3;
  if (!tokenIsValid) {
    axios
      .post(`${process.env.REACT_APP_API_HOST_URL}/tokens`, {
        refresh_token: localStorage.getItem("refreshToken"),
      })
      .then((response) => {
        localStorage.setItem(
          "accessToken",
          response.data.data.attributes.access_token
        );
        localStorage.setItem(
          "refreshToken",
          response.data.data.attributes.refresh_token
        );
      });
  }
  return localStorage.getItem("accessToken");
};

const instance = () => {
  const API_HOST_URL = process.env.REACT_APP_API_HOST_URL;

  const options = {
    baseURL: `${API_HOST_URL}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };
  const client = axios.create(options);
  return client;
};

export const riskOverviewByClass = (erLevels) => {
  return instance().get(`/dashboard/risk/dashboard_risk_metrics`, {
    params: {
      group_by: "year",
      er_levels: erLevels,
      date_from: `2013-01-01`,
    },
  });
};

export const riskOverviewByYear = (year) => {
  return instance().get(`/dashboard/risk/dashboard_risk_metrics`, {
    params: {
      group_by: xcpihOrXcpi(provId, "xcpih", "er_level"),
      date_from: `${year}-01-01`,
      date_to: `${year}-12-31`,
    },
  });
};

export const riskOverviewByVenue = (erLevels, year) => {
  return instance().get(`/dashboard/risk/dashboard_risk_metrics`, {
    params: {
      group_by: "venue",
      er_levels: erLevels,
      date_from: `${year}-01-01`,
      date_to: `${year}-12-31`,
    },
  });
};

export const getErLevels = () => {
  return instance().get("/dashboard/risk/er_levels", {});
};
