import axios from "axios";
import { format, differenceInCalendarDays } from "date-fns";

const getAccessToken = () => {
  const tokenIsValid =
    format(
      differenceInCalendarDays(Date.now(), localStorage.getItem("timeOfLogin")),
      "DD"
    ) < 3;
  if (!tokenIsValid) {
    axios
      .post(`${process.env.REACT_APP_API_HOST_URL}/tokens`, {
        refresh_token: localStorage.getItem("refreshToken"),
      })
      .then((response) => {
        localStorage.setItem(
          "accessToken",
          response.data.data.attributes.access_token
        );
        localStorage.setItem(
          "refreshToken",
          response.data.data.attributes.refresh_token
        );
      });
  }
  return localStorage.getItem("accessToken");
};

const instance = () => {
  const API_HOST_URL = process.env.REACT_APP_API_HOST_URL;

  const options = {
    baseURL: `${API_HOST_URL}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };
  const client = axios.create(options);
  return client;
};

export const getCompetitions = (filterString, searchTarget) => {
  return instance().get(
    `/dashboard/risk/shows?show_search_string=${filterString}&search_target=${searchTarget}`
  );
};

export const getMostRecentCompetitions = (searchTarget) => {
  return instance().get(`/dashboard/risk/shows?search_target=${searchTarget}`);
};

export const getMostRecentCompetitionsFromDate = (searchTarget) => {
  return instance().get(
    `/dashboard/risk/shows?search_target=${searchTarget}&date_from=${format(
      Date.now(),
      "YYYY-MM-DD"
    )}`
  );
};

export const getCompetitionOverview = (erLevels = [], venueId) => {
  return instance().get(`/dashboard/risk/dashboard_risk_metrics`, {
    params: {
      group_by: "year",
      er_levels: erLevels,
      venue_id: venueId,
      date_from: "2013-01-01",
    },
  });
};

export const getGrades = () => {
  return instance().get(`/dashboard/risk/dashboard_risk_metric_grades`);
};

export const getWatchlists = (showId, erLevel) => {
  console.log("PARAMS", showId, erLevel);
  return instance().get(`/dashboard/risk/dashboard_risk_metrics_erqi`, {
    params: {
      show_id: showId,
      er_level: erLevel,
    },
  });
};

export const getUpcomingCompetitions = (filterString) => {
  return instance().get(
    `/dashboard/risk/shows?show_search_string=${filterString}`
  );
};

export const getMostRecentUpcomingCompetitions = () => {
  return instance().get(`/dashboard/risk/shows`);
};

export const getUpcomingCompetitionOverview = (erLevels = [], venueId) => {
  return instance().get(`/dashboard/risk/dashboard_risk_metrics`, {
    params: {
      group_by: "year",
      er_levels: erLevels,
      venue_id: venueId,
      date_from: "2013-01-01",
    },
  });
};

export const getUpcomingCompetitionWatchlists = (showId, erLevel) => {
  return instance().get(`/dashboard/risk/dashboard_risk_metrics_erqi`, {
    params: {
      show_id: showId,
      er_level: erLevel,
      date_from: "2013-01-01",
    },
  });
};

export const uploadUpcomingShow = (upcoming_show) => {
  return instance().post("/dashboard/risk/upcoming_shows", upcoming_show);
};
