import React, { useState, useEffect, useRef } from "react";
import { store } from "../redux/store";
import styled from "styled-components";
import {
  theme,
  Card,
  Dropdown,
  Text,
  Spacer,
  Table,
  Th,
  Tr,
  Td,
} from "equiratings-components";
import { round } from "../helperFunctions";
import { currentYear, years } from "../utilities";

const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: baseline;
`;

const RiskOverviewByYear = ({ getOverviewByYear, byYear }) => {
  const initialRender = useRef(true);
  const providerId = store.getState().UserState.providerId;
  const [selectedYear, selectYear] = useState(currentYear);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      getOverviewByYear(selectedYear);
    }
  }, [selectedYear]);

  return (
    <>
      <TitleContainer>
        <Text
          tag="h1"
          size={[theme.fontScale.x4]}
          weight={theme.fontWeights.bold}
          lineHeight={theme.lineHeights.heading}
          cursor={"pointer"}
        >
          Risk Overview by Year
        </Text>
        <Dropdown
          onChange={(item) => selectYear(item)}
          readOnly={true}
          items={years(providerId)}
          itemToString={(item) => item}
          initialItem={selectedYear}
          handleFilter={() => () => true}
          displaySuggestion={(item) => (
            <Text
              tag="h1"
              size={[theme.fontScale.x4]}
              weight={theme.fontWeights.normal}
              lineHeight={theme.lineHeights.heading}
              cursor={"pointer"}
            >
              {item}
            </Text>
          )}
        />
      </TitleContainer>
      <Spacer size={theme.spacing.x3} />
      <Card>
        <Table>
          <tbody>
            <tr>
              <Th>Class</Th>
              <Th>Starters</Th>
              <Th>XC Runs</Th>
              <Th>% Of XC Runs</Th>
              <Th>XCJ Clear Rates</Th>
              <Th>XC Falls</Th>
              <Th>XC Fall Rates</Th>
              <Th>Rider Falls</Th>
              <Th>Rider Fall Rates</Th>
              <Th>Horse Falls</Th>
              <Th>Horse Fall Rates</Th>
            </tr>
            {byYear.map((item, index) => {
              return (
                console.log(item.attributes.group_colour) || (
                  <Tr
                    key={index}
                    style={{ background: item.attributes.group_colour }}
                  >
                    <Td style={{ width: "9%" }}>
                      {item.attributes.group_label}
                    </Td>
                    <Td>{item.attributes.starters}</Td>
                    <Td>{item.attributes.xc_runs}</Td>
                    <Td>
                      {round(
                        item.attributes.percentage_of_all_xc_runs,
                        1,
                        true
                      )}
                    </Td>
                    <Td>{round(item.attributes.xcj_clear_rate, 1, true)}</Td>
                    <Td>{item.attributes.all_falls}</Td>
                    <Td>{round(item.attributes.all_fall_rate, 2, true)}</Td>
                    <Td>{item.attributes.rider_falls}</Td>
                    <Td>{round(item.attributes.rider_fall_rate, 2, true)}</Td>
                    <Td>{item.attributes.horse_falls}</Td>
                    <Td>{round(item.attributes.horse_fall_rate, 2, true)}</Td>
                  </Tr>
                )
              );
            })}
          </tbody>
        </Table>
      </Card>
    </>
  );
};
export default RiskOverviewByYear;
