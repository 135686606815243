import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { store } from '../redux/store.js';
import { userLogin } from '../redux/User/UserActions';
import { format, subDays } from 'date-fns';
import { navigate } from '@reach/router';

import {
  theme,
  Text,
  Spacer,
  TextField,
  PasswordField,
  Submit,
} from 'equiratings-components';

const Login = ({ userLogin, error, accessToken, isUserLoggedIn }) => {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);

  const todaysDate = format(Date.now(), 'DD');
  const timeOfLoginFormatted = format(
    subDays(store.getState().UserState.timeOfLogin, 2),
    'DD',
  );
  const tokenIsValid = todaysDate - timeOfLoginFormatted < 3;

  const handleSubmit = async e => {
    e.preventDefault();
    await userLogin(email, password);
  };

  const clearState = async () => {
    if (store.getState().UserState.isUserLoggedIn && tokenIsValid) {
      localStorage.removeItem('navTab');
      navigate('/risk-overview');
    } else {
      return;
    }
  };

  useEffect(() => {
    clearState();
  }, [clearState]);

  return (
    <>
      <Spacer size={theme.spacing.x5} />
      <Text
        tag="h1"
        size={[theme.fontScale.x6]}
        weight={theme.fontWeights.bold}
        lineHeight={theme.lineHeights.heading}
        cursor={'pointer'}>
        Welcome to the EquiRatings Risk Dashboard
      </Text>
      <Spacer size={theme.spacing.x5} />
      {error && (
        <>
          <Text
            tag="h1"
            size={[theme.fontScale.x5]}
            weight={theme.fontWeights.normal}
            lineHeight={theme.lineHeights.heading}
            cursor={'pointer'}
            color={'red'}>
            The email and password combination you entered is incorrect.
          </Text>
          <Spacer size={theme.spacing.x3} />
        </>
      )}
      <form>
        <Text
          tag="h1"
          size={[theme.fontScale.x5]}
          weight={theme.fontWeights.bold}
          lineHeight={theme.lineHeights.heading}
          cursor={'pointer'}>
          Enter your Email
        </Text>
        <Spacer size={theme.spacing.x2} />
        <TextField
          onChange={e => setEmail(e.target.value)}
          placeholder={'youremail@email.com'}
          style={{
            border: `${error ? '1px solid red' : ''}`,
          }}
        />
        <Spacer size={theme.spacing.x4} />
        <Text
          tag="h1"
          size={[theme.fontScale.x5]}
          weight={theme.fontWeights.bold}
          lineHeight={theme.lineHeights.heading}
          cursor={'pointer'}>
          Enter your Password
        </Text>
        <Spacer size={theme.spacing.x2} />
        <PasswordField
          onChange={e => setPassword(e.target.value)}
          placeholder={'•••••••••••••••'}
          style={{
            border: `${error ? '1px solid red' : ''}`,
          }}
        />
        <Spacer size={theme.spacing.x4} />
        <Submit
          value={'Sign In'}
          style={{ width: '140px', textAlign: 'center' }}
          onClick={e => handleSubmit(e)}
        />
        <Spacer size={theme.spacing.x3} />
      </form>
    </>
  );
};

export default connect(
  ({ UserState }) => ({
    error: UserState.error,
    accessToken: UserState.accessToken,
    isUserLoggedIn: UserState.isUserLoggedIn,
  }),
  { userLogin },
)(Login);
