import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { checkUserLoggedIn } from "../helperFunctions.js";
import { isXcpihProvider } from "../utilities";
import NavigationTab from "../components/NavigationTab";
import RiskValueTab from "../components/RiskValueTab";
import XCPIAnalysis from "../components/XCPIAnalysis";
import LoadingSpinner from "../components/LoadingSpinner";
import {
  getXcpiAnalysis,
  getErqiAnalysis,
} from "../redux/RiskAnalysis/RiskAnalysisActions";

const LoadingContainer = styled.div`
  width: 100%;
  padding-top: 15%;
  margin: 0 auto;
`;

const RiskAnalysis = ({
  getXcpiAnalysis,
  xcpiByDate,
  xcpiByClass,
  xcpiByCategory,
  getErqiAnalysis,
  erqiByDate,
  erqiByClass,
  erqiByCategory,
  xcpiError,
  erqiError,
  providerId,
}) => {
  const [riskValue, setRiskValue] = useState(1);
  const xcpiValuesPresent = xcpiByDate && xcpiByClass && xcpiByCategory;

  useEffect(() => {
    checkUserLoggedIn();
  }, []);

  useEffect(() => {
    // getErqiAnalysis("green");
    getXcpiAnalysis("A++");
  }, []);

  return (
    console.log(providerId) || (
      <>
        <NavigationTab />
        <RiskValueTab
          riskValue={riskValue}
          setRiskValue={(tab) => setRiskValue(tab)}
        />
        {xcpiValuesPresent ? (
          <XCPIAnalysis
            showErqi2={isXcpihProvider(providerId)}
          />
        ) : (
          <LoadingContainer>
            <LoadingSpinner />
          </LoadingContainer>
        )}
      </>
    )
  );
};

export default connect(
  ({ RiskAnalysisState, UserState }) => ({
    xcpiByDate: RiskAnalysisState.xcpiByDate,
    xcpiByClass: RiskAnalysisState.xcpiByClass,
    xcpiByCategory: RiskAnalysisState.xcpiByCategory,
    erqiByDate: RiskAnalysisState.erqiByDate,
    erqiByClass: RiskAnalysisState.erqiByClass,
    erqiByCategory: RiskAnalysisState.erqiByCategory,
    xcpiError: RiskAnalysisState.xcpiError,
    erqiError: RiskAnalysisState.erqiError,
    loading: RiskAnalysisState.loading,
    providerId: UserState.providerId,
  }),
  { getXcpiAnalysis, getErqiAnalysis }
)(RiskAnalysis);
