import React from "react";
import styled from "styled-components";
import {
  Text,
  theme,
  Spacer,
  Card,
  Table,
  Th,
  Tr,
  Td,
} from "equiratings-components";

import { format } from "date-fns";
import { round } from "../helperFunctions";
import { xcpihOrXcpi } from "../utilities";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PostCompPDF from "./PostCompPDF";
import { store } from "../redux/store";
import DownloadSVG from "./DownloadSVG";

const NoData = styled.div`
  background: ${theme.colors.white};
  width: 100%;
  display: flex;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: ${theme.spacing.x2}
  padding: ${theme.spacing.x6};
  overflow-x: auto;
`;

const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const PostCompetitionWatchlist = ({
  watchlists,
  selectedCompetition,
  requestFailed,
  grades,
}) => {
  const [showPDF, setShowPDF] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      setShowPDF(true);
    }, 500);
  }, []);

  return watchlists.length > 0 ? (
    requestFailed ? (
      <>
        <TitleContainer>
          <Text
            tag="h1"
            size={[theme.fontScale.x4]}
            weight={theme.fontWeights.bold}
            lineHeight={theme.lineHeights.heading}
          >
            {`${selectedCompetition.attributes.name} ${format(
              selectedCompetition.attributes.start_date,
              "YYYY"
            )} Watchlist Report`}
          </Text>
        </TitleContainer>
        <Spacer size={theme.spacing.x4} />
        <NoData>
          <Text
            tag="h1"
            size={[theme.fontScale.x4]}
            weight={theme.fontWeights.normal}
            lineHeight={theme.lineHeights.heading}
            style={{
              color: "red",
              margin: "0 auto",
            }}
          >
            Uh Oh! It looks like you've encountered an issue. If it persists
            please contact harry@equiratings.com.
          </Text>
        </NoData>
      </>
    ) : (
      <>
        <TitleContainer>
          <Text
            tag="h1"
            size={[theme.fontScale.x4]}
            weight={theme.fontWeights.bold}
            lineHeight={theme.lineHeights.heading}
          >
            {`${selectedCompetition.attributes.name} ${format(
              selectedCompetition.attributes.start_date,
              "YYYY"
            )} Watchlist Report`}
          </Text>
          {watchlists && showPDF && (
            <PDFDownloadLink
              style={{ textDecoration: "none" }}
              document={
                <PostCompPDF
                  watchlists={watchlists}
                  grades={grades}
                  competitionName={selectedCompetition.attributes.name}
                  competitionDate={format(
                    selectedCompetition.attributes.start_date,
                    "DD/MM/YYYY"
                  )}
                />
              }
              fileName={`${selectedCompetition.attributes.name
                }-post-comp-${format(
                  selectedCompetition.attributes.start_date,
                  "YYYY"
                )}.pdf`}
            >
              <div>
                <DownloadSVG />
              </div>
            </PDFDownloadLink>
          )}
        </TitleContainer>
        <Spacer size={theme.spacing.x4} />
        <Card>
          <Table>
            <tbody>
              <tr>
                <Th>Class</Th>
                <Th>Horse Name</Th>
                <Th>Athlete Name</Th>
                {store.getState().UserState.providerId === 4 && (
                  <Th>Athlete Category</Th>
                )}
                <Th>% of Clears (last 10)</Th>
                <Th>
                  {xcpihOrXcpi(
                    store.getState().UserState.providerId,
                    "ERQI 2.0",
                    "HFI"
                  )}
                </Th>
                <Th>XC Status</Th>
                <Th>XC Jump</Th>
              </tr>
              {watchlists.map((item, index) => {
                return (
                  (
                    <Tr key={index} style={{ borderTop: "none" }}>
                      <Td>{item.attributes.competition.level}</Td>
                      <Td style={{ width: "30%" }}>
                        {item.attributes.horse.name}
                      </Td>
                      <Td>{item.attributes.athlete.name}</Td>
                      {store.getState().UserState.providerId === 4 && (
                        <Td>{item.attributes.athlete.category}</Td>
                      )}
                      <Td>{round(item.attributes.xcj.xcj, 1, true)}</Td>
                      <Td
                        style={{
                          background: xcpihOrXcpi(
                            store.getState().UserState.providerId,
                            item.attributes.xcpih_grade.rgba,
                            item.attributes.xcpi_grade.rgba
                          )
                        }}
                      >
                        {xcpihOrXcpi(
                          store.getState().UserState.providerId,
                          round(item.attributes.xcpih_grade.xcpih, 2) || "-",
                          round(item.attributes.xcpi_grade.xcpi, 2) || "-"
                        )}{" "}
                        (
                        {xcpihOrXcpi(
                          store.getState().UserState.providerId,
                          item.attributes.xcpih_grade.title || "-",
                          item.attributes.xcpi_grade.title || "-"
                        )}
                        )
                      </Td>
                      <Td>
                        {item.attributes.result.xc_status} (
                        {item.attributes.result.xc_code || "-"})
                      </Td>
                      <Td>{item.attributes.result.xc_jump}</Td>
                    </Tr>
                  )
                );
              })}
            </tbody>
          </Table>
        </Card>
      </>
    )
  ) : (
    <>
      <Text
        tag="h1"
        size={[theme.fontScale.x4]}
        weight={theme.fontWeights.bold}
        lineHeight={theme.lineHeights.heading}
      >
        {`${selectedCompetition.attributes.name} ${format(
          selectedCompetition.attributes.start_date,
          "YYYY"
        )} Watchlist Report`}
      </Text>
      <Spacer size={theme.spacing.x4} />
      <NoData>
        <Text
          tag="h1"
          size={[theme.fontScale.x4]}
          weight={theme.fontWeights.normal}
          lineHeight={theme.lineHeights.heading}
          style={{
            color: "#808080",
            margin: "0 auto",
          }}
        >
          {`There is no watchlist entries for ${selectedCompetition.attributes.name}.`}
        </Text>
      </NoData>
    </>
  );
};

export default PostCompetitionWatchlist;
