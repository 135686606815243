import {
  riskOverviewByClass,
  riskOverviewByYear,
  riskOverviewByVenue,
  getErLevels,
} from "../../services/riskOverview";

export const GET_OVERVIEW_CLASS_STARTED = "GET_OVERVIEW_CLASS_STARTED";
export const GET_OVERVIEW_CLASS_SUCCESS = "GET_OVERVIEW_CLASS_SUCCESS";
export const GET_OVERVIEW_CLASS_FAILURE = "GET_OVERVIEW_CLASS_FAILURE";

export const GET_OVERVIEW_YEAR_STARTED = "GET_OVERVIEW_YEAR_STARTED";
export const GET_OVERVIEW_YEAR_SUCCESS = "GET_OVERVIEW_YEAR_SUCCESS";
export const GET_OVERVIEW_YEAR_FAILURE = "GET_OVERVIEW_YEAR_FAILURE";

export const GET_OVERVIEW_VENUE_STARTED = "GET_OVERVIEW_VENUE_STARTED";
export const GET_OVERVIEW_VENUE_SUCCESS = "GET_OVERVIEW_VENUE_SUCCESS";
export const GET_OVERVIEW_VENUE_FAILURE = "GET_OVERVIEW_VENUE_FAILURE";

export const GET_ER_LEVELS_STARTED = "GET_ER_LEVELS_STARTED";
export const GET_ER_LEVELS_SUCCESS = "GET_ER_LEVELS_SUCCESS ";
export const GET_ER_LEVELS_FAILURE = "GET_ER_LEVELS_FAILURE";

export const getOverviewByClassStarted = () => ({
  type: GET_OVERVIEW_CLASS_STARTED,
});

export const getOverviewByClassSuccess = (values) => ({
  type: GET_OVERVIEW_CLASS_SUCCESS,
  payload: values,
});

export const getOverviewByClassFailure = (error) => ({
  type: GET_OVERVIEW_CLASS_FAILURE,
  payload: error,
});

export const getOverviewByYearStarted = () => ({
  type: GET_OVERVIEW_YEAR_STARTED,
});

export const getOverviewByYearSuccess = (values) => ({
  type: GET_OVERVIEW_YEAR_SUCCESS,
  payload: values,
});

export const getOverviewByYearFailure = (error) => ({
  type: GET_OVERVIEW_YEAR_FAILURE,
  payload: error,
});

export const getOverviewByVenueStarted = () => ({
  type: GET_OVERVIEW_VENUE_STARTED,
});

export const getOverviewByVenueSuccess = (values) => ({
  type: GET_OVERVIEW_VENUE_SUCCESS,
  payload: values,
});

export const getOverviewByVenueFailure = (error) => ({
  type: GET_OVERVIEW_VENUE_FAILURE,
  payload: error,
});

export const getErLevelsStarted = () => ({
  type: GET_ER_LEVELS_STARTED,
});

export const getErLevelsSuccess = (levels) => ({
  type: GET_ER_LEVELS_SUCCESS,
  payload: levels,
});

export const getErLevelsFailure = (error) => ({
  type: GET_ER_LEVELS_FAILURE,
  payload: error,
});

export const getOverviewByClass = (erLevel) => {
  return (dispatch) => {
    dispatch(getOverviewByClassStarted());
    riskOverviewByClass(erLevel)
      .then((response) => {
        const values = response.data.data;
        dispatch(getOverviewByClassSuccess(values));
      })
      .catch((error) => {
        dispatch(getOverviewByClassFailure(error));
      });
  };
};

export const getOverviewByYear = (year) => {
  return (dispatch) => {
    dispatch(getOverviewByYearStarted());
    riskOverviewByYear(year)
      .then((response) => {
        const values = response.data.data;
        dispatch(getOverviewByYearSuccess(values));
      })
      .catch((error) => {
        dispatch(getOverviewByYearFailure(error));
      });
  };
};

export const getOverviewByVenue = (erLevels, year) => {
  return (dispatch) => {
    dispatch(getOverviewByVenueStarted());
    riskOverviewByVenue(erLevels, year)
      .then((response) => {
        const values = response.data.data;
        dispatch(getOverviewByVenueSuccess(values));
      })
      .catch((error) => {
        dispatch(getOverviewByVenueFailure(error));
      });
  };
};

export const getLevels = () => {
  return (dispatch) => {
    dispatch(getErLevelsStarted());
    getErLevels()
      .then((response) => {
        const levels = response.data.data;
        dispatch(getErLevelsSuccess(levels));
      })
      .catch((error) => {
        dispatch(getErLevelsFailure(error));
      });
  };
};
