import React from 'react';
import { HomeSVG, LogoutSVG, theme } from 'equiratings-components';
import styled from 'styled-components';
import { navigate } from '@reach/router';
import { connect } from 'react-redux';
import { userLogout } from '../redux/User/UserActions';
import { persistor } from '../redux/store';
import { getProviderImage } from '../helperFunctions.js';

const HeaderContainer = styled.header`
  justify-content: space-between;
  box-sizing: border-box;
  display: flex;
  align-items: center;
`;

const IconContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  justify-content: space-around;
  padding: ${theme.spacing.x3} 0 ${theme.spacing.x3} 0;
  width: 100px;
`;

const PartnerLogoContainer = styled.div`
  display: none;
  @media (${theme.breakpoints.notSmall}) {
    display: flex;
  }
`;

const Header = ({ userLogout, isUserLoggedIn }) => {
  const image = require('../assets/logo.png');
  const handleLogout = async () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('navTab');
    await userLogout();
    await persistor.purge();
    navigate('/');
  };

  return (
    <HeaderContainer>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img
          src={image}
          alt="Logo"
          width="200px"
          height="25px"
          style={{ marginRight: '5%' }}
        />
        {isUserLoggedIn ? (
          <PartnerLogoContainer>{getProviderImage()}</PartnerLogoContainer>
        ) : null}
      </div>
      {isUserLoggedIn ? (
        <IconContainer>
          <div
            onClick={() => (
              navigate('/risk-overview'), (localStorage['navTab'] = '0')
            )}>
            <HomeSVG />
          </div>
          <div onClick={() => handleLogout()}>
            <LogoutSVG />
          </div>
        </IconContainer>
      ) : null}
    </HeaderContainer>
  );
};

export default connect(
  ({ UserState }) => ({ isUserLoggedIn: UserState.isUserLoggedIn }),
  { userLogout },
)(Header);
