import { 
  SET_HORSE
} from './HorseActions.js'


const initialState = {
  selectedHorse: undefined
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_HORSE: 
      return {
        selectedHorse: action.payload
      }
    default:
      return state
  }
}
