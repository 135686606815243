import React from "react";
import { store } from "../redux/store";
import styled from "styled-components";
import {
  Text,
  Card,
  Dropdown,
  Spacer,
  Table,
  Th,
  Tr,
  Td,
  theme,
} from "equiratings-components";
import { currentYear, years } from "../utilities";
import { round } from "../helperFunctions.js";

const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: baseline;
`;

const AnalysisByDate = ({ rows, onChange, riskMetric }) => {
  const handleChange = async (year) => {
    onChange(year);
  };
  const providerId = store.getState().UserState.providerId;

  return (
    rows &&
    rows.length > 0 && (
      <>
        <TitleContainer>
          <Text
            tag="h1"
            size={[theme.fontScale.x4]}
            weight={theme.fontWeights.bold}
            lineHeight={theme.lineHeights.heading}
          >
            By Date
          </Text>
          <Dropdown
            onChange={(year) => handleChange(year)}
            readOnly={true}
            items={years(providerId)}
            itemToString={(year) => year}
            initialItem={currentYear}
            handleFilter={() => () => true}
            displaySuggestion={(year) => (
              <Text
                tag="h1"
                size={[theme.fontScale.x4]}
                weight={theme.fontWeights.normal}
                lineHeight={theme.lineHeights.heading}
              >
                {year}
              </Text>
            )}
          />
        </TitleContainer>
        <Spacer size={theme.spacing.x3} />
        <Card>
          <Table>
            <tbody>
              <tr>
                <Th>Category</Th>
                <Th>XC Runs</Th>
                <Th>Starters</Th>
                <Th>% Of XC Runs</Th>
                <Th>XCJ Clear Rates</Th>
                <Th>XC Falls</Th>
                <Th>XC Fall Rates</Th>
                <Th>Rider Falls</Th>
                <Th>Rider Fall Rates</Th>
                <Th>Horse Falls</Th>
                <Th>Horse Fall Rates</Th>
              </tr>
              {rows.map((row, index) => {
                return (
                  <Tr
                    style={{ background: row.attributes.group_colour }}
                    key={index}
                  >
                    <Td>{row.attributes.group_label}</Td>
                    <Td>{row.attributes.xc_runs}</Td>
                    <Td>{row.attributes.starters}</Td>
                    <Td>
                      {round(row.attributes.percentage_of_all_xc_runs, 1, true)}
                    </Td>
                    <Td>{round(row.attributes.xcj_clear_rate, 1, true)}</Td>
                    <Td>{row.attributes.all_falls}</Td>
                    <Td>{round(row.attributes.all_fall_rate, 2, true)}</Td>
                    <Td>{row.attributes.rider_falls}</Td>
                    <Td>{round(row.attributes.rider_fall_rate, 2, true)}</Td>
                    <Td>{row.attributes.horse_falls}</Td>
                    <Td>{round(row.attributes.horse_fall_rate, 2, true)}</Td>
                  </Tr>
                );
              })}
            </tbody>
          </Table>
        </Card>
      </>
    )
  );
};

export default AnalysisByDate;
