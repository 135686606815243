import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { round } from "../helperFunctions";
import {
  Text,
  Card,
  Dropdown,
  Spacer,
  Table,
  Th,
  Tr,
  Td,
  theme,
} from "equiratings-components";

const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: baseline;
`;

const ClassItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
`;

const Class = styled.div`
  display: flex;
  padding: ${theme.spacing.x2} ${theme.spacing.x3};
  align-items: center;
  margin-right: 1%;
`;

const AnalysisByLevel = ({ rows, levels, onChange, riskMetric }) => {
  const [selectedLevels, selectLevel] = useState([]);
  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      onChange(selectedLevels.map((lvl) => lvl.erLevel));
    }
  }, [selectedLevels]);

  const removeClass = (index) => {
    const newArr = [...selectedLevels];
    newArr.splice(index, 1);
    selectLevel(newArr);
  };

  const addNewClass = (level) => {
    const someArr = selectedLevels.map((lvl) => lvl.erLevel);
    const isDuplicate = someArr.includes(level.attributes.er_level);

    if (!isDuplicate) {
      selectLevel([
        ...selectedLevels,
        {
          erLevel: level.attributes.er_level,
          className: level.attributes.title,
        },
      ]);
    }
  };

  return (
    <>
      <TitleContainer>
        <Text
          tag="h1"
          size={[theme.fontScale.x4]}
          weight={theme.fontWeights.bold}
          lineHeight={theme.lineHeights.heading}
        >
          By Class - Since 2020
        </Text>
        <Dropdown
          onChange={(level) => addNewClass(level)}
          readOnly={true}
          items={levels}
          itemToString={() => "Select a Class"}
          initialItem={"Select a Class"}
          handleFilter={() => () => true}
          displaySuggestion={(level) => (
            <Text
              tag="h1"
              size={[theme.fontScale.x4]}
              weight={theme.fontWeights.normal}
              lineHeight={theme.lineHeights.heading}
            >
              {level.attributes ? level.attributes.title : ""}
            </Text>
          )}
        />
      </TitleContainer>
      <Spacer size={theme.spacing.x3} />
      <Spacer size={theme.spacing.x2} />
      <ClassItems>
        <Text
          tag="h1"
          size={[theme.fontScale.x4]}
          weight={theme.fontWeights.bold}
          style={{
            marginRight: "20px",
          }}
        >
          Selected Levels:
        </Text>
        {selectedLevels.length > 0 ? (
          <>
            {selectedLevels.map((level, index) => {
              return (
                <Class key={index}>
                  <Text
                    tag="h1"
                    size={[theme.fontScale.x4]}
                    weight={theme.fontWeights.normal}
                    lineHeight={theme.lineHeights.heading}
                    style={{
                      marginRight: "20px",
                    }}
                  >
                    {level.className}
                  </Text>
                  <Text
                    tag="h1"
                    size={[theme.fontScale.x4]}
                    weight={theme.fontWeights.normal}
                    lineHeight={theme.lineHeights.heading}
                    color={"grey"}
                    cursor={"pointer"}
                    onClick={() => removeClass(index)}
                  >
                    ✕
                  </Text>
                </Class>
              );
            })}
          </>
        ) : (
          <Class>
            <Text
              tag="h1"
              size={[theme.fontScale.x4]}
              weight={theme.fontWeights.normal}
              lineHeight={theme.lineHeights.heading}
            >
              All Levels
            </Text>
          </Class>
        )}
      </ClassItems>
      <Spacer size={theme.spacing.x3} />
      <Card>
        <Table>
          <tbody>
            <tr>
              {riskMetric === "HFI" ? <Th>Category</Th> : null}
              <Th>XC Runs</Th>
              <Th>Starters</Th>
              <Th>% Of XC Runs</Th>
              <Th>XCJ Clear Rates</Th>
              <Th>XC Falls</Th>
              <Th>XC Fall Rates</Th>
              <Th>Rider Falls</Th>
              <Th>Rider Fall Rates</Th>
              <Th>Horse Falls</Th>
              <Th>Horse Fall Rates</Th>
            </tr>
            {rows.map((row, index) => {
              return (
                <Tr
                  style={{ background: row.attributes.group_colour }}
                  key={index}
                >
                  {riskMetric === "HFI" ? (
                    <Td>{row.attributes.group_label}</Td>
                  ) : null}
                  <Td>{row.attributes.xc_runs}</Td>
                  <Td>{row.attributes.starters}</Td>
                  <Td>
                    {round(row.attributes.percentage_of_all_xc_runs, 1, true)}
                  </Td>
                  <Td>{round(row.attributes.xcj_clear_rate, 1, true)}</Td>
                  <Td>{row.attributes.all_falls}</Td>
                  <Td>{round(row.attributes.all_fall_rate, 2, true)}</Td>
                  <Td>{row.attributes.rider_falls}</Td>
                  <Td>{round(row.attributes.rider_fall_rate, 2, true)}</Td>
                  <Td>{row.attributes.horse_falls}</Td>
                  <Td>{round(row.attributes.horse_fall_rate, 2, true)}</Td>
                </Tr>
              );
            })}
          </tbody>
        </Table>
      </Card>
    </>
  );
};

export default AnalysisByLevel;
