import { userLoginRequest } from "../../services/user";
import { navigate } from "@reach/router";
// import { getCompetitionsReq } from "../Metrics/MetricsActions";

// ACTION CREATORS //
export const USER_LOGIN_STARTED = "USER_LOGIN_STARTED";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";

export const USER_LOGOUT = "USER_LOGOUT";

// ACTIONS //
export const userLoginStarted = () => ({
  type: USER_LOGIN_STARTED,
});

export const userLoginSuccess = (data, email, date) => ({
  type: USER_LOGIN_SUCCESS,
  payload: { data, email, date },
});

export const userLoginFailure = (error) => ({
  type: USER_LOGIN_FAILURE,
  payload: error,
});

export const userLogout = () => ({
  type: USER_LOGOUT,
});

// ASYNC ACTIONS //
export const userLogin = (email, password) => {
  return (dispatch) => {
    dispatch(userLoginStarted());
    userLoginRequest(email, password)
      .then((response) => {
        dispatch(userLoginSuccess(response.data, email, Date.now()));
        localStorage.setItem(
          "accessToken",
          response.data.data.attributes.access_token
        );
        localStorage.setItem(
          "refreshToken",
          response.data.data.attributes.refresh_token
        );
        localStorage.setItem(
          "providerId",
          response.data.data.attributes.provider_id
        );
        navigate("/risk-overview");
      })
      .catch((error) => {
        dispatch(userLoginFailure(error));
      });
  };
};
