import axios from 'axios'
const instance = () => {
  const API_HOST_URL = process.env.REACT_APP_API_HOST_URL;
  const options = {
    baseURL: `${API_HOST_URL}`,
    headers: {
      "Content-Type": "application/json"
    }
  };

  const client = axios.create(options);
  return client;
};

export const userLoginRequest = (email, password) => {
  return instance().post("/sessions", {
    email: email,
    password: password
  });
}
