import React, { useState } from "react";
import "react-dates/lib/css/_datepicker.css";
import "../lib/DatePickerOveride.css";
import styled from "styled-components";
import {
  Text,
  Card,
  Spacer,
  Table,
  Th,
  Tr,
  Td,
  theme,
} from "equiratings-components";

const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: baseline;
`;
const CardWithBorder = styled.div`
  border: 2px solid rgba(26, 56, 82, 0.5);
  background: ${theme.colors.white};
  width: 100%;
  display: flex;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: ${theme.spacing.x2};
  overflow-x: auto;
`;

const CPRByUpcomingShow = ({ rows, onChange, riskMetric }) => {
  const [selectedCPR, setSelectedCPR] = useState(undefined);
  return (
    rows &&
    rows.length > 0 && (
      <>
        <>
          <TitleContainer>
            <Text
              tag="h1"
              size={[theme.fontScale.x4]}
              weight={theme.fontWeights.bold}
              lineHeight={theme.lineHeights.heading}
            >
              CPR by Upcoming Show
            </Text>
          </TitleContainer>
          <Spacer size={theme.spacing.x3} />
          <div style={{ width: "100%", maxHeight: 500, overflow: "scroll" }}>
            <Card>
              <Table>
                <tbody>
                  <tr>
                    <Th>Horse</Th>
                    <Th>Athlete</Th>
                    <Th>Upcoming Show</Th>
                    <Th>Upcoming Date</Th>
                    <Th>Entry Class</Th>
                    <Th>CPR Class</Th>
                    <Th>CPR Trigger</Th>
                    <Th>Entry Status</Th>
                  </tr>
                  {rows.map((row, index) => {
                    return (
                      <Tr
                        style={{ borderTop: "none" }}
                        key={index}
                        onClick={() =>
                          setSelectedCPR(
                            row.attributes.cpr_contributing_results
                          )
                        }
                      >
                        <Td>
                          <a
                            href={row.attributes.horse_url}
                            style={{
                              textDecoration: "none",
                              fontWeight: theme.fontWeights.bold,
                              color: "inherit",
                            }}
                          >
                            {row.attributes.horse}
                          </a>
                        </Td>
                        <Td>
                          {row.attributes.cpr_contributing_results[0].rider}
                        </Td>
                        <Td>{row.attributes.entry_show_name}</Td>
                        <Td>{row.attributes.entry_show_date}</Td>
                        <Td>{row.attributes.entry_class_name}</Td>
                        <Td>{row.attributes.cpr_class}</Td>
                        <Td>{row.attributes.cpr_trigger}</Td>
                        <Td
                          style={{
                            backgroundColor:
                              !row.attributes.entry_too_high_for_cpr &&
                                !row.attributes.entry_lowest_level_check
                                ? "rgba(166, 235, 133, 0.65"
                                : row.attributes.entry_too_low_to_requalify
                                  ? "rgba(166, 235, 133, 0.65"
                                  : row.attributes.entry_lowest_level_check
                                    ? "rgba(255, 188, 73, 0.65)"
                                    : row.attributes.entry_too_high_for_cpr &&
                                      !row.attributes.entry_lowest_level_check
                                      ? "rgba(236, 92, 92, 0.65)"
                                      : null,
                          }}
                        >
                          {!row.attributes.entry_too_high_for_cpr &&
                            !row.attributes.entry_lowest_level_check
                            ? "At Correct Level"
                            : row.attributes.entry_too_low_to_requalify
                              ? "At Correct Level"
                              : row.attributes.entry_lowest_level_check
                                ? "Correct Level with Approval"
                                : row.attributes.entry_too_high_for_cpr &&
                                  !row.attributes.entry_lowest_level_check
                                  ? "At the wrong Level"
                                  : null}
                        </Td>
                      </Tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card>
          </div>
        </>
        <>
          {selectedCPR ? (
            <div
              style={{
                margin: "auto",
                position: "absolute",
                width: "90%",
                top: "75%",
                left: 0,
                bottom: 0,
                right: 0,
              }}
            >
              <CardWithBorder>
                <div style={{ padding: "2%", width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Text
                      width="20%"
                      tag="h1"
                      size={[theme.fontScale.x4]}
                      weight={theme.fontWeights.bold}
                      lineHeight={theme.lineHeights.heading}
                    >
                      Contributing Results
                    </Text>
                    <Text
                      onClick={() => setSelectedCPR(undefined)}
                      width="20%"
                      tag="h1"
                      cursor="pointer"
                      size={[theme.fontScale.x4]}
                      weight={theme.fontWeights.bold}
                      lineHeight={theme.lineHeights.heading}
                    >
                      X
                    </Text>
                  </div>
                  <Spacer size={theme.spacing.x3} />
                  <Table>
                    <tbody>
                      <tr>
                        <Th>Athlete</Th>
                        <Th>Show</Th>
                        <Th>Date</Th>
                        <Th>Class</Th>
                        <Th>XC Status</Th>
                      </tr>
                      {selectedCPR.map((item, index) => {
                        return (
                          <Tr key={index}>
                            <Td>{item.rider}</Td>
                            <Td>{item.show}</Td>
                            <Td>{item.date}</Td>
                            <Td>{item.class}</Td>
                            <Td>{item.xc_status}</Td>
                          </Tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </CardWithBorder>
            </div>
          ) : null}
        </>
      </>
    )
  );
};

export default CPRByUpcomingShow;
