import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { DateRangePicker } from "react-dates";
import { connect } from "react-redux";
import { format } from "date-fns";
import { store } from "../redux/store";
import "react-dates/lib/css/_datepicker.css";
import "../lib/DatePickerOveride.css";
import NavigationTab from "../components/NavigationTab";
import XcpiByLvl from "../components/XcpiByLevel";
import DataByte from "../components/DataByte";
import LoadingSpinner from "../components/LoadingSpinner";
import { PDFDownloadLink } from "@react-pdf/renderer";
import DownloadSVG from "../components/DownloadSVG";
import HorseXcpiPDF from "../components/HorseXcpiPDF";
import { navigate } from "@reach/router";
import { checkUserLoggedIn } from "../helperFunctions.js";
import { isXcpihProvider, xcpihOrXcpi } from "../utilities";
import {
  theme,
  Text,
  Spacer,
  Card,
  BackSVG,
  Dropdown,
  Table,
  Th,
  Td,
  Tr,
} from "equiratings-components";

import {
  getHorseErqis,
  getHorseXcpihs,
  getHorseXcpis,
  getHorseOverview,
  getHorseResults,
} from "../services/byHorse";

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const LoadingContainer = styled.div`
  width: 100%;
  padding-top: 15%;
  margin: 0 auto;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const NoData = styled.div`
  background: ${theme.colors.white};
  width: 100%;
  display: flex;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: ${theme.spacing.x2}
  padding: ${({ padding }) => (padding ? padding : theme.spacing.x6)};
  overflow-x: auto;
`;

const HorseOverview = ({ erLevels, selectedHorse, providerId }) => {
  const mapErqiToClass = (arr, func) => {
    let array = [];
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].attributes.erqi !== null) {
        for (let k = 0; k < erLevels.length; k++) {
          if (arr[i].attributes.er_level === erLevels[k].attributes.er_level) {
            array.push({
              title: erLevels[k].attributes.title,
              value: parseFloat(arr[i].attributes.erqi),
              erLevel: arr[i].attributes.er_level,
            });
          }
        }
      }
    }
    func(array);
  };

  const mapXcpiToClass = (arr, func) => {
    let array = [];
    for (let i = 0; i < arr.length; i++) {
      console.log("HERE", arr[0].attributes.xcpih);
      if (isXcpihProvider(store.getState().UserState.providerId)) {
        if (arr[i].attributes.xcpih !== null) {
          for (let k = 0; k < erLevels.length; k++) {
            if (
              arr[i].attributes.height_level === erLevels[k].attributes.er_level
            ) {
              array.push({
                title: arr[i].attributes.height_level_title,
                value: parseFloat(arr[i].attributes.xcpih),
                erLevel: arr[i].attributes.height_level,
              });
            }
          }
        }
      } else {
        if (arr[i].attributes.xcpi !== null) {
          for (let k = 0; k < erLevels.length; k++) {
            if (
              arr[i].attributes.er_level === erLevels[k].attributes.er_level
            ) {
              array.push({
                title: erLevels[k].attributes.title,
                value: parseFloat(arr[i].attributes.xcpi),
                erLevel: arr[i].attributes.er_level,
              });
            }
          }
        }
      }
    }
    func(array);
  };
  useEffect(() => {
    checkUserLoggedIn();
  }, []);

  const [dateRange, setDate] = useState({
    startDate: undefined,
    endDate: undefined,
  });
  const [dateFocus, setFocus] = useState(null);
  const [level, setLevel] = useState(null);
  const [mappedErqis, setErqis] = useState([]);
  const [horseOverview, setOverview] = useState(null);
  const [horseResults, setResults] = useState([]);
  const [horseXcpis, setHorseXcpis] = useState([]);

  const dataIsPresent = horseOverview && mappedErqis && horseResults;

  const formatText = (string) => {
    let splitString = string.toLowerCase().split(" ");
    const formattedString = splitString.map(
      (item) => (item = item.charAt().toUpperCase() + item.substring(1))
    );
    return formattedString.join(" ");
  };

  useEffect(() => {
    getHorseErqis(selectedHorse.id)
      .then(
        (response) =>
          console.log(response.data.data) ||
          mapErqiToClass(response.data.data, setErqis)
      )
      .catch((error) => console.log(error));

    getHorseOverview(selectedHorse.id)
      .then((response) => setOverview(response.data.data[0].attributes))
      .catch((error) => console.log(error));

    if (isXcpihProvider(store.getState().UserState.providerId)) {
      getHorseXcpihs(selectedHorse.id)
        .then((response) => mapXcpiToClass(response.data.data, setHorseXcpis))
        .catch((error) => console.log(error));
    } else {
      getHorseXcpis(selectedHorse.id)
        .then(
          (response) =>
            console.log(response.data.data) ||
            mapXcpiToClass(response.data.data, setHorseXcpis)
        )
        .catch((error) => console.log(error));
    }

    getHorseResults(selectedHorse.id)
      .then((response) =>
        setResults(
          response.data.data.sort(
            (a, b) =>
              new Date(b.attributes.competition.date) -
              new Date(a.attributes.competition.date)
          )
        )
      )
      .catch((error) => console.log(error));

  }, []);

  const [showPDF, setShowPDF] = React.useState(false);
  React.useEffect(() => {
    setTimeout(() => {
      setShowPDF(true);
    }, 500);
  }, []);

  useEffect(() => {
    const parameters = {
      horse_id: selectedHorse.id,
    };

    if (dateRange.startDate || dateRange.endDate) {
      parameters.date_from = format(dateRange.startDate, "YYYY-MM-DD");
      parameters.date_to = format(dateRange.endDate, "YYYY-MM-DD");
    }

    if (level) parameters.er_level = level.attributes.er_level;
    getHorseResults(parameters)
      .then((response) => {
        setResults(
          response.data.data.sort(
            (a, b) =>
              new Date(b.attributes.competition.date) -
              new Date(a.attributes.competition.date)
          )
        );
        parameters = {
          horse_id: selectedHorse.id,
        };
      })
      .catch((error) => console.log(error));
  }, [level, dateRange]);

  const xcpihOrXcpiTitle = xcpihOrXcpi(
    store.getState().UserState.providerId,
    "ERQI 2.0",
    "HFI"
  )

  return dataIsPresent ? (
    <>
      <NavigationTab />
      <Container>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Text
            tag="h1"
            size={[theme.fontScale.x5]}
            weight={theme.fontWeights.bold}
            lineHeight={theme.lineHeights.heading}
          >
            {selectedHorse.name}
          </Text>
          <Text
            tag="h1"
            size={[theme.fontScale.x4]}
            weight={theme.fontWeights.normal}
            lineHeight={theme.lineHeights.heading}
          >
            {selectedHorse.source_id}
          </Text>
        </div>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/by-horse")}
        >
          <BackSVG />
        </div>
      </Container>
      <Spacer size={theme.spacing.x5} />
      {horseOverview ? (
        <>
          <Text
            tag="h1"
            size={[theme.fontScale.x5]}
            weight={theme.fontWeights.bold}
            lineHeight={theme.lineHeights.heading}
          >
            {" "}
            Risk Overview
          </Text>
          <Spacer size={theme.spacing.x3} />
          <DataByte
            bytes={
              store.getState().UserState.providerId !== 4
                ? [
                  { main: horseOverview.xc_runs || 0, sub: "XC Runs" },
                  {
                    main:
                      (horseOverview.xcj_clear_rate * 100).toFixed(1) || 0,
                    sub: "Avg Clear Rate",
                  },
                  {
                    main:
                      (horseOverview.rider_fall_rate * 100).toFixed(1) || 0,
                    sub: "Avg Rider Fall Rate",
                  },
                  {
                    main:
                      (horseOverview.horse_fall_rate * 100).toFixed(1) || 0,
                    sub: "Avg Horse Fall Rate",
                  },
                  // {
                  //   main: horseOverview.restricted_erqis || 0,
                  //   sub: "XC Runs in red (ERQI)",
                  // },
                  {
                    main: horseOverview.restricted_xcpihs || 0,
                    sub: `XC Runs in red (${xcpihOrXcpiTitle})`,
                  },
                ]
                : [
                  { main: horseOverview.xc_runs || 0, sub: "XC Runs" },
                  {
                    main:
                      (horseOverview.xcj_clear_rate * 100).toFixed(1) || 0,
                    sub: "Avg Clear Rate",
                  },
                  {
                    main:
                      (horseOverview.rider_fall_rate * 100).toFixed(1) || 0,
                    sub: "Avg Rider Fall Rate",
                  },
                  {
                    main:
                      (horseOverview.horse_fall_rate * 100).toFixed(1) || 0,
                    sub: "Avg Horse Fall Rate",
                  },
                  {
                    main: horseOverview.restricted_xcpis || 0,
                    sub: `XC Runs in red (${xcpihOrXcpiTitle})`,
                  },
                ]
            }
          />
          <Spacer size={theme.spacing.x4} />
        </>
      ) : null}

      {/*
      {isXcpihProvider(store.getState().UserState.providerId) ? (
        <>
          <Text
            tag="h1"
            size={[theme.fontScale.x5]}
            weight={theme.fontWeights.bold}
            lineHeight={theme.lineHeights.heading}
          >
            ERQI 2.0 By Level
          </Text>
          <Spacer size={theme.spacing.x3} />
           {mappedErqis.length > 0 ? (
            <>
              <ErqiByLvl providerId={providerId} bytes={mappedErqis} />
              <Spacer size={theme.spacing.x4} />
            </>
          ) : (
            <>
              <NoData padding={theme.spacing.x5}>
                <Text
                  tag="h1"
                  size={[theme.fontScale.x4]}
                  weight={theme.fontWeights.normal}
                  lineHeight={theme.lineHeights.heading}
                  style={{
                    color: "#808080",
                    margin: "0 auto",
                  }}
                >
                  It seems we don't have sufficient data to calculate this
                  horses ERQI 2.0.
                </Text>
              </NoData>
            </>
          )}
        </>
      ) : null}*/}
      <>
        <Text
          tag="h1"
          size={[theme.fontScale.x5]}
          weight={theme.fontWeights.bold}
          lineHeight={theme.lineHeights.heading}
        >
          {xcpihOrXcpiTitle} By Level
        </Text>
        <Spacer size={theme.spacing.x3} />
        {console.log("HORSE XCPIS", horseXcpis) || horseXcpis.length > 0 ? (
          <>
            <XcpiByLvl bytes={horseXcpis} />
          </>
        ) : (
          <>
            <NoData padding={theme.spacing.x5}>
              <Text
                tag="h1"
                size={[theme.fontScale.x4]}
                weight={theme.fontWeights.normal}
                lineHeight={theme.lineHeights.heading}
                style={{
                  color: "#808080",
                  margin: "0 auto",
                }}
              >
                It seems we don't have sufficient data to calculate this
                horses {xcpihOrXcpiTitle}
              </Text>
            </NoData>
          </>
        )}
      </>
      <Spacer size={theme.spacing.x4} />
      <Text
        tag="h1"
        size={[theme.fontScale.x5]}
        weight={theme.fontWeights.bold}
        lineHeight={theme.lineHeights.heading}
      >
        Previous Runs
      </Text>
      <Spacer size={theme.spacing.x4} />
      <FormContainer>
        <div style={{ width: "49%", marginRight: "1%" }}>
          <Text
            tag="h1"
            size={[theme.fontScale.x4]}
            weight={theme.fontWeights.bold}
            lineHeight={theme.lineHeights.heading}
          >
            Select a Level
          </Text>
          <Spacer size={theme.spacing.x3} />
          <Dropdown
            onChange={(level) => setLevel(level)}
            readOnly={true}
            padding={theme.spacing.x3}
            ja
            items={erLevels}
            itemToString={(level) =>
              level.attributes ? level.attributes.title : "Select a Level"
            }
            initialItem={"Select a Level"}
            handleFilter={() => () => true}
            displaySuggestion={(level) => (
              <Text
                tag="h1"
                size={[theme.fontScale.x4]}
                weight={theme.fontWeights.normal}
                lineHeight={theme.lineHeights.heading}
              >
                {level.attributes ? level.attributes.title : ""}
              </Text>
            )}
          />
        </div>
        <div style={{ width: "49%" }}>
          <Text
            tag="h1"
            size={[theme.fontScale.x4]}
            weight={theme.fontWeights.bold}
            lineHeight={theme.lineHeights.heading}
          >
            When will your competition run?
          </Text>
          <Spacer size={theme.spacing.x3} />
          <DateRangePicker
            startDate={dateRange.startDate}
            minimumNights={0}
            startDateId="your_unique_start_date_id"
            endDate={dateRange.endDate}
            endDateId="your_unique_end_date_id"
            onDatesChange={({ startDate, endDate }) =>
              setDate({ startDate, endDate })
            }
            focusedInput={dateFocus}
            isOutsideRange={() => false}
            hideKeyboardShortcutsPanel={true}
            displayFormat="YYYY-MM-DD"
            onFocusChange={(focusedInput) => setFocus(focusedInput)}
          />
        </div>
        {horseResults && showPDF && providerId === 4 && (
          <PDFDownloadLink
            style={{ textDecoration: "none" }}
            document={
              <HorseXcpiPDF
                data={horseResults}
                horseName={selectedHorse.name}
              />
            }
            fileName={`${selectedHorse.name}${xcpihOrXcpiTitle}Report.pdf`}
          >
            <div>
              <DownloadSVG />
            </div>
          </PDFDownloadLink>
        )}
      </FormContainer>
      <Spacer size={theme.spacing.x4} />
      {horseResults.length > 0 ? (
        <Card>
          <Table>
            <tbody>
              <tr>
                <Th>Date</Th>
                <Th>Competition</Th>
                <Th>Athlete</Th>
                <Th>{xcpihOrXcpiTitle}</Th>
                <Th>XC Status</Th>
                <Th>XC Jump</Th>
              </tr>
              {horseResults.map((item, index) => {
                return (
                  <Tr key={index} style={{ borderTop: "none" }}>
                    <Td>{item.attributes.competition.date}</Td>
                    <Td>
                      {item.attributes.competition.show_name} -{" "}
                      {item.attributes.competition.name}
                    </Td>
                    <Td>{formatText(item.attributes.athlete.name)}</Td>
                    {providerId === 4 ? (
                      <Td
                        style={{
                          backgroundColor: item.attributes.xcpi_grade.rgba,
                        }}
                      >
                        {item.attributes.xcpi_grade.xcpi
                          ? parseFloat(item.attributes.xcpi_grade.xcpi).toFixed(
                            2
                          )
                          : "-"}
                      </Td>
                    ) : (
                      <Td
                        style={{
                          backgroundColor: item.attributes.xcpih_grade.rgba,
                        }}
                      >
                        {item.attributes.xcpih_grade.xcpih
                          ? parseFloat(
                            item.attributes.xcpih_grade.xcpih
                          ).toFixed(2)
                          : "-"}
                      </Td>
                    )}
                    <Td>
                      {item.attributes.result.xc_status} (
                      {item.attributes.result.xc_code || "-"})
                    </Td>
                    <Td>{item.attributes.result.xc_jump || "-"}</Td>
                  </Tr>
                );
              })}
            </tbody>
          </Table>
        </Card>
      ) : (
        <>
          <NoData>
            <Text
              tag="h1"
              size={[theme.fontScale.x4]}
              weight={theme.fontWeights.normal}
              lineHeight={theme.lineHeights.heading}
              style={{
                color: "#808080",
                margin: "0 auto",
              }}
            >
              Oops! It looks like there were no results returned.
            </Text>
          </NoData>
        </>
      )}
    </>
  ) : (
    <LoadingContainer>
      <LoadingSpinner />
    </LoadingContainer>
  );
};

export default connect(
  ({ RiskOverviewState, HorseState, UserState }) => ({
    erLevels: RiskOverviewState.erLevels,
    selectedHorse: HorseState.selectedHorse,
    providerId: UserState.providerId,
  }),
  {}
)(HorseOverview);
