
import { 
  SET_COMPETITION,
  SET_UPCOMING_COMPETITION
} from './PostCompetitionActions'


const initialState = {
  selectedCompetition: undefined,
  selectedUpcomingCompetition: undefined,
  error: undefined
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_COMPETITION: 
      return {
        selectedCompetition: action.payload
      }
    case SET_UPCOMING_COMPETITION:
      return {
        selectedUpcomingCompetition: action.payload
      }
    default:
      return state
  }
}

