import axios from "axios";
import { format, differenceInCalendarDays } from "date-fns";

const getAccessToken = () => {
  const tokenIsValid =
    format(
      differenceInCalendarDays(Date.now(), localStorage.getItem("timeOfLogin")),
      "DD"
    ) < 3;
  if (!tokenIsValid) {
    axios
      .post(`${process.env.REACT_APP_API_HOST_URL}/tokens`, {
        refresh_token: localStorage.getItem("refreshToken"),
      })
      .then((response) => {
        localStorage.setItem(
          "accessToken",
          response.data.data.attributes.access_token
        );
        localStorage.setItem(
          "refreshToken",
          response.data.data.attributes.refresh_token
        );
      });
  }
  return localStorage.getItem("accessToken");
};

const instance = () => {
  const API_HOST_URL = process.env.REACT_APP_API_HOST_URL;

  const options = {
    baseURL: `${API_HOST_URL}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };
  const client = axios.create(options);
  return client;
};

export const searchAthletes = (firstName, surname) => {
  if (firstName || surname) {
    return instance().get(
      `/dashboard/risk/athlete_search?sort_direction=asc${firstName ? `&athletes_filter[first_name_contains]=${firstName}` : ""
      }${surname ? `&athletes_filter[surname_contains]=${surname}` : ""}`
    );
  } else {
    return instance().get(`/dashboard/risk/athlete_search?sort_direction=asc`);
  }
};

export const getAthleteMers = (athleteId) => {
  const levels = [12, 11, 9, 8, 6, 5, 3];
  return instance().get(`/dashboard/risk/entry_qualifications`, {
    params: {
      athlete_id: athleteId,
      er_levels: levels,
    },
  });
};

export const getHorseXcpis = (horseId) => {
  return instance().get(`/dashboard/risk/horse_xcpis?horse_id=${horseId}`);
};

export const getHorseOverview = (horseId) => {
  return instance().get("/dashboard/risk/dashboard_risk_metrics", {
    params: {
      horse_id: horseId,
    },
  });
};

export const getHorseResults = (parameters) => {
  return instance().get(`/dashboard/risk/dashboard_risk_metrics_erqi`, {
    params: parameters,
  });
};
