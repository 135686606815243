import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { theme, Spacer, Text, TextField } from "equiratings-components";
import { connect } from "react-redux";
import { navigate } from "@reach/router";
import { searchHorses } from "../services/byHorse.js";
import NavigationTab from "../components/NavigationTab";
import { setHorse } from "../redux/Horse/HorseActions";
import { checkUserLoggedIn } from "../helperFunctions.js";

const Horse = styled.div`
  background: ${theme.colors.white};
  border-radius: ${theme.spacing.x2};
  padding: ${theme.spacing.x3};
  border-left: 6px solid ${theme.colors.primary};
  display: flex;
  box-sizing: border-box;
  margin-bottom: ${theme.spacing.x2};
  flex-direction: column;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  cursor: pointer;
`;

const Horses = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media (${theme.breakpoints.notSmall}) {
    flex-direction: row;
  }
`;

const HorsesColumn = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  @media (${theme.breakpoints.notSmall}) {
    width: 49.5%;
  }
`;

const HorseView = ({ setHorse, chosenHorse }) => {
  useEffect(() => {
    checkUserLoggedIn();
  }, []);

  const [selectedHorse, setHorseName] = useState(null);
  const [horses, setHorsesList] = useState([]);

  useEffect(() => {
    searchHorses()
      .then((response) => setHorsesList(response.data.data))
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    searchHorses(selectedHorse)
      .then((response) => setHorsesList(response.data.data))
      .catch((error) => console.log(error));
  }, [selectedHorse]);

  const handleHorseSelection = async (horse) => {
    await setHorse(horse);
    console.log(horse);
    if (chosenHorse) {
      navigate(`/by-horse/${horse.id}`);
    }
  };

  const divideArr = () => {
    let arrLength = horses.length;
    let divider = Math.ceil(arrLength / 2);
    let firstArray = horses.slice(0, divider);
    let secondArray = horses.slice(divider, arrLength);
    return {
      firstArray,
      secondArray,
    };
  };

  return (
    <>
      <NavigationTab />
      <Text
        tag="h1"
        size={[theme.fontScale.x5]}
        weight={theme.fontWeights.bold}
        lineHeight={theme.lineHeights.heading}
      >
        Choose a Horse
      </Text>
      <Spacer size={theme.spacing.x3} />
      <TextField
        placeholder="Enter Horse name here"
        style={{ background: theme.colors.white }}
        onChange={(e) => setHorseName(e.target.value)}
      />
      <Spacer size={theme.spacing.x3} />
      <Horses>
        <HorsesColumn>
          {divideArr().firstArray.map((horse) => {
            return (
              <Horse onClick={() => handleHorseSelection(horse.attributes)}>
                <Spacer size={theme.spacing.x3} />
                <Text
                  tag="h1"
                  size={[theme.fontScale.x5]}
                  weight={theme.fontWeights.bold}
                  lineHeight={theme.lineHeights.heading}
                  cursor="pointer"
                >
                  {horse.attributes.name}
                </Text>
                <Spacer size={theme.spacing.x3} />
              </Horse>
            );
          })}
        </HorsesColumn>
        <HorsesColumn>
          {divideArr().secondArray.map((horse) => {
            return (
              <Horse onClick={() => handleHorseSelection(horse.attributes)}>
                <Spacer size={theme.spacing.x3} />
                <Text
                  tag="h1"
                  size={[theme.fontScale.x5]}
                  weight={theme.fontWeights.bold}
                  lineHeight={theme.lineHeights.heading}
                  cursor="pointer"
                >
                  {horse.attributes.name}
                </Text>
                <Spacer size={theme.spacing.x3} />
              </Horse>
            );
          })}
        </HorsesColumn>
      </Horses>
    </>
  );
};

export default connect(
  ({ HorseState }) => ({ chosenHorse: HorseState.selectedHorse }),
  { setHorse }
)(HorseView);
