import "react-dates/initialize";
import React, { useEffect, useState, createRef } from "react";
import styled from "styled-components";
import { DateRangePicker } from "react-dates";
import { navigate } from "@reach/router";
import { format } from "date-fns";
import { checkUserLoggedIn } from "../helperFunctions.js";
import { uploadUpcomingShow } from "../services/preCompetition";
import "react-dates/lib/css/_datepicker.css";
import "../lib/DatePickerOveride.css";
import NavigationTab from "../components/NavigationTab";
import ShowIdDropdown from "../components/ShowIdDropdown";
import {
  Text,
  Submit,
  theme,
  Spacer,
  TextField,
  BackSVG,
  UploadSVG,
} from "equiratings-components";

const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const FormContainer = styled.div`
  width: 100%;
  display: flex;
`;

const FileUpload = styled.div`
  border-radius: ${theme.spacing.x2};
  width: 100%;
  background: #ececec;
  display: flex;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  justify-content: center;
  border: ${({ showBorder }) =>
    showBorder ? `2px dotted ${theme.colors.secondary}` : "none"};
`;

const ElementsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const CreateACompetition = () => {
  useEffect(() => {
    checkUserLoggedIn();
  }, []);

  const [drag, setDrag] = useState(false);
  const [file, setFile] = useState(undefined);
  const [uploadError, setUploadError] = useState("");
  const [dateFocus, setFocus] = useState(null);

  const [showName, setShowName] = useState(undefined);
  const [sourceId, setSourceId] = useState(undefined);
  const [venueId, setVenueId] = useState(undefined);
  const [dateRange, setDate] = useState({
    startDate: undefined,
    endDate: undefined,
  });

  const [responseMessage, setResponseMessage] = useState(null);
  const formComplete = showName && sourceId && dateRange && venueId;

  const handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDrag(true);
  };
  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDrag(false);
  };
  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  let show = new FormData();

  const handleSubmit = async () => {
    console.log(venueId);
    let formattedStart = format(dateRange.startDate, "YYYY-MM-DD");
    let formattedEnd = format(dateRange.endDate, "YYYY-MM-DD");
    await show.append("upcoming_show[name]", showName);
    await show.append("upcoming_show[start_date]", formattedStart);
    await show.append("upcoming_show[end_date]", formattedEnd);
    await show.append("upcoming_show[source_id]", sourceId);
    await show.append("upcoming_show[venue_id]", venueId);
    await show.append("upcoming_show[upcoming_show_entries_csv]", file);
    if (formComplete) {
      console.log(show.entries());
      await uploadUpcomingShow(show)
        .then(() => {
          setResponseMessage(
            "Success! You will find your new competition on the previous pre comptition page."
          );
          navigate("/pre-competition");
        })
        .catch(() => {
          setUploadError(
            "Oops! There was an issue while uploading your show, please check what you've entered and try again."
          );
        });
    } else {
      alert("You must complete all form values to create a show");
    }
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      await setFile(e.dataTransfer.files[0]);
    } else {
      setUploadError(
        "It looks like you tried to upload a file type other than '.csv', please check your upload and try again."
      );
    }
  };

  const handleFileSelect = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setUploadError(false);
    if (
      e.target.files &&
      e.target.files.length > 0 &&
      e.target.files[0].type.includes("csv")
    ) {
      console.log("FILE ADDED");
      setFile(e.target.files[0]);
    } else {
      setUploadError(
        "It looks like you tried to upload a file type other than '.csv', please check your upload and try again."
      );
    }
  };

  const handleFileClear = () => {
    setFile(undefined);
    show.delete("upcoming_show[upcoming_show_entries_csv]");
  };

  const dragAndDropRef = createRef();
  useEffect(() => {
    let div = dragAndDropRef.current;
    div.addEventListener("dragenter", handleDragIn);
    div.addEventListener("dragleave", handleDragOut);
    div.addEventListener("dragover", handleDrag);
    div.addEventListener("drop", handleDrop);

    return function cleanup() {
      div.removeEventListener("dragenter", handleDragIn);
      div.removeEventListener("dragleave", handleDragOut);
      div.removeEventListener("dragover", handleDrag);
      div.removeEventListener("drop", handleDrop);
    };
  });

  return (
    <>
      <NavigationTab />
      <TitleContainer>
        <Text
          tag="h1"
          size={[theme.fontScale.x5]}
          weight={theme.fontWeights.bold}
          lineHeight={theme.lineHeights.heading}
        >
          Create a show
        </Text>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/pre-competition")}
        >
          <BackSVG />
        </div>
      </TitleContainer>
      <Spacer size={theme.spacing.x4} />
      <FormContainer>
        <div style={{ width: "49.5%", marginRight: "1%" }}>
          <Text
            tag="h1"
            size={[theme.fontScale.x4]}
            weight={theme.fontWeights.bold}
            lineHeight={theme.lineHeights.heading}
          >
            What is your Show's Name?
          </Text>
          <Spacer size={theme.spacing.x3} />
          <TextField
            placeholder="Enter your show name here"
            style={{ background: theme.colors.white }}
            onChange={(e) => setShowName(e.target.value)}
          />
        </div>
        <div style={{ width: "49.5%" }}>
          <Text
            tag="h1"
            size={[theme.fontScale.x4]}
            weight={theme.fontWeights.bold}
            lineHeight={theme.lineHeights.heading}
          >
            When will your competition run?
          </Text>
          <Spacer size={theme.spacing.x3} />
          <DateRangePicker
            startDate={dateRange.startDate}
            minimumNights={0}
            startDateId="your_unique_start_date_id"
            endDate={dateRange.endDate}
            endDateId="your_unique_end_date_id"
            onDatesChange={({ startDate, endDate }) =>
              setDate({ startDate, endDate })
            }
            focusedInput={dateFocus}
            isOutsideRange={() => false}
            hideKeyboardShortcutsPanel={true}
            displayFormat="YYYY-MM-DD"
            onFocusChange={(focusedInput) => setFocus(focusedInput)}
          />
        </div>
      </FormContainer>
      <FormContainer>
        <div style={{ width: "49.5%", marginRight: "1%" }}>
          <Spacer size={theme.spacing.x4} />
          <Text
            tag="h1"
            size={[theme.fontScale.x4]}
            weight={theme.fontWeights.bold}
            lineHeight={theme.lineHeights.heading}
          >
            What is your Venue Name?
          </Text>
          <Spacer size={theme.spacing.x3} />
          <ShowIdDropdown setVenueId={setVenueId} />
        </div>
        <div style={{ width: "49.5%" }}>
          <Spacer size={theme.spacing.x4} />
          <Text
            tag="h1"
            size={[theme.fontScale.x4]}
            weight={theme.fontWeights.bold}
            lineHeight={theme.lineHeights.heading}
          >
            What is your Show ID?
          </Text>
          <Spacer size={theme.spacing.x3} />
          <TextField
            placeholder="Enter your Show ID here"
            style={{ background: theme.colors.white }}
            onChange={(e) => setSourceId(e.target.value)}
          />
        </div>
      </FormContainer>

      <Spacer size={theme.spacing.x4} />
      <Text
        tag="h1"
        size={[theme.fontScale.x4]}
        weight={theme.fontWeights.bold}
        lineHeight={theme.lineHeights.heading}
      >
        Upload your entries
      </Text>
      <Spacer size={theme.spacing.x3} />
      <FileUpload ref={dragAndDropRef} showBorder={drag}>
        <ElementsContainer>
          <input
            id="openFile"
            type="file"
            style={{ display: "none" }}
            onChange={(e) => handleFileSelect(e)}
          />
          <Spacer size={theme.spacing.x6} />
          <UploadSVG />
          {!file ? (
            !uploadError ? (
              <>
                <Spacer size={theme.spacing.x3} />
                <Text
                  tag="h1"
                  size={[theme.fontScale.x5]}
                  weight={theme.fontWeights.bold}
                  lineHeight={theme.lineHeights.heading}
                  cursor="pointer"
                  style={{
                    margin: "0 auto",
                    textAlign: "center",
                    color: "#808080",
                  }}
                >
                  Drag and drop your spreadsheet
                </Text>
                <Spacer size={theme.spacing.x2} />
                <Text
                  tag="h1"
                  size={[theme.fontScale.x4]}
                  weight={theme.fontWeights.normal}
                  lineHeight={theme.lineHeights.heading}
                  cursor="pointer"
                  style={{
                    margin: "0 auto",
                    textAlign: "center",
                    color: "#808080",
                  }}
                >
                  or
                </Text>
                <Spacer size={theme.spacing.x3} />
                <Submit
                  value={"Browse"}
                  style={{
                    width: "140px",
                    textAlign: "center",
                    background: theme.colors.white,
                    color: theme.colors.secondary,
                  }}
                  onClick={() => document.getElementById("openFile").click()}
                />
                <Spacer size={theme.spacing.x4} />
                <Text
                  tag="h1"
                  size={[theme.fontScale.x4]}
                  weight={theme.fontWeights.bold}
                  lineHeight={theme.lineHeights.heading}
                  cursor="pointer"
                  style={{
                    margin: "0 auto",
                    textAlign: "center",
                  }}
                >
                  Don't have a copy of our spreadsheet? Download it{" "}
                  <a href="/create_show.csv" download="create_show.csv">
                    here
                  </a>
                </Text>
                <Spacer size={theme.spacing.x6} />
              </>
            ) : (
              <>
                <Spacer size={theme.spacing.x3} />
                <Text
                  tag="h1"
                  size={[theme.fontScale.x5]}
                  weight={theme.fontWeights.normal}
                  lineHeight={theme.lineHeights.heading}
                  cursor="pointer"
                  style={{
                    margin: "0 auto",
                    width: "60%",
                    textAlign: "center",
                    color: "rgba(239, 124, 124, 1)",
                  }}
                >
                  {uploadError}
                </Text>
                <Spacer size={theme.spacing.x4} />
                <Submit
                  value={"Browse"}
                  style={{
                    width: "140px",
                    textAlign: "center",
                    background: theme.colors.white,
                    color: theme.colors.secondary,
                  }}
                  onClick={(e) => document.getElementById("openFile").click()}
                />
                <Spacer size={theme.spacing.x6} />
              </>
            )
          ) : !uploadError ? (
            <>
              <Spacer size={theme.spacing.x3} />
              <Text
                tag="h1"
                size={[theme.fontScale.x5]}
                weight={theme.fontWeights.normal}
                lineHeight={theme.lineHeights.heading}
                cursor="pointer"
                style={{
                  margin: "0 auto",
                  textAlign: "center",
                }}
              >
                {`You've selected: '${file.name}'`}
              </Text>
              <Spacer size={theme.spacing.x4} />
              <Submit
                value={"Clear"}
                style={{
                  width: "140px",
                  textAlign: "center",
                  background: theme.colors.white,
                  color: theme.colors.secondary,
                }}
                onClick={() => handleFileClear()}
              />
              <Spacer size={theme.spacing.x6} />
            </>
          ) : (
            <>
              <Spacer size={theme.spacing.x3} />
              <Text
                tag="h1"
                size={[theme.fontScale.x5]}
                weight={theme.fontWeights.normal}
                lineHeight={theme.lineHeights.heading}
                cursor="pointer"
                style={{
                  margin: "0 auto",
                  width: "60%",
                  textAlign: "center",
                  color: "rgba(239, 124, 124, 1)",
                }}
              >
                {uploadError}
              </Text>
              <Spacer size={theme.spacing.x4} />
              <Submit
                value={"Browse"}
                style={{
                  width: "140px",
                  textAlign: "center",
                  background: theme.colors.white,
                  color: theme.colors.secondary,
                }}
                onClick={(e) => document.getElementById("openFile").click()}
              />
              <Spacer size={theme.spacing.x6} />
            </>
          )}
        </ElementsContainer>
      </FileUpload>
      <Spacer size={theme.spacing.x3} />
      <Submit
        value={"Create Show"}
        style={{
          float: "right",
          width: "140px",
          background: !formComplete
            ? theme.colors.lightGrey
            : theme.colors.secondary,
          color: !formComplete ? "#808080" : theme.colors.white,
        }}
        onClick={() => handleSubmit()}
      />
      <Spacer size={theme.spacing.x4} />
    </>
  );
};

export default CreateACompetition;
