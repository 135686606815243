import React from 'react'
import styled from 'styled-components'
import { Text, Spacer, theme } from 'equiratings-components'

const Container = styled.div`
  background: ${theme.colors.white};
  border-radius: ${theme.spacing.x2};
  padding: ${theme.spacing.x3};
  border-left: 6px solid ${theme.colors.primary};
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  margin-bottom: ${theme.spacing.x2};
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  flex-direction: column;
  align-items: center;
   @media (${theme.breakpoints.notSmall}) {
    flex-direction: row;
    align-items: flex-start;
  }
`

const Byte = styled.div`
 display: flex;
 flex-direction: column;
 align-items: center;
 margin-bottom: ${theme.spacing.x3};
 &:last-child {
  margin-bottom: 0;
 }
 @media (${theme.breakpoints.notSmall}) { 
  margin-bottom: 0;
  align-items: flex-start;
 }
`

const DataByte = ({ bytes }) => {
  return (
  <Container>
  {bytes.map(byte => {
    return (
      <Byte>
        <Text
          tag="h1"
          size={[theme.fontScale.x6]}
          weight={theme.fontWeights.bold}
          lineHeight={theme.lineHeights.heading}
        >
	  {byte.main}
          </Text>
          <Spacer size={theme.spacing.x2} />
          <Text
          tag="h1"
          size={[theme.fontScale.x3]}
          weight={theme.fontWeights.normal}
          lineHeight={theme.lineHeights.heading}
        >
	  {byte.sub}
        </Text>
      </Byte>
    )
  })}
  </Container>
  )
} 

export default DataByte 
