import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import { persistStore, persistReducer } from "redux-persist";
import { composeWithDevTools } from "redux-devtools-extension";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web and AsyncStorage for react-native

import UserState from "./User/UserState";
import RiskOverviewState from "./RiskOverview/RiskOverviewState";
import RiskAnalysisState from "./RiskAnalysis/RiskAnalysisState";
import PostCompetitionState from "./PostCompetition/PostCompetitionState";
import HorseState from "./Horse/HorseState";
import CPRState from "./CPRS/CPRState";
import AthleteMerState from "./AthleteMERS/AthleteMerState";

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    UserState,
    RiskOverviewState,
    RiskAnalysisState,
    PostCompetitionState,
    HorseState,
    CPRState,
    AthleteMerState,
  })
);

export let store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunk, logger))
);

export let persistor = persistStore(store);
