import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Spacer, theme, } from "equiratings-components";
import { checkUserLoggedIn } from "../helperFunctions";
import NavigationTab from "../components/NavigationTab";
import RiskOverviewByClass from "../components/RiskOverviewByClass";
import RiskOverviewByVenue from "../components/RiskOverviewByVenue";
import RiskOverviewByYear from "../components/RiskOverviewByYear";
import Explainer from "../components/Explainer";
import LoadingSpinner from "../components/LoadingSpinner";
import { currentYear } from "../utilities";

import {
  getOverviewByClass,
  getOverviewByYear,
  getLevels,
  getOverviewByVenue
} from "../redux/RiskOverview/RiskOverviewActions";

const RiskOverview = ({
  getOverviewByClass,
  getLevels,
  getOverviewByYear,
  getOverviewByVenue,
  byYear,
  erLevels,
  byClass,
  byVenue,
}) => {
  useEffect(() => {
    checkUserLoggedIn();
  }, []);

  useEffect(() => {
    getLevels();
    getOverviewByClass([]);
    getOverviewByYear(currentYear);
    getOverviewByVenue([], currentYear)
  }, []);

  return (
    <>
      <NavigationTab />
      <Explainer
        text={
          "The following tables show a general overview of your federations risk performance. " +
          "The first table shows your performance year on year, and is filterable by level. " +
          "The second, shows a per level breakdown for a given year."
        }
      />
      <Spacer size={theme.spacing.x4} />
      {!byClass || !byYear || !byVenue ? (
        <LoadingSpinner />
      ) : (
        <>
          <RiskOverviewByClass
            byClass={byClass}
            erLevels={erLevels}
            getOverviewByClass={getOverviewByClass}
          />
          <Spacer size={theme.spacing.x5} />
          <RiskOverviewByYear
            byYear={byYear}
            getOverviewByYear={getOverviewByYear}
          />
          <Spacer size={theme.spacing.x5} />
          <RiskOverviewByVenue
            byVenue={byVenue}
            erLevels={erLevels}
            getOverviewByVenue={getOverviewByVenue}
          />
          <Spacer size={theme.spacing.x3} />
        </>
      )}
    </>
  );
};

export default connect(
  ({ RiskOverviewState }) => ({
    byYear: RiskOverviewState.byYear,
    byClass: RiskOverviewState.byClass,
    byVenue: RiskOverviewState.byVenue,
    erLevels: RiskOverviewState.erLevels,
  }),
  { getOverviewByClass, getOverviewByYear, getLevels, getOverviewByVenue }
)(RiskOverview);
