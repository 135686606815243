import React from "react";
import styled from "styled-components";
import { theme, Text } from "equiratings-components";
import { store } from "../redux/store";
import { xcpihOrXcpi } from "../utilities";

const TabContainer = styled.div`
  display: flex;
  box-sizing: border-box;
`;
const TabItem = styled.div`
  display: flex;
  margin-right: 2%;
  border-bottom: ${({ active }) =>
    active ? `2px solid ${theme.colors.primary}` : "none"};
`;

const RiskValueTab = ({ riskValue, setRiskValue }) => {
  const providerId = store.getState().UserState.providerId;
  return (
    <>
      <TabContainer>
        <TabItem active={riskValue === 1} onClick={() => setRiskValue(1)}>
          <Text
            tag="h1"
            size={[theme.fontScale.x4]}
            weight={theme.fontWeights.bold}
            cursor={"pointer"}
          >
            {xcpihOrXcpi(providerId, "ERQI 2.0", "HFI")}
          </Text>
        </TabItem>
      </TabContainer>
    </>
  );
};
export default RiskValueTab;
