import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { theme, Spacer, Text, TextField } from 'equiratings-components';
import { navigate } from '@reach/router';
import { connect } from 'react-redux';
import NavigationTab from '../components/NavigationTab';
import { format } from 'date-fns';
import { checkUserLoggedIn } from '../helperFunctions';

import {
  getCompetitions,
  getMostRecentCompetitions,
  getMostRecentCompetitionsFromDate,
} from '../services/postCompetition';

import {
  setPostCompetition,
  setPreCompetition,
} from '../redux/PostCompetition/PostCompetitionActions';

const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: baseline;
`;

const Competition = styled.div`
  background: ${theme.colors.white};
  border-radius: ${theme.spacing.x2};
  padding: ${theme.spacing.x2};
  border-left: 6px solid ${theme.colors.primary};
  display: flex;
  box-sizing: border-box;
  margin-bottom: ${theme.spacing.x2};
  flex-direction: column;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  cursor: pointer;
`;

const CreateShowContainer = styled.div`
  width: 100%;
  background: ${theme.colors.white};
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  padding: ${theme.spacing.x2} ${theme.spacing.x3};
  display: flex;
  justify-content: space-between;
  border-radius: ${theme.spacing.x2}
  align-items: center; cursor: pointer;
`;

const Competitions = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media (${theme.breakpoints.notSmall}) {
    flex-direction: row;
  }
`;

const CompetitionColumn = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  @media (${theme.breakpoints.notSmall}) {
    width: 49.5%;
  }
`;

const CompetitionsList = ({ setPostCompetition, setPreCompetition }) => {
  const [filteredShows, setShows] = useState([]);
  const [filteredUpcomingShows, setUpcomingShow] = useState([]);

  useEffect(() => {
    checkUserLoggedIn();
  }, []);

  useEffect(() => {
    getMostRecentCompetitions('show').then(response =>
      setShows(response.data.data),
    );
    getMostRecentCompetitionsFromDate('upcoming_show').then(response =>
      setUpcomingShow(response.data.data),
    );
  }, []);

  const handleRouteChange = async comp => {
    if (window.location.pathname.includes('post')) {
      await setPostCompetition(comp);
      navigate(`/post-competition/${comp.attributes.venue_id}`);
    } else {
      await setPreCompetition(comp);
      navigate(`/pre-competition/${comp.name}`);
    }
  };

  const handleTextChange = async e => {
    if (window.location.pathname.includes('post')) {
      getCompetitions(e.target.value, 'show').then(response =>
        setShows(response.data.data),
      );
    } else {
      console.log('CALLING UPCOMING');
      await getCompetitions(e.target.value, 'upcoming_show').then(response =>
        setUpcomingShow(response.data.data),
      );
      console.log(filteredUpcomingShows);
    }
  };

  const divideArr = () => {
    if (window.location.pathname.includes('post')) {
      console.log('Calling ');
      let arrLength = filteredShows.length;
      let divider = Math.ceil(arrLength / 2);
      let firstArray = filteredShows.slice(0, divider);
      let secondArray = filteredShows.slice(divider, arrLength);
      return {
        firstArray,
        secondArray,
      };
    } else {
      console.log('Getting Called');
      let arrLength = filteredUpcomingShows.length;
      let divider = Math.round(arrLength / 2);
      let firstArray = filteredUpcomingShows.slice(0, divider);
      let secondArray = filteredUpcomingShows.slice(divider, arrLength);
      return {
        firstArray,
        secondArray,
      };
    }
  };

  return window.location.pathname.includes('post') ? (
    <>
      <NavigationTab />
      <TitleContainer>
        <Text
          tag="h1"
          size={[theme.fontScale.x4]}
          weight={theme.fontWeights.bold}
          lineHeight={theme.lineHeights.heading}>
          Search for shows
        </Text>
      </TitleContainer>
      <Spacer size={theme.spacing.x3} />
      <TextField
        placeholder={'Enter Show name here'}
        style={{ background: theme.colors.white, border: 'none' }}
        onChange={e => handleTextChange(e)}
      />
      <Spacer size={theme.spacing.x3} />
      {filteredUpcomingShows.length > 0 || filteredShows.length > 0 ? (
        <Competitions>
          <CompetitionColumn>
            {divideArr().firstArray.map((comp, idx) => {
              return (
                <Competition onClick={() => handleRouteChange(comp)}>
                  <Text
                    tag="h1"
                    size={[theme.fontScale.x5]}
                    weight={theme.fontWeights.bold}
                    lineHeight={theme.lineHeights.heading}
                    cursor="pointer">
                    {comp.attributes.name}
                  </Text>
                  <Spacer size={theme.spacing.x4} />
                  <Text
                    tag="h1"
                    size={[theme.fontScale.x3]}
                    weight={theme.fontWeights.normal}
                    lineHeight={theme.lineHeights.heading}
                    cursor="pointer">
                    {format(comp.attributes.start_date, 'DD/MM/YYYY')}
                  </Text>
                </Competition>
              );
            })}
          </CompetitionColumn>
          <CompetitionColumn>
            {divideArr().secondArray.map((comp, idx) => {
              return (
                <Competition onClick={() => handleRouteChange(comp)}>
                  <Text
                    tag="h1"
                    size={[theme.fontScale.x5]}
                    weight={theme.fontWeights.bold}
                    lineHeight={theme.lineHeights.heading}
                    cursor="pointer">
                    {comp.attributes.name}
                  </Text>
                  <Spacer size={theme.spacing.x4} />
                  <Text
                    tag="h1"
                    size={[theme.fontScale.x3]}
                    weight={theme.fontWeights.normal}
                    lineHeight={theme.lineHeights.heading}
                    cursor="pointer">
                    {format(comp.attributes.start_date, 'DD/MM/YYYY')}
                  </Text>
                </Competition>
              );
            })}
          </CompetitionColumn>
        </Competitions>
      ) : (
        <div>
          <Spacer size={theme.spacing.x5} />
          <Text
            tag="h1"
            size={[theme.fontScale.x5]}
            weight={theme.fontWeights.normal}
            lineHeight={theme.lineHeights.heading}
            cursor="pointer"
            style={{
              margin: '0 auto',
              textAlign: 'center',
              color: '#808080',
            }}>
            We're sorry, your search returned no results.
          </Text>
        </div>
      )}
    </>
  ) : (
    <>
      <NavigationTab />
      <CreateShowContainer onClick={() => navigate('/create-show')}>
        <Text
          tag="h1"
          size={[theme.fontScale.x5]}
          weight={theme.fontWeights.bold}
          lineHeight={theme.lineHeights.heading}
          cursor="pointer">
          Create a Show
        </Text>
        <Text
          tag="h1"
          size={[theme.fontScale.x7]}
          weight={theme.fontWeights.normal}
          lineHeight={theme.lineHeights.heading}
          cursor="pointer">
          +
        </Text>
      </CreateShowContainer>
      <Spacer size={theme.spacing.x4} />
      <TitleContainer>
        <Text
          tag="h1"
          size={[theme.fontScale.x4]}
          weight={theme.fontWeights.bold}
          lineHeight={theme.lineHeights.heading}>
          Search for shows
        </Text>
      </TitleContainer>
      <Spacer size={theme.spacing.x3} />
      <TextField
        placeholder={'Enter Show name here'}
        style={{ background: theme.colors.white, border: 'none' }}
        onChange={e => handleTextChange(e)}
      />
      <Spacer size={theme.spacing.x3} />
      {filteredUpcomingShows.length > 0 ? (
        <Competitions>
          <CompetitionColumn>
            {divideArr().firstArray.map((comp, idx) => {
              return (
                <Competition onClick={() => handleRouteChange(comp)}>
                  <Text
                    tag="h1"
                    size={[theme.fontScale.x5]}
                    weight={theme.fontWeights.bold}
                    lineHeight={theme.lineHeights.heading}
                    cursor="pointer">
                    {comp.attributes.name}
                  </Text>
                  <Spacer size={theme.spacing.x4} />
                  <Text
                    tag="h1"
                    size={[theme.fontScale.x3]}
                    weight={theme.fontWeights.normal}
                    lineHeight={theme.lineHeights.heading}
                    cursor="pointer">
                    {format(comp.attributes.start_date, 'DD/MM/YYYY')}
                  </Text>
                </Competition>
              );
            })}
          </CompetitionColumn>
          <CompetitionColumn>
            {divideArr().secondArray.map((comp, idx) => {
              return (
                <Competition onClick={() => handleRouteChange(comp)}>
                  <Text
                    tag="h1"
                    size={[theme.fontScale.x5]}
                    weight={theme.fontWeights.bold}
                    lineHeight={theme.lineHeights.heading}
                    cursor="pointer">
                    {comp.attributes.name}
                  </Text>
                  <Spacer size={theme.spacing.x4} />
                  <Text
                    tag="h1"
                    size={[theme.fontScale.x3]}
                    weight={theme.fontWeights.normal}
                    lineHeight={theme.lineHeights.heading}
                    cursor="pointer">
                    {format(comp.attributes.start_date, 'DD/MM/YYYY')}
                  </Text>
                </Competition>
              );
            })}
          </CompetitionColumn>
        </Competitions>
      ) : (
        <div>
          <Spacer size={theme.spacing.x5} />
          <Text
            tag="h1"
            size={[theme.fontScale.x5]}
            weight={theme.fontWeights.normal}
            lineHeight={theme.lineHeights.heading}
            cursor="pointer"
            style={{
              margin: '0 auto',
              textAlign: 'center',
              color: '#808080',
            }}>
            We're sorry, your search returned no results.
          </Text>
        </div>
      )}
    </>
  );
};

export default connect(({ PostCompetitionState }) => ({}), {
  setPostCompetition,
  setPreCompetition,
})(CompetitionsList);
