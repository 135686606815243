import React from "react";
import { store } from "../redux/store";
import { xcpihOrXcpi, hexColourFromRgba, hexOpacityFromRgba } from "../utilities";
import { round } from "../helperFunctions";

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  body: {
    padding: 40,
    backgroundColor: "#fafafa",
    color: "#1a3852",
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    backgroundColor: "white",
    borderRadius: 3,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: "auto",
    marginTop: 7,
    marginBottom: 7,
    fontSize: 8,
  },
  cellText: {
    margin: "auto",
    marginTop: 7,
    marginBottom: 7,
    fontSize: 8,
    opacity: 1,
  },
});

const MyDocument = ({ data, horseName }) => {
  const xcpihOrXcpiTitle = xcpihOrXcpi(
    store.getState().UserState.providerId,
    "ERQI 2.0",
    "HFI"
  )

  return (
    <Document>
      <Page style={styles.body}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            paddingBottom: 10,
            marginBottom: 15,
            borderBottomWidth: 3,
            borderBottomColor: "#FFBC49",
          }}
        >
          {store.getState().UserState.providerId === 4
            ? (
              <>
                <Text style={{ fontSize: 20, }}>EquiRatings / FEI Risk Management</Text>
                <Image
                  style={{ height: 25, width: 80 }}
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/51/FEI_Logo_RGB_Purple.svg/1200px-FEI_Logo_RGB_Purple.svg.png"
                />
              </>
            )
            : (
              <Text style={{ fontSize: 20, }}>EquiRatings Risk Management</Text>
            )
          }
        </View>
        <Text
          style={{
            fontSize: 15,
            paddingBottom: 3,
          }}
        >
          {`${horseName} ${xcpihOrXcpiTitle} Report`}
        </Text>
        <View style={{ marginTop: 10, marginBottom: 10 }} />
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View
              style={{
                width: "20%",
                borderStyle: "solid",
                borderWidth: 1,
                borderLeftWidth: 0,
                borderTopWidth: 0,
              }}
            >
              <Text
                style={{
                  margin: "auto",
                  marginTop: 5,
                  marginBottom: 5,
                  fontSize: 10,
                }}
              >
                Date
              </Text>
            </View>
            <View
              style={{
                width: "25%",
                borderStyle: "solid",
                borderWidth: 1,
                borderTopWidth: 0,
                borderLeftWidth: 0,
              }}
            >
              <Text
                style={{
                  margin: "auto",
                  marginTop: 5,
                  marginBottom: 5,
                  fontSize: 10,
                }}
              >
                Competition
              </Text>
            </View>
            <View
              style={{
                width: "20%",
                borderStyle: "solid",
                borderWidth: 1,
                borderTopWidth: 0,
                borderLeftWidth: 0,
              }}
            >
              <Text
                style={{
                  margin: "auto",
                  marginTop: 5,
                  marginBottom: 5,
                  fontSize: 10,
                }}
              >
                Athlete
              </Text>
            </View>
            <View
              style={{
                width: "15%",
                borderStyle: "solid",
                borderWidth: 1,
                borderTopWidth: 0,
                borderLeftWidth: 0,
              }}
            >
              <Text
                style={{
                  margin: "auto",
                  marginTop: 5,
                  marginBottom: 5,
                  fontSize: 10,
                }}
              >
                {xcpihOrXcpiTitle}
              </Text>
            </View>
            <View
              style={{
                width: "10%",
                borderStyle: "solid",
                borderWidth: 1,
                borderTopWidth: 0,
                borderLeftWidth: 0,
              }}
            >
              <Text
                style={{
                  margin: "auto",
                  marginTop: 5,
                  marginBottom: 5,
                  fontSize: 10,
                }}
              >
                XC Status
              </Text>
            </View>
            <View
              style={{
                width: "10%",
                borderStyle: "solid",
                borderWidth: 1,
                borderTopWidth: 0,
                borderLeftWidth: 0,
              }}
            >
              <Text
                style={{
                  margin: "auto",
                  marginTop: 5,
                  marginBottom: 5,
                  fontSize: 10,
                }}
              >
                XC Jump
              </Text>
            </View>
          </View>
          {data
            ? data.map((item, index) => {
              return (
                <View style={styles.tableRow}>
                  <View
                    style={{
                      width: "20%",
                      borderStyle: "solid",
                      borderWidth: 1,
                      borderLeftWidth: 0,
                      borderTopWidth: 0,
                    }}
                  >
                    <Text style={styles.tableCell}>
                      {item.attributes.competition.date}
                    </Text>
                  </View>
                  <View
                    style={{
                      width: "25%",
                      borderStyle: "solid",
                      borderWidth: 1,
                      borderTopWidth: 0,
                      borderLeftWidth: 0,
                    }}
                  >
                    <Text style={styles.tableCell}>
                      {item.attributes.competition.show_name} -{" "}
                      {item.attributes.competition.name}
                    </Text>
                  </View>
                  <View
                    style={{
                      width: "20%",
                      borderStyle: "solid",
                      borderWidth: 1,
                      borderTopWidth: 0,
                      borderLeftWidth: 0,
                    }}
                  >
                    <Text style={styles.tableCell}>
                      {item.attributes.athlete.name}
                    </Text>
                  </View>
                  <View
                    style={{
                      width: "15%",
                      borderStyle: "solid",
                      borderWidth: 1,
                      borderTopWidth: 0,
                      borderLeftWidth: 0,
                    }}
                  >
                    <View
                      style={
                        (styles.tableCell,
                        {
                          flex: 1,
                          backgroundColor: hexColourFromRgba(
                            xcpihOrXcpi(
                              store.getState().UserState.providerId,
                              item.attributes.xcpih_grade.rgba,
                              item.attributes.xcpi_grade.rgba
                            )
                          ),
                          opacity: hexOpacityFromRgba(
                            xcpihOrXcpi(
                              store.getState().UserState.providerId,
                              item.attributes.xcpih_grade.rgba,
                              item.attributes.xcpi_grade.rgba
                            )
                          ),
                        })
                      }
                    >
                      <Text style={styles.cellText}>
                        {xcpihOrXcpi(
                          store.getState().UserState.providerId,
                          round(item.attributes.xcpih_grade.xcpih, 2) || "-",
                          round(item.attributes.xcpi_grade.xcpi, 2) || "-"
                        )}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      width: "10%",
                      borderStyle: "solid",
                      borderWidth: 1,
                      borderTopWidth: 0,
                      borderLeftWidth: 0,
                    }}
                  >
                    <Text style={styles.tableCell}>
                      {item.attributes.result.xc_status} (
                      {item.attributes.result.xc_code || "-"})
                    </Text>
                  </View>
                  <View
                    style={{
                      width: "10%",
                      borderStyle: "solid",
                      borderWidth: 1,
                      borderTopWidth: 0,
                      borderLeftWidth: 0,
                    }}
                  >
                    <Text style={styles.tableCell}>
                      {item.attributes.result.xc_jump}
                    </Text>
                  </View>
                </View>
              );
            })
            : null}
        </View>
      </Page>
    </Document>
  );
};

export default MyDocument;
