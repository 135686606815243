import React, { useState } from "react";
import styled from "styled-components";
import { theme, Text, Spacer } from "equiratings-components";
import { navigate } from "@reach/router";
import { connect } from "react-redux";
import { store } from "../redux/store";

import {
  getErqiAnalysis,
  getXcpiAnalysis,
} from "../redux/RiskAnalysis/RiskAnalysisActions";

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;
const Tab = styled.div`
  padding: ${theme.spacing.x2} 0;
  margin-right: ${theme.spacing.x4}
  border-bottom: ${({ active }) =>
    active ? `3px solid ${theme.colors.primary}` : "none"};
`;

const NavigationTab = () => {
  const [activeTab, setActiveTab] = useState(
    parseInt(localStorage.getItem("navTab"), 10) || 0
  );

  const handleTabClick = async (tab, url) => {
    await localStorage.setItem("navTab", tab);
    await setActiveTab(tab);
    navigate(url);
  };
  return (
    <>
      <Spacer size={theme.spacing.x5} />
      <Container>
        <Tab
          active={activeTab === 0}
          onClick={() => handleTabClick(0, "/risk-overview")}
        >
          <Text
            tag="h1"
            size={[theme.fontScale.x5]}
            weight={theme.fontWeights.bold}
            lineHeight={theme.lineHeights.heading}
            cursor={"pointer"}
          >
            Risk Overview
          </Text>
        </Tab>
        <Tab
          active={activeTab === 1}
          onClick={() => {
            handleTabClick(1, "/risk-analysis");
          }}
        >
          <Text
            tag="h1"
            size={[theme.fontScale.x5]}
            weight={theme.fontWeights.bold}
            lineHeight={theme.lineHeights.heading}
            cursor={"pointer"}
          >
            Risk Analysis
          </Text>
        </Tab>
        <Tab
          active={activeTab === 2}
          onClick={() => handleTabClick(2, "/post-competition")}
        >
          <Text
            tag="h1"
            size={[theme.fontScale.x5]}
            weight={theme.fontWeights.bold}
            lineHeight={theme.lineHeights.heading}
            cursor={"pointer"}
          >
            {store.getState().UserState.providerId === 4
              ? "Post Show"
              : "Past Shows"}
          </Text>
        </Tab>

        <Tab
          active={activeTab === 3}
          onClick={() => handleTabClick(3, "/pre-competition")}
        >
          <Text
            tag="h1"
            size={[theme.fontScale.x5]}
            weight={theme.fontWeights.bold}
            lineHeight={theme.lineHeights.heading}
            cursor={"pointer"}
          >
            {store.getState().UserState.providerId === 4
              ? "Pre Show"
              : "Upcoming Shows"}
          </Text>
        </Tab>
        <Tab
          active={activeTab === 4}
          onClick={() => handleTabClick(4, "/by-horse")}
        >
          <Text
            tag="h1"
            size={[theme.fontScale.x5]}
            weight={theme.fontWeights.bold}
            lineHeight={theme.lineHeights.heading}
            cursor={"pointer"}
          >
            By Horse
          </Text>
        </Tab>
        {store.getState().UserState.providerId === 10 && (
          <Tab
            active={activeTab === 5}
            onClick={() => handleTabClick(5, "/cprs")}
          >
            <Text
              tag="h1"
              size={[theme.fontScale.x5]}
              weight={theme.fontWeights.bold}
              lineHeight={theme.lineHeights.heading}
              cursor={"pointer"}
            >
              CPR
            </Text>
          </Tab>
        )}
        {store.getState().UserState.providerId === 12 && (
          <Tab
            active={activeTab === 6}
            onClick={() => handleTabClick(6, "/athlete-mers")}
          >
            <Text
              tag="h1"
              size={[theme.fontScale.x5]}
              weight={theme.fontWeights.bold}
              lineHeight={theme.lineHeights.heading}
              cursor={"pointer"}
            >
              Athlete MERs
            </Text>
          </Tab>
        )}
      </Container>
      <Spacer size={theme.spacing.x5} />
    </>
  );
};

export default connect(({ }) => ({}), { getErqiAnalysis, getXcpiAnalysis })(
  NavigationTab
);
