import axios from "axios";
import { format, differenceInCalendarDays } from "date-fns";

const getAccessToken = () => {
  const tokenIsValid =
    format(
      differenceInCalendarDays(Date.now(), localStorage.getItem("timeOfLogin")),
      "DD"
    ) < 3;
  if (!tokenIsValid) {
    axios
      .post(`${process.env.REACT_APP_API_HOST_URL}/tokens`, {
        refresh_token: localStorage.getItem("refreshToken"),
      })
      .then((response) => {
        localStorage.setItem(
          "accessToken",
          response.data.data.attributes.access_token
        );
        localStorage.setItem(
          "refreshToken",
          response.data.data.attributes.refresh_token
        );
      });
  }
  return localStorage.getItem("accessToken");
};

const instance = () => {
  const API_HOST_URL = process.env.REACT_APP_API_HOST_URL;

  const options = {
    baseURL: `${API_HOST_URL}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };
  const client = axios.create(options);
  return client;
};

export const cprsByDate = (dateFrom, dateTo) => {
  return instance().get(`/dashboard/risk/recent_cprs`, {
    params: {
      date_from: dateFrom,
      date_to: dateTo,
      include_inactive_cprs: true,
    },
  });
};

export const cprsByUpcomingCompetition = (dateFrom, dateTo) => {
  console.log(dateFrom, dateTo);
  return instance().get(
    `/dashboard/risk/entry_cprs?date_from=${dateFrom}&date_to=${dateTo}`,
    {}
  );
};

export const allCprs = () => {
  return instance().get("/dashboard/risk/recent_cprs", {});
};
