import React from "react";

const DownloadSVG = () => (
  <svg
    width="35"
    height="35"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 5V26.6667"
      stroke="#1A3852"
      strokeWidth="2"
      strokeLinecap="square"
    />
    <path
      d="M11.666 20L19.9993 28.3333L28.3327 20"
      stroke="#1A3852"
      strokeWidth="2"
      strokeLinecap="square"
    />
    <path
      d="M33.3327 35H6.66602"
      stroke="#1A3852"
      strokeWidth="2"
      strokeLinecap="square"
    />
  </svg>
);
export default DownloadSVG;
