import { store } from "../src/redux/store";
import React from "react";
import { navigate } from "@reach/router";

export const round = (value, precision, isPercentage) => {
  if (value != null) {
    var multiplier = Math.pow(10, precision || 0);
    if (isPercentage) {
      return (
        (Math.round(value * multiplier * 100) / multiplier).toFixed(precision) +
        "%"
      );
    } else {
      return (Math.round(value * multiplier) / multiplier).toFixed(precision);
    }
  } else {
    return "-";
  }
};

export const getProviderImage = () => {
  const provId = store.getState().UserState.providerId;
  switch (provId) {
    case 4:
      const fei = require("../src/assets/FEI logo copy.png");
      return <img src={fei} alt={"FEI logo"} height="20px" width="80px" />;
    case 7:
      const ea = require("../src/assets/Equestrian-Australia.png");
      return <img src={ea} alt={"Equestrian Australia logo"} height="30px" width="100px" />;
    case 10:
      const be = require("../src/assets/British_Eventing.jpg");
      return <img src={be} alt={"British Eventing logo"} height="30px" width="100px" />;
    case 11:
      const usea = require("../src/assets/USEA-LOGO.jpg");
      return <img src={usea} alt={"USEA logo"} height="35px" width="60px" />;
    default:
      const ei = require("../src/assets/eiLogo.png");
      return <img src={ei} alt={"Eventing Ireland logo"} height="35px" width="60px" />;
  }
};

export const checkUserLoggedIn = () => {
  const userIsLoggedIn = store.getState().UserState.isUserLoggedIn;
  if (!userIsLoggedIn) {
    navigate("/");
  }
};
