import React, { useEffect } from "react";
import { Router, navigate } from "@reach/router";
import { format, differenceInCalendarDays } from "date-fns";

import { store } from "./redux/store";
import { userLogout } from "./redux/User/UserActions";

import Login from "./screens/Login";
import RiskOverview from "./screens/RiskOverview";
import RiskAnalysis from "./screens/RiskAnalysis";
import PreCompetition from "./screens/PreCompetition";
import PostCompetition from "./screens/PostCompetition";
import HorseView from "./screens/HorseView";
import Competition from "./screens/Competition";
import CreateAShow from "./screens/CreateAShow";
import HorseOverview from "./screens/HorseOverview";
import CPRS from "./screens/CPRS";
import AthleteSearch from "./screens/AthletesSearch";
import AthleteMers from "./screens/AthleteMers";

const Navigation = () => {
  const tokenIsValid =
    format(
      differenceInCalendarDays(
        Date.now(),
        store.getState().UserState.timeOfLogin
      ),
      "DD"
    ) < 4;

  useEffect(() => {
    if (!tokenIsValid || !store.getState().UserState.isUserLoggedIn) {
      store.dispatch(userLogout());
      navigate("/");
    } else navigate("/risk-overview");
  }, [tokenIsValid]);

  return (
    <>
      <Router>
        <Login path="/" />
        <RiskOverview path="/risk-overview" />
        <RiskAnalysis path="/risk-analysis" />
        <Competition path="/post-competition" />
        <Competition path="/pre-competition" />
        <PostCompetition path="/post-competition/:name" />
        <PreCompetition path="/pre-competition/:name" />
        <HorseView path="/by-horse" />
        <HorseOverview path="/by-horse/:horseId" />
        <CreateAShow path="/create-show" />
        <CPRS path="/cprs" />
        <AthleteSearch path="/athlete-mers" />
        <AthleteMers path="/athlete-mers/:athleteId" />
      </Router>
    </>
  );
};

export default Navigation;
