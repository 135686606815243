import { SET_ATHLETE } from "./AthleteMerActions.js";

const initialState = {
  selectedAthlete: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ATHLETE:
      return {
        selectedAthlete: action.payload,
      };
    default:
      return state;
  }
};
