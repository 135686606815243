import React, { useState } from 'react'
import styled from 'styled-components'
import Downshift from 'downshift'
import { connect } from 'react-redux'
import { theme, Text, Spacer } from 'equiratings-components'
import axios from 'axios'

const Input = styled.input`
  border-radius: ${theme.spacing.x2};
  box-sizing: border-box;
  border: none;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  font-size: ${({ fontSize }) => fontSize || theme.fontScale.x4};
  font-weight: ${theme.fontWeights.bold}
  color: ${theme.colors.secondary};
  margin: 0;
  padding: ${({ padding }) => padding ? padding : theme.spacing.x3};
  outline: none;
  width: 100%;
`

const Relative = styled.div`
  position: relative;
  & > svg,
  & > button {
    position: absolute;
    right: ${theme.spacing.x4};
    top: 40%;
    transform: translateY(-50%);
    -webkit-appearance: none;
    border: none;
    border-radius: 0;
    background: none;
    padding: 0;
    margin: 0;
    & > svg {
      position: absolute;
      top: 1px;
      transform: ${({ isOpen }) => isOpen ? `rotate(180deg)` : ``} 
    }
  }
`

const Suggestions = styled.div`
  border-radius: 0 4px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  background: transparent;
  border-radius: ${theme.spacing.x2};
  border: 0.8px solid ${theme.colors.lightGrey};
  width: 100%;
  margin-top:0.8%;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  
`

const Suggestion = styled.div`
  background: ${({ highlighted }) =>
    highlighted ? theme.colors.offWhite : theme.colors.white};
  padding: ${theme.spacing.x1} ${theme.spacing.x2}; 
  border-left: 2px solid
    ${({ highlighted }) =>
    highlighted ? theme.colors.primary : theme.colors.white};
`

const ShowIdDropdown = ({ setVenueId }) => {
  const instance = () => {
    const API_HOST_URL = process.env.REACT_APP_API_HOST_URL;
    const accessToken = localStorage.getItem('accessToken')

    const options = {
      baseURL: `${API_HOST_URL}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${accessToken}`
      }
    }
    const client = axios.create(options)
    return client
  }

  const [shows, setShows] = useState([])
  const [error, setError] = useState(false)

  const handleTextChange = inputValue => {
    instance().get(`/dashboard/risk/venues?venue_filter[name_contains]=${inputValue}&sort_field=source_id&sort_direction=asc`)
      .then((response) => {
        setShows(response.data.data.slice(0, 10))
      })
      .catch((error) => {
        setError(true)
      })
  }

  return (
    <>
      <Downshift
        onChange={(item, index) => { setVenueId(item.attributes.id) }}
        onInputValueChange={(inputValue) => handleTextChange(inputValue)}
        itemToString={(item) => item ? item.attributes.name : ''}
      >
        {({
          getInputProps,
          getItemProps,
          getLabelProps,
          isOpen, inputValue, highlightedIndex,
          selectedItem,
          toggleMenu,
          getRootProps,
          initialSelectedItem,
          clearSelection,
        }) => (
          <div {...getRootProps({}, { suppressRefError: true })}>
            <Relative>
              <Input
                type="text"
                onFocus={toggleMenu}
                placeholder="Select a Venue"
                {...getInputProps()}
                id="athleteSelector"
              />
              {isOpen && (
                <Suggestions>
                  {shows
                    .map((item, index) => (
                      <Suggestion
                        {...getItemProps({
                          key: index,
                          index,
                          item,
                          highlighted: highlightedIndex === index,
                          selected: selectedItem === item,
                        })}
                      >
                        <Spacer size={theme.spacing.x3} />
                        <Text
                          tag="h3"
                          size={[theme.fontScale.x4]}
                          weight={theme.fontWeights.bold}
                          lineHeight={theme.lineHeights.heading}
                        >
                          {item.attributes.name}
                        </Text>
                        <Spacer size={theme.spacing.x3} />
                      </Suggestion>
                    ))}
                </Suggestions>
              )}
            </Relative>
          </div>
        )}
      </Downshift>
    </>
  )
}

export default connect(
)(ShowIdDropdown)
