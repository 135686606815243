import {
  riskAnalysisByDate,
  riskAnalysisByClass,
  riskAnalysisByCategory,
} from "./../../services/riskAnalysis";

import { currentYear, xcpihOrXcpi } from "./../../utilities";

export const GET_XCPI_ANALYSIS_STARTED = "GET_XCPI_ANALYSIS_STARTED";
export const GET_XCPI_ANALYSIS_SUCCESS = "GET_XCPI_ANALYSIS_SUCCESS";
export const GET_XCPI_ANALYSIS_FAILURE = "GET_XCPI_ANALYSIS_FAILURE";

export const GET_ERQI_ANALYSIS_STARTED = "GET_ERQI_ANALYSIS_STARTED";
export const GET_ERQI_ANALYSIS_SUCCESS = "GET_ERQI_ANALYSIS_SUCCESS";
export const GET_ERQI_ANALYSIS_FAILURE = "GET_ERQI_ANALYSIS_FAILURE";

export const GET_XCPI_ANALYSIS_DATE_STARTED = "GET_XCPI_ANALYSIS_DATE_STARTED";
export const GET_XCPI_ANALYSIS_DATE_SUCCESS = "GET_XCPI_ANALYSIS_DATE_SUCCESS";
export const GET_XCPI_ANALYSIS_DATE_FAILURE = "GET_XCPI_ANALYSIS_DATE_FAILURE";

export const GET_ERQI_ANALYSIS_DATE_STARTED = "GET_ERQI_ANALYSIS_DATE_STARTED";
export const GET_ERQI_ANALYSIS_DATE_SUCCESS = "GET_ERQI_ANALYSIS_DATE_SUCCESS";
export const GET_ERQI_ANALYSIS_DATE_FAILURE = "GET_ERQI_ANALYSIS_DATE_FAILURE";

export const GET_XCPI_ANALYSIS_CLASS_STARTED =
  "GET_XCPI_ANALYSIS_CLASS_STARTED";
export const GET_XCPI_ANALYSIS_CLASS_SUCCESS =
  "GET_XCPI_ANALYSIS_CLASS_SUCCESS";
export const GET_XCPI_ANALYSIS_CLASS_FAILURE =
  "GET_XCPI_ANALYSIS_CLASS_FAILURE";

export const GET_ERQI_ANALYSIS_CLASS_STARTED =
  "GET_ERQI_ANALYSIS_CLASS_STARTED";
export const GET_ERQI_ANALYSIS_CLASS_SUCCESS =
  "GET_ERQI_ANALYSIS_CLASS_SUCCESS";
export const GET_ERQI_ANALYSIS_CLASS_FAILURE =
  "GET_ERQI_ANALYSIS_CLASS_FAILURE";

export const GET_XCPI_ANALYSIS_CATEGORY_STARTED =
  "GET_XCPI_ANALYSIS_CATEGORY_STARTED";
export const GET_XCPI_ANALYSIS_CATEGORY_SUCCESS =
  "GET_XCPI_ANALYSIS_CATEGORY_SUCCESS";
export const GET_XCPI_ANALYSIS_CATEGORY_FAILURE =
  "GET_XCPI_ANALYSIS_CATEGORY_FAILURE";

export const GET_ERQI_ANALYSIS_CATEGORY_STARTED =
  "GET_ERQI_ANALYSIS_CATEGORY_STARTED";
export const GET_ERQI_ANALYSIS_CATEGORY_SUCCESS =
  "GET_ERQI_ANALYSIS_CATEGORY_SUCCESS";
export const GET_ERQI_ANALYSIS_CATEGORY_FAILURE =
  "GET_ERQI_ANALYSIS_CATEGORY_FAILURE";

let provId = parseInt(localStorage.getItem("providerId"));

export const getXcpiAnalysisStarted = () => ({
  type: GET_XCPI_ANALYSIS_STARTED,
});

export const getXcpiAnalysisSuccess = (
  xcpiByDate,
  xcpiByClass,
  xcpiByCategory
) => ({
  type: GET_XCPI_ANALYSIS_SUCCESS,
  payload: {
    xcpiByDate,
    xcpiByClass,
    xcpiByCategory,
  },
});

export const getXcpiAnalysisFailure = () => ({
  type: GET_XCPI_ANALYSIS_FAILURE,
});

export const getErqiAnalysisStarted = () => ({
  type: GET_ERQI_ANALYSIS_STARTED,
});

export const getErqiAnalysisSuccess = (
  erqiByDate,
  erqiByClass,
  erqiByCategory
) => ({
  type: GET_ERQI_ANALYSIS_SUCCESS,
  payload: {
    erqiByDate,
    erqiByClass,
    erqiByCategory,
  },
});

export const getErqiAnalysisFailure = () => ({
  type: GET_ERQI_ANALYSIS_FAILURE,
});

export const getXcpiAnalysisDateStarted = () => ({
  type: GET_XCPI_ANALYSIS_DATE_STARTED,
});

export const getXcpiAnalysisDateSuccess = (xcpiByDate) => ({
  type: GET_XCPI_ANALYSIS_DATE_SUCCESS,
  payload: xcpiByDate,
});

export const getXcpiAnalysisDateFailure = () => ({
  type: GET_XCPI_ANALYSIS_DATE_FAILURE,
});

export const getErqiAnalysisDateStarted = () => ({
  type: GET_ERQI_ANALYSIS_DATE_STARTED,
});

export const getErqiAnalysisDateSuccess = (erqiByDate) => ({
  type: GET_ERQI_ANALYSIS_DATE_SUCCESS,
  payload: erqiByDate,
});

export const getErqiAnalysisDateFailure = () => ({
  type: GET_ERQI_ANALYSIS_DATE_FAILURE,
});

export const getXcpiAnalysisClassStarted = () => ({
  type: GET_XCPI_ANALYSIS_CLASS_STARTED,
});

export const getXcpiAnalysisClassSuccess = (xcpiByClass) => ({
  type: GET_XCPI_ANALYSIS_CLASS_SUCCESS,
  payload: xcpiByClass,
});

export const getXcpiAnalysisClassFailure = () => ({
  type: GET_XCPI_ANALYSIS_CLASS_FAILURE,
});

export const getErqiAnalysisClassStarted = () => ({
  type: GET_ERQI_ANALYSIS_CLASS_STARTED,
});

export const getErqiAnalysisClassSuccess = (erqiByClass) => ({
  type: GET_ERQI_ANALYSIS_CLASS_SUCCESS,
  payload: erqiByClass,
});

export const getErqiAnalysisClassFailure = () => ({
  type: GET_ERQI_ANALYSIS_CLASS_FAILURE,
});

export const getXcpiAnalysisCategoryStarted = () => ({
  type: GET_XCPI_ANALYSIS_CATEGORY_STARTED,
});

export const getXcpiAnalysisCategorySuccess = (xcpiByCategory) => ({
  type: GET_XCPI_ANALYSIS_CATEGORY_SUCCESS,
  payload: xcpiByCategory,
});

export const getXcpiAnalysisCategoryFailure = () => ({
  type: GET_XCPI_ANALYSIS_CATEGORY_FAILURE,
});

export const getErqiAnalysisCategoryStarted = () => ({
  type: GET_ERQI_ANALYSIS_CATEGORY_STARTED,
});

export const getErqiAnalysisCategorySuccess = (erqiByCategory) => ({
  type: GET_ERQI_ANALYSIS_CATEGORY_SUCCESS,
  payload: erqiByCategory,
});

export const getErqiAnalysisCategoryFailure = () => ({
  type: GET_ERQI_ANALYSIS_CATEGORY_FAILURE,
});

export const getXcpiAnalysis = (riskCat) => {
  return (dispatch) => {
    dispatch(getXcpiAnalysisStarted());
    Promise.all([
      riskAnalysisByDate(currentYear, xcpihOrXcpi(provId)),
      riskAnalysisByClass(xcpihOrXcpi(provId), []),
      riskAnalysisByCategory(xcpihOrXcpi(provId), riskCat),
    ])
      .then((values) => {
        const xcpiByDate = values[0].data.data;
        const xcpiByClass = values[1].data.data;
        const xcpiByCategory = values[2].data.data;
        dispatch(
          getXcpiAnalysisSuccess(xcpiByDate, xcpiByClass, xcpiByCategory)
        );
      })
      .catch(() => dispatch(getXcpiAnalysisFailure()));
  };
};

export const getErqiAnalysis = (riskCat) => {
  return (dispatch) => {
    dispatch(getErqiAnalysisStarted());
    Promise.all([
      riskAnalysisByDate(currentYear, "ews"),
      riskAnalysisByClass("ews", []),
      riskAnalysisByCategory("ews", riskCat),
    ])
      .then((values) => {
        const erqiByDate = values[0].data.data;
        const erqiByClass = values[1].data.data;
        const erqiByCategory = values[2].data.data;
        dispatch(
          getErqiAnalysisSuccess(erqiByDate, erqiByClass, erqiByCategory)
        );
      })
      .catch(() => dispatch(getErqiAnalysisFailure()));
  };
};

export const getXcpiAnalysisByDate = (date) => {
  return (dispatch) => {
    dispatch(getXcpiAnalysisDateStarted());
    riskAnalysisByDate(date, xcpihOrXcpi(provId))
      .then((response) => {
        const xcpiByDate = response.data.data;
        dispatch(getXcpiAnalysisDateSuccess(xcpiByDate));
      })
      .catch(() => {
        dispatch(getXcpiAnalysisDateFailure());
      });
  };
};

export const getErqiAnalysisByDate = (date) => {
  return (dispatch) => {
    dispatch(getErqiAnalysisDateStarted());
    riskAnalysisByDate(date, "ews")
      .then((response) => {
        const erqiByDate = response.data.data;
        dispatch(getErqiAnalysisDateSuccess(erqiByDate));
      })
      .catch(() => {
        dispatch(getErqiAnalysisDateFailure());
      });
  };
};

export const getXcpiAnalysisByClass = (erLevels) => {
  return (dispatch) => {
    dispatch(getXcpiAnalysisClassStarted());
    riskAnalysisByClass(xcpihOrXcpi(provId), erLevels)
      .then((response) => {
        const xcpiByClass = response.data.data;
        dispatch(getXcpiAnalysisClassSuccess(xcpiByClass));
      })
      .catch(() => {
        dispatch(getXcpiAnalysisClassFailure());
      });
  };
};

export const getErqiAnalysisByClass = (erLevels) => {
  return (dispatch) => {
    dispatch(getErqiAnalysisClassStarted());
    riskAnalysisByClass("ews", erLevels)
      .then((response) => {
        const erqiByClass = response.data.data;
        dispatch(getErqiAnalysisClassSuccess(erqiByClass));
      })
      .catch(() => {
        dispatch(getErqiAnalysisClassFailure());
      });
  };
};

export const getXcpiAnalysisByCategory = (category) => {
  return (dispatch) => {
    dispatch(getXcpiAnalysisCategoryStarted());
    riskAnalysisByCategory(xcpihOrXcpi(provId), category)
      .then((response) => {
        const xcpiByCategory = response.data.data;
        dispatch(getXcpiAnalysisCategorySuccess(xcpiByCategory));
      })
      .catch(() => {
        dispatch(getXcpiAnalysisCategoryFailure());
      });
  };
};

export const getErqiAnalysisByCategory = (category) => {
  return (dispatch) => {
    dispatch(getErqiAnalysisCategoryStarted());
    riskAnalysisByCategory("ews", category)
      .then((response) => {
        const erqiByCategory = response.data.data;
        dispatch(getErqiAnalysisCategorySuccess(erqiByCategory));
      })
      .catch(() => {
        dispatch(getErqiAnalysisCategoryFailure());
      });
  };
};
