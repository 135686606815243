import React, { useState, useEffect, useRef } from 'react'
import { store } from "../redux/store";
import styled from 'styled-components'
import { theme, Card, Dropdown, Text, Spacer, Table, Th, Tr, Td } from 'equiratings-components'
import { round } from '../helperFunctions'
import { currentYear, years } from "../utilities";

const TitleContainer = styled.div`
display: flex;
justify-content: space-between;
align-items: baseline;
`
const ClassItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
`

const Class = styled.div`
 display: flex;
 padding: ${theme.spacing.x2} ${theme.spacing.x3};
 align-items: center;
 margin-right: 1%;
`

const RiskOverviewByVenue = ({ erLevels, year, byVenue, getOverviewByVenue }) => {
    const initialRender = useRef(true);
    const providerId = store.getState().UserState.providerId;
    const [selectedLevels, selectLevel] = useState([])
    const [selectedYear, selectYear] = useState(currentYear);

    const removeClass = index => {
        const newArr = [...selectedLevels]
        newArr.splice(index, 1)
        selectLevel(newArr)
    }

    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false;
        } else {
            getOverviewByVenue(selectedLevels.map(lvl => lvl.erLevel), selectedYear)
        }
    }, [selectedLevels, selectedYear])

    const addNewClass = level => {
        const someArr = selectedLevels.map(lvl => lvl.erLevel)
        const isDuplicate = someArr.includes(level.attributes.er_level)
        if (!isDuplicate) {
            selectLevel([...selectedLevels, { erLevel: level.attributes.er_level, className: level.attributes.title }])
        }
    }

    return (
        <>
            <TitleContainer>
                <Text
                    tag="h1"
                    size={[theme.fontScale.x4]}
                    weight={theme.fontWeights.bold}
                    lineHeight={theme.lineHeights.heading}
                >
                    Risk Overview by Venue
                </Text>
                <Dropdown
                    onChange={(level) => addNewClass(level)}
                    readOnly={true}
                    items={erLevels}
                    itemToString={() => "Select a Class"}
                    initialItem={"Select a Level"}
                    handleFilter={() => () => true}
                    displaySuggestion={(level) => (
                        <Text
                            tag="h1"
                            size={[theme.fontScale.x4]}
                            weight={theme.fontWeights.normal}
                            lineHeight={theme.lineHeights.heading}
                        >
                            {level.attributes ? level.attributes.title : ''}
                        </Text>
                    )}
                />
                <Dropdown
                    onChange={(item) => selectYear(item)}
                    readOnly={true}
                    items={years(providerId)}
                    itemToString={(item) => item}
                    initialItem={selectedYear}
                    handleFilter={() => () => true}
                    displaySuggestion={(item) => (
                        <Text
                            tag="h1"
                            size={[theme.fontScale.x4]}
                            weight={theme.fontWeights.normal}
                            lineHeight={theme.lineHeights.heading}
                            cursor={"pointer"}
                        >
                            {item}
                        </Text>
                    )}
                />
            </TitleContainer>
            <Spacer size={theme.spacing.x3} />
            <Spacer size={theme.spacing.x2} />
            <ClassItems>

                <Text
                    tag="h1"
                    size={[theme.fontScale.x4]}
                    weight={theme.fontWeights.bold}
                    style={{
                        marginRight: '20px',
                    }}
                >
                    Selected Levels:
                </Text>
                {selectedLevels.length > 0 ? (
                    <>
                        {selectedLevels.map((level, index) => {
                            return (
                                <Class>
                                    <Text
                                        tag="h1"
                                        size={[theme.fontScale.x4]}
                                        weight={theme.fontWeights.normal}
                                        lineHeight={theme.lineHeights.heading}
                                        style={{
                                            marginRight: '20px'
                                        }}
                                    >
                                        {level.className}
                                    </Text>
                                    <Text
                                        tag="h1"
                                        size={[theme.fontScale.x4]}
                                        weight={theme.fontWeights.normal}
                                        lineHeight={theme.lineHeights.heading}
                                        color={'grey'}
                                        cursor={'pointer'}
                                        onClick={() => removeClass(index)}
                                    >
                                        ✕
                                    </Text>
                                </Class>
                            )
                        })}
                    </>
                ) : (
                    <Class>
                        <Text
                            tag="h1"
                            size={[theme.fontScale.x4]}
                            weight={theme.fontWeights.normal}
                            lineHeight={theme.lineHeights.heading}
                        >
                            All Levels
                        </Text>
                    </Class>
                )}

            </ClassItems>
            <Spacer size={theme.spacing.x3} />
            <Card>
                <Table>
                    <tbody>
                        <tr>
                            <Th>Venue</Th>
                            <Th>Starters</Th>
                            <Th>XC Runs</Th>
                            <Th>% Of all XC Runs</Th>
                            <Th>XCJ Clear Rates</Th>
                            <Th>XC Falls</Th>
                            <Th>XC Fall Rates</Th>
                            <Th>Rider Falls</Th>
                            <Th>Rider Fall Rates</Th>
                            <Th>Horse Falls</Th>
                            <Th>Horse Fall Rates</Th>
                        </tr>
                        {byVenue.map((item, index) => {
                            return (
                                <Tr key={index}>
                                    <Td>{item.attributes.group_label}</Td>
                                    <Td>{item.attributes.starters}</Td>
                                    <Td>{item.attributes.xc_runs}</Td>
                                    <Td>{round(item.attributes.percentage_of_all_xc_runs, 1, true)}</Td>
                                    <Td>{round(item.attributes.xcj_clear_rate, 1, true)}</Td>
                                    <Td>{item.attributes.all_falls}</Td>
                                    <Td>{round(item.attributes.all_fall_rate, 2, true)}</Td>
                                    <Td>{item.attributes.rider_falls}</Td>
                                    <Td>{round(item.attributes.rider_fall_rate, 2, true)}</Td>
                                    <Td>{item.attributes.horse_falls}</Td>
                                    <Td>{round(item.attributes.horse_fall_rate, 2, true)}</Td>
                                </Tr>
                            )
                        })}
                    </tbody>
                </Table>
            </Card>
        </>
    )
}
export default RiskOverviewByVenue
