export const SET_COMPETITION = "SET_COMPETITION"
export const SET_UPCOMING_COMPETITION = "SET_UPCOMING_COMPETITION"

export const setPostCompetition = (comp) => ({
  type: SET_COMPETITION,
  payload: comp 
})

export const setPreCompetition = (comp) => ({
  type: SET_UPCOMING_COMPETITION,
  payload: comp
})
