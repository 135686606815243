import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { theme, Spacer, Text, TextField } from "equiratings-components";
import { connect } from "react-redux";
import { navigate } from "@reach/router";
import { searchAthletes } from "../services/byAthlete";
import NavigationTab from "../components/NavigationTab";
import { setAthlete } from "../redux/AthleteMERS/AthleteMerActions";
import { checkUserLoggedIn } from "../helperFunctions.js";

const Horse = styled.div`
  background: ${theme.colors.white};
  border-radius: ${theme.spacing.x2};
  padding: ${theme.spacing.x3};
  border-left: 6px solid ${theme.colors.primary};
  display: flex;
  box-sizing: border-box;
  margin-bottom: ${theme.spacing.x2};
  flex-direction: column;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  cursor: pointer;
`;

const Horses = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media (${theme.breakpoints.notSmall}) {
    flex-direction: row;
  }
`;

const HorsesColumn = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  @media (${theme.breakpoints.notSmall}) {
    width: 49.5%;
  }
`;

const AthleteSearch = ({ setAthlete, chosenAthlete }) => {
  useEffect(() => {
    checkUserLoggedIn();
  }, []);

  const [firstName, setFirstName] = useState(null);
  const [surname, setSurname] = useState(null);
  const [athletes, setAthletesList] = useState([]);

  //   useEffect(() => {
  //     searchAthletes()
  //       .then((response) => setAthletesList(response.data.data))
  //       .catch((error) => console.log(error));
  //   }, []);

  useEffect(() => {
    searchAthletes(firstName, surname)
      .then((response) => setAthletesList(response.data.data))
      .catch((error) => console.log(error));
  }, [firstName, surname]);

  const handleAthleteSelection = async (athlete) => {
    await setAthlete(athlete);
    if (chosenAthlete) {
      navigate(`/athlete-mers/${athlete.id}`);
    }
  };

  const divideArr = () => {
    let arrLength = athletes.length;
    let divider = Math.ceil(arrLength / 2);
    let firstArray = athletes.slice(0, divider);
    let secondArray = athletes.slice(divider, arrLength);
    return {
      firstArray,
      secondArray,
    };
  };

  return (
    <>
      <NavigationTab />
      <Text
        tag="h1"
        size={[theme.fontScale.x5]}
        weight={theme.fontWeights.bold}
        lineHeight={theme.lineHeights.heading}
      >
        Choose an Athlete
      </Text>
      <Spacer size={theme.spacing.x3} />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <TextField
          placeholder="Athlete Firstname"
          style={{ background: theme.colors.white, width: "49%" }}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <TextField
          placeholder="Athlete Surname"
          style={{ background: theme.colors.white, width: "49%" }}
          onChange={(e) => setSurname(e.target.value)}
        />
      </div>
      <Spacer size={theme.spacing.x3} />
      <Horses>
        <HorsesColumn>
          {divideArr().firstArray.map((athlete) => {
            return (
              <Horse onClick={() => handleAthleteSelection(athlete.attributes)}>
                <Spacer size={theme.spacing.x3} />
                <Text
                  tag="h1"
                  size={[theme.fontScale.x5]}
                  weight={theme.fontWeights.bold}
                  lineHeight={theme.lineHeights.heading}
                  cursor="pointer"
                >
                  {athlete.attributes.first_name +
                    " " +
                    athlete.attributes.surname}
                </Text>
                <Spacer size={theme.spacing.x3} />
              </Horse>
            );
          })}
        </HorsesColumn>
        <HorsesColumn>
          {divideArr().secondArray.map((athlete) => {
            return (
              <Horse onClick={() => handleAthleteSelection(athlete.attributes)}>
                <Spacer size={theme.spacing.x3} />
                <Text
                  tag="h1"
                  size={[theme.fontScale.x5]}
                  weight={theme.fontWeights.bold}
                  lineHeight={theme.lineHeights.heading}
                  cursor="pointer"
                >
                  {athlete.attributes.first_name +
                    " " +
                    athlete.attributes.surname}
                </Text>
                <Spacer size={theme.spacing.x3} />
              </Horse>
            );
          })}
        </HorsesColumn>
      </Horses>
    </>
  );
};

export default connect(
  ({ AthleteMerState }) => ({ chosenAthlete: AthleteMerState.selectedAthlete }),
  { setAthlete }
)(AthleteSearch);
