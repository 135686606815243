import axios from "axios";
import { format, differenceInCalendarDays } from "date-fns";

const getAccessToken = () => {
  const tokenIsValid =
    format(
      differenceInCalendarDays(Date.now(), localStorage.getItem("timeOfLogin")),
      "DD"
    ) < 3;
  if (!tokenIsValid) {
    axios
      .post(`${process.env.REACT_APP_API_HOST_URL}/tokens`, {
        refresh_token: localStorage.getItem("refreshToken"),
      })
      .then((response) => {
        localStorage.setItem(
          "accessToken",
          response.data.data.attributes.access_token
        );
        localStorage.setItem(
          "refreshToken",
          response.data.data.attributes.refresh_token
        );
      });
  }
  return localStorage.getItem("accessToken");
};

const instance = () => {
  const API_HOST_URL = process.env.REACT_APP_API_HOST_URL;

  const options = {
    baseURL: `${API_HOST_URL}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };
  const client = axios.create(options);
  return client;
};

const todaysDate = format(Date.now(), "YYYY-MM-DD");

export const riskAnalysisByDate = (year, groupBy) => {
  return instance().get(`/dashboard/risk/dashboard_risk_metrics`, {
    params: {
      date_from: `${year}-01-01`,
      date_to: `${year}-12-31`,
      group_by: groupBy,
    },
  });
};

export const riskAnalysisByClass = (groupBy, erLevels = []) => {
  return instance().get(`/dashboard/risk/dashboard_risk_metrics`, {
    params: {
      date_from: `2020-01-01`,
      date_to: todaysDate,
      group_by: groupBy,
      er_levels: erLevels,
    },
  });
};

export const riskAnalysisByCategory = (riskValue, riskGrade) => {
  if (riskValue === "xcpih") {
    return instance().get("/dashboard/risk/dashboard_risk_metrics", {
      params: {
        date_from: `2020-01-01`,
        date_to: todaysDate,
        group_by: "er_level",
        xcpih_grade: riskGrade,
      },
    });
  } else if (riskValue === "xcpi") {
    return instance().get("/dashboard/risk/dashboard_risk_metrics", {
      params: {
        date_from: `2020-01-01`,
        date_to: todaysDate,
        group_by: "er_level",
        xcpi_grade: riskGrade,
      },
    });
  } else {
    return instance().get("/dashboard/risk/dashboard_risk_metrics", {
      params: {
        date_from: `2020-01-01`,
        date_to: todaysDate,
        group_by: "er_level",
        ews_grade: riskGrade,
      },
    });
  }
};
