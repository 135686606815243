import React from 'react'
import styled from 'styled-components'
import { theme, Text } from 'equiratings-components'

const ExplainerContainer = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
`

const Explainer = ({ text }) => {
  return (
    <ExplainerContainer>
      <Text
        tag="h1"
        size={[theme.fontScale.x4]}
        weight={theme.fontWeights.normal}
        lineHeight={theme.lineHeights.heading}
      >
        {text}
      </Text>
    </ExplainerContainer>
  )
}

export default Explainer
