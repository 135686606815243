import React from "react";
import { theme, Spacer, } from "equiratings-components";
import { connect } from "react-redux";
import AnalysisByDate from "../components/AnalysisByDate.js";
import AnalysisByLevel from "../components/AnalysisByLevel.js";
import AnalysisByCategory from "../components/AnalysisByCategory.js";
import XCPIBreakdown from "../components/XCPIBreakdown";
import Explainer from "../components/Explainer";

import {
  getXcpiAnalysisByDate,
  getXcpiAnalysisByClass,
  getXcpiAnalysisByCategory,
} from "../redux/RiskAnalysis/RiskAnalysisActions";

const XCPIAnalysis = ({
  erLevels,
  xcpiByDate,
  xcpiByClass,
  xcpiByCategory,
  getXcpiAnalysisByDate,
  getXcpiAnalysisByClass,
  getXcpiAnalysisByCategory,
  showErqi2 = false,
}) => {
  const showErqi2Title = showErqi2 ? "ERQI 2.0" : "HFI"

  return (
    <>
      <Spacer size={theme.spacing.x5} />
      <Explainer
        text={`The following tables give analysis on how your risk in your
          federation is performing within the context of ${showErqi2Title}. You
          can filter for a given year, accross a set of classes, or for a
          specific ${showErqi2Title} cateogry. ${showErqi2Title} is divided up
          into eight categories:`}
      />
      <Spacer size={theme.spacing.x5} />
      <XCPIBreakdown showErqi2 />
      <Spacer size={theme.spacing.x4} />
      <AnalysisByDate
        rows={xcpiByDate}
        onChange={(year) => getXcpiAnalysisByDate(year)}
      />
      <Spacer size={theme.spacing.x4} />
      <AnalysisByLevel
        rows={xcpiByClass}
        levels={erLevels}
        onChange={(erLevels) => getXcpiAnalysisByClass(erLevels)}
        riskMetric={"HFI"}
      />
      <Spacer size={theme.spacing.x4} />
      <AnalysisByCategory
        riskMetric={"HFI"}
        rows={xcpiByCategory}
        onChange={(category) => getXcpiAnalysisByCategory(category)}
        dropdownItems={["A++", "A+", "A", "B", "C", "D", "E"]}
        showErqi2
      />
    </>
  );
};

export default connect(
  ({ RiskOverviewState, RiskAnalysisState }) => ({
    erLevels: RiskOverviewState.erLevels,
    xcpiByDate: RiskAnalysisState.xcpiByDate,
    xcpiByClass: RiskAnalysisState.xcpiByClass,
    xcpiByCategory: RiskAnalysisState.xcpiByCategory,
  }),
  { getXcpiAnalysisByDate, getXcpiAnalysisByClass, getXcpiAnalysisByCategory }
)(XCPIAnalysis);
