import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  theme,
  Text,
  Spacer,
  Card,
  TextField,
  Table,
  Th,
  Td,
  Tr,
} from "equiratings-components";

import { connect } from "react-redux";
import "react-dates/lib/css/_datepicker.css";
import "../lib/DatePickerOveride.css";
import NavigationTab from "../components/NavigationTab";
import LoadingSpinner from "../components/LoadingSpinner";
import { checkUserLoggedIn } from "../helperFunctions.js";
import { getAthleteMers } from "../services/byAthlete";
import { getHorseXcpihs } from "../services/byHorse";

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const CardWithBorder = styled.div`
  border: 2px solid rgba(26, 56, 82, 0.5);
  background: ${theme.colors.white};
  width: 100%;
  display: flex;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: ${theme.spacing.x2};
  overflow-x: auto;
`;

const LoadingContainer = styled.div`
  width: 100%;
  padding-top: 15%;
  margin: 0 auto;
`;

const NoData = styled.div`
  background: ${theme.colors.white};
  width: 100%;
  display: flex;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: ${theme.spacing.x2}
  padding: ${({ padding }) => (padding ? padding : theme.spacing.x6)};
  overflow-x: auto;
`;

const AthleteMers = ({ erLevels, selectedAthlete, providerId }) => {
  const [selectedHorseName, setSelectedHorseName] = useState("");
  const [horseName, setHorseName] = useState("");
  const [mers, setMers] = useState([]);
  const [erqis, setErqis] = useState(undefined);

  const mapErqiToClass = (arr, func) => {
    console.log("ERQI TO CLASS", arr);
    let array = [];
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].attributes.erqi !== null) {
        for (let k = 0; k < erLevels.length; k++) {
          if (
            arr[i].attributes.height_level === erLevels[k].attributes.er_level
          ) {
            array.push({
              title: arr[i].attributes.height_level_title,
              value: parseFloat(arr[i].attributes.xcpih),
              erLevel: arr[i].attributes.height_level,
            });
          }
        }
      }
    }
    func(array);
  };

  useEffect(() => {
    checkUserLoggedIn();
  }, []);

  const dataIsPresent = mers;

  useEffect(() => {
    getAthleteMers(selectedAthlete.id)
      .then((response) => {
        console.log(response);
        setMers(response.data.data);
      })
      .catch((error) => console.log(error));
  }, []);

  const getErqis = (selectedHorse) => {
    getHorseXcpihs(selectedHorse)
      .then((resp) => {
        console.log(resp.data.data);
        mapErqiToClass(resp.data.data, setErqis);
      })
      .catch((err) => console.log(err));
  };

  const merChecker = (mer) => {
    if (mer === null) {
      return "-";
    } else if (mer === false) {
      return "No";
    } else {
      return "Yes";
    }
  };
  const merColor = (mer) => {
    if (mer === null) {
      return "rgb(255, 215, 148)";
    } else if (mer === false) {
      return "rgb(239, 124, 124)";
    } else {
      return "rgba(97, 202, 49)";
    }
  };

  return dataIsPresent ? (
    <>
      <NavigationTab />
      <Spacer size={theme.spacing.x4} />
      <Container>
        <Text
          tag="h1"
          size={[theme.fontScale.x5]}
          weight={theme.fontWeights.bold}
          lineHeight={theme.lineHeights.heading}
        >
          {selectedAthlete.first_name + " " + selectedAthlete.surname + " MERs"}
        </Text>
        <TextField
          placeholder="Horse Name"
          style={{
            background: theme.colors.white,
            width: "49%",
            alignSelf: "flex-end",
          }}
          onChange={(e) => setHorseName(e.target.value)}
        />
      </Container>
      <Spacer size={theme.spacing.x4} />
      <Container>
        {mers.length > 0 ? (
          <Card>
            <Table>
              <tbody>
                <tr>
                  <Th>Horse</Th>
                  <Th>Date of latest MER</Th>
                  <Th>CCI4*-L</Th>
                  <Th>CCI4*-S</Th>
                  <Th>CCI3*-L</Th>
                  <Th>CCI3*-S</Th>
                  <Th>CCI2*-L</Th>
                  <Th>CCI2*-S</Th>
                  <Th>CCI1*-U</Th>
                </tr>
                {mers
                  .filter((item) =>
                    item.attributes.horse_display_name.includes(horseName)
                  )
                  .map((item, index) => {
                    return (
                      <Tr
                        key={index}
                        style={{ borderTop: "none", cursor: "pointer" }}
                        onClick={() => {
                          getErqis(item.attributes.horse_id);
                          setSelectedHorseName(
                            item.attributes.horse_display_name
                          );
                        }}
                      >
                        <Td>{item.attributes.horse_display_name}</Td>
                        <Td>{item.attributes.latest_mer_date}</Td>
                        <Td
                          style={{
                            background: merColor(
                              item.attributes.level_qualifications[12]
                            ),
                          }}
                        >
                          {merChecker(item.attributes.level_qualifications[12])}
                        </Td>
                        <Td
                          style={{
                            background: merColor(
                              item.attributes.level_qualifications[11]
                            ),
                          }}
                        >
                          {merChecker(item.attributes.level_qualifications[11])}
                        </Td>
                        <Td
                          style={{
                            background: merColor(
                              item.attributes.level_qualifications[9]
                            ),
                          }}
                        >
                          {merChecker(item.attributes.level_qualifications[9])}
                        </Td>
                        <Td
                          style={{
                            background: merColor(
                              item.attributes.level_qualifications[8]
                            ),
                          }}
                        >
                          {merChecker(item.attributes.level_qualifications[8])}
                        </Td>
                        <Td
                          style={{
                            background: merColor(
                              item.attributes.level_qualifications[6]
                            ),
                          }}
                        >
                          {merChecker(item.attributes.level_qualifications[6])}
                        </Td>
                        <Td
                          style={{
                            background: merColor(
                              item.attributes.level_qualifications[5]
                            ),
                          }}
                        >
                          {merChecker(item.attributes.level_qualifications[5])}
                        </Td>
                        <Td
                          style={{
                            background: merColor(
                              item.attributes.level_qualifications[3]
                            ),
                          }}
                        >
                          {merChecker(item.attributes.level_qualifications[3])}
                        </Td>
                      </Tr>
                    );
                  })}
              </tbody>
            </Table>
          </Card>
        ) : (
          <>
            <NoData>
              <Text
                tag="h1"
                size={[theme.fontScale.x4]}
                weight={theme.fontWeights.normal}
                lineHeight={theme.lineHeights.heading}
                style={{
                  color: "#808080",
                  margin: "0 auto",
                }}
              >
                Oops! It looks like there were no results returned.
              </Text>
            </NoData>
          </>
        )}
        {erqis ? (
          <div
            style={{
              margin: "auto",
              position: "absolute",
              width: "90%",
              top: "20%",
              left: 0,
              bottom: 0,
              right: 0,
            }}
          >
            <CardWithBorder>
              <div style={{ padding: "2%", width: "100%" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Text
                    width="20%"
                    tag="h1"
                    size={[theme.fontScale.x4]}
                    weight={theme.fontWeights.bold}
                    lineHeight={theme.lineHeights.heading}
                  >
                    {selectedHorseName} ERQI 2.0
                  </Text>
                  <Text
                    onClick={() => setErqis(undefined)}
                    width="20%"
                    tag="h1"
                    cursor="pointer"
                    size={[theme.fontScale.x4]}
                    weight={theme.fontWeights.bold}
                    lineHeight={theme.lineHeights.heading}
                  >
                    X
                  </Text>
                </div>
                <Spacer size={theme.spacing.x3} />
                <Table>
                  <tbody>
                    <tr>
                      <Th>Level</Th>
                      <Th>ERQI 2.0</Th>
                    </tr>
                    {erqis.map((item, index) => {
                      return (
                        <Tr key={index}>
                          <Td>{item.title}</Td>
                          <Td>{item.value.toFixed(2)}</Td>
                        </Tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </CardWithBorder>
          </div>
        ) : null}
      </Container>
    </>
  ) : (
    <LoadingContainer>
      <LoadingSpinner />
    </LoadingContainer>
  );
};

export default connect(
  ({ RiskOverviewState, AthleteMerState, UserState }) => ({
    erLevels: RiskOverviewState.erLevels,
    selectedAthlete: AthleteMerState.selectedAthlete,
    providerId: UserState.providerId,
  }),
  {}
)(AthleteMers);
