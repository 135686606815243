import React, { useState } from "react";
import { store } from "../redux/store";
import styled from "styled-components";
import {
  Text,
  Card,
  Dropdown,
  Spacer,
  Table,
  Th,
  Tr,
  Td,
  theme,
} from "equiratings-components";

import { round } from "../helperFunctions";
import { xcpihOrXcpi } from "./../utilities";

const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: baseline;
`;

const AnalysisByCategory = ({
  rows,
  onChange,
  dropdownItems,
  riskMetric,
  showErqi2,
}) => {
  const [category, setCategory] = useState();
  const handleChange = (category) => {
    setCategory(category);
    onChange(category);
  };

  const setColor = (selectedCat) => {
    switch (category) {
      case "Green":
        return "rgba(166, 235, 133, 0.65)";
      case "Yellow":
        return "rgba(255, 188, 73, 0.6)";
      case "Amber":
        return "rgba(255, 188, 73, 0.9)";
      case "Red":
        return "rgba(236, 92, 92, 0.85)";
      case "A++":
        return "rgba(97, 202, 49, 0.8)";
      case "A+":
        return "rgba(97, 202, 49, 0.65)";
      case "A":
        return "rgba(97, 202, 49, 0.5)";
      case "B":
        return "rgba(97, 202, 49, 0.3)";
      case "C":
        return "rgba(97, 202, 49, 0.15)";
      case "D":
        return "rgba(255, 188, 71, 0.8)";
      case "E":
        return "rgba(239, 124, 124, 1)";
      default:
        return "rgba(166, 235, 133, 0.65)";
    }
  };

  return (
    <>
      <TitleContainer>
        <Text
          tag="h1"
          size={[theme.fontScale.x4]}
          weight={theme.fontWeights.bold}
          lineHeight={theme.lineHeights.heading}
        >
          {`By ${
            xcpihOrXcpi(
              store.getState().UserState.providerId,
              "ERQI 2.0",
              "HFI"
            )
          } Category - Since 2020`}
        </Text>
        <Dropdown
          onChange={(category) => handleChange(category)}
          readOnly={true}
          items={dropdownItems}
          itemToString={(category) => category}
          initialItem={riskMetric === "HFI" ? ["A++"] : ["Green"]}
          handleFilter={() => () => true}
          displaySuggestion={(item) => (
            <Text
              tag="h1"
              size={[theme.fontScale.x4]}
              weight={theme.fontWeights.normal}
              lineHeight={theme.lineHeights.heading}
            >
              {item}
            </Text>
          )}
        />
      </TitleContainer>
      <Spacer size={theme.spacing.x3} />
      <Card>
        <Table
          style={{
            borderLeft: `8px solid ${setColor()}`,
          }}
        >
          <tbody>
            <tr>
              <Th>Class</Th>
              <Th>Starters</Th>
              <Th>XC Runs</Th>
              <Th>% Of XC Runs</Th>
              <Th>XCJ Clear Rate</Th>
              <Th>XC Falls</Th>
              <Th>XC Fall Rates</Th>
              <Th>Rider Falls</Th>
              <Th>Rider Fall Rate</Th>
              <Th>Horse Falls</Th>
              <Th>Horse Fall Rate</Th>
            </tr>
            {rows.map((row, index) => {
              return (
                <Tr key={index}>
                  <Td style={{ width: "9%" }}>{row.attributes.group_label}</Td>
                  <Td>{row.attributes.starters}</Td>
                  <Td>{row.attributes.xc_runs}</Td>
                  <Td>
                    {round(row.attributes.percentage_of_all_xc_runs, 1, true)}
                  </Td>
                  <Td>{round(row.attributes.xcj_clear_rate, 1, true)}</Td>
                  <Td>{row.attributes.all_falls}</Td>
                  <Td>{round(row.attributes.all_fall_rate, 2, true)}</Td>
                  <Td>{row.attributes.rider_falls}</Td>
                  <Td>{round(row.attributes.rider_fall_rate, 2, true)}</Td>
                  <Td>{row.attributes.horse_falls}</Td>
                  <Td>{round(row.attributes.horse_fall_rate, 2, true)}</Td>{" "}
                </Tr>
              );
            })}
          </tbody>
        </Table>
      </Card>
    </>
  );
};

export default AnalysisByCategory;
