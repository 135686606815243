import React from "react";
import { store } from "../redux/store";
import { theme } from "equiratings-components";
import { round } from "../helperFunctions";
import { xcpihOrXcpi, hexColourFromRgba, hexOpacityFromRgba } from "../utilities";

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  body: {
    padding: 40,
    backgroundColor: "#fafafa",
    color: "#1a3852",
  },
  titleContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 10,
    marginBottom: 15,
    borderBottomWidth: 3,
    borderBottomColor: "#FFBC49",
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    backgroundColor: "white",
    borderRadius: 3,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCell: {
    borderStyle: "solid",
    borderWidth: 1,
  },
  cellText: {
    margin: "auto",
    marginTop: 5,
    marginBottom: 5,
    fontSize: 10,
    opacity: 1,
  },
  dataText: {
    margin: "auto",
    marginTop: 5,
    marginBottom: 5,
    fontSize: 9,
    opacity: 1,
  },
});
export const getProviderImage = () => {
  const provId = store.getState().UserState.providerId;
  switch (provId) {
    case 4:
      const fei = require("../assets/FEI logo copy.png");
      return fei;
    case 7:
      const ea = require("../assets/Equestrian-Australia.png");
      return ea;
    case 10:
      const be = require("../assets/British_Eventing.jpg");
      return be;
    case 11:
      const usea = require("../assets/USEA-LOGO.jpg");
      return usea;
    default:
      const def = require("../assets/eiLogo.png");
      return def;
  }
};

const PreCompPDF = ({ watchlists, grades, competitionName, competitionDate }) => {
  const xcpihOrXcpiTitle = xcpihOrXcpi(
    store.getState().UserState.providerId,
    "ERQI 2.0",
    "HFI"
  )

  return store.getState().UserState.providerId === 4 ? (
    <Document>
      <Page style={styles.body}>
        <View style={styles.titleContainer}>
          <Text
            style={{
              fontSize: 20,
            }}
          >
            EquiRatings / FEI Risk Management
          </Text>
          <Image
            style={{ height: 35, width: 80 }}
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/51/FEI_Logo_RGB_Purple.svg/1200px-FEI_Logo_RGB_Purple.svg.png"
          />
        </View>
        <Text
          style={{
            fontSize: 15,
            paddingBottom: 3,
          }}
        >
          {`${competitionName} Pre Show Watchlist Report`}
        </Text>
        <Text
          style={{
            fontSize: 10,
            paddingBottom: 3,
          }}
        >
          {competitionDate}
        </Text>

        <View style={{ marginTop: 10, marginBottom: 10 }} />
        <Text
          style={{
            fontSize: 10,
            paddingBottom: 3,
            fontWeight: "bold",
          }}
        >
          What is {xcpihOrXcpiTitle}?
        </Text>
        <Text
          style={{
            fontSize: 8,
            paddingBottom: 8,
            fontWeight: 700,
          }}
        >
          {xcpihOrXcpiTitle} is a rating which tracks the most recent
          cross-country results of a horse. It looks at the last 8 runs at four
          star or five star level.
          The {xcpihOrXcpiTitle} value goes up and down based on whether the
          horse had a clear, single fault or other (other including multiple
          faults, rider falls, horse falls). Horses appearing at international
          competition with a {xcpihOrXcpiTitle} rating below 0 are performing
          within the bottom 3% of performance (2018/2019 data).
        </Text>
        <Text
          style={{
            fontSize: 10,
            paddingBottom: 3,
            fontWeight: 700,
          }}
        >
          Why are these horses on the list?
        </Text>
        <Text
          style={{
            fontSize: 8,
            paddingBottom: 3,
            fontWeight: 700,
          }}
        >
          A horse can get a low {xcpihOrXcpiTitle} rating in two ways:
        </Text>
        <Text
          style={{
            fontSize: 8,
            paddingBottom: 3,
            paddingLeft: 10,
            fontWeight: 700,
          }}
        >
          1. When stepping up a level before showing consistent form at the
          levels below.
        </Text>
        <Text
          style={{
            fontSize: 8,
            paddingBottom: 8,
            paddingLeft: 10,
            fontWeight: 700,
          }}
        >
          2. When showing continued faults at this level.
        </Text>
        <Text
          style={{
            fontSize: 7,
            fontWeight: 700,
            paddingBottom: 4,
          }}
        >
          For international competitions, {xcpihOrXcpiTitle} ratings are
          generated using FEI data only. National performance is not considered.
        </Text>
        <Text
          style={{
            fontSize: 7,
            paddingBottom: 4,
            fontWeight: 700,
          }}
        >
          It should be emphasised that the {xcpihOrXcpiTitle} tool is in a
          testing phase and we continue to work on these data-informed indicators.
          Therefore, the information is shared in a confidential manner to
          assist you in your roles as TD and Ground Jury.
        </Text>
        <Text
          style={{
            fontSize: 7,
            fontWeight: 700,
            paddingBottom: 10,
          }}
        >
          The tool has been developed to help assess the risk of falls in the
          Cross Country phase and should not replace Athletes, NFs and Officials
          responsibilities as well as horse welfare.
        </Text>

        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View
              style={
                (styles.tableCell,
                  { width: "11.1%", borderRightWidth: 1, borderBottomWidth: 1 })
              }
            >
              <Text style={styles.cellText}>
                {xcpihOrXcpiTitle} Title
              </Text>
            </View>
            {grades.map(
              (item) => {
                return <View
                  style={
                    (styles.tableCell,
                    {
                      width: "11.1%",
                      borderRightWidth: 1,
                      borderBottomWidth: 1,
                    })
                  }
                >
                  <View
                    style={
                      (styles.tableCell,
                      {
                        flex: 1,
                        backgroundColor: hexColourFromRgba(item.rgba),
                        opacity: hexOpacityFromRgba(item.rgba),
                      })
                    }
                  >
                    <Text style={styles.cellText}>{item.title}</Text>
                  </View>
                </View>
              }
            )}
            <View
              style={
                (styles.tableCell,
                {
                  width: "11.1%",
                  borderRightWidth: 1,
                  borderBottomWidth: 1,
                  backgroundColor: theme.colors.lightGrey,
                })
              }
            >
              <Text style={styles.cellText}>N/A</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View
              style={
                (styles.tableCell,
                  { width: "11.1%", borderRightWidth: 1, borderBottomWidth: 1 })
              }
            >
              <Text style={styles.cellText}>
                {xcpihOrXcpiTitle} Range
              </Text>
            </View>
            {grades.map(
              (item) => {
                return <View
                  style={
                    (styles.tableCell,
                      { width: "11.1%", borderRightWidth: 1, borderBottomWidth: 1 })
                  }
                >
                  <Text style={styles.cellText}>{item.xcpi_range}</Text>
                </View>
              }
            )}
            <View
              style={
                (styles.tableCell,
                  { width: "11.1%", borderRightWidth: 1, borderBottomWidth: 1 })
              }
            >
              <Text style={styles.cellText}>No Data</Text>
            </View>
          </View>
        </View>

        <View style={{ marginTop: 5, marginBottom: 5 }} />
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View
              style={
                (styles.tableCell,
                  { width: "10%", borderRightWidth: 1, borderBottomWidth: 1 })
              }
            >
              <Text style={styles.cellText}>Class</Text>
            </View>
            <View
              style={
                (styles.tableCell,
                  { width: "25%", borderRightWidth: 1, borderBottomWidth: 1 })
              }
            >
              <Text style={styles.cellText}>Horse</Text>
            </View>
            <View
              style={
                (styles.tableCell,
                  { width: "25%", borderRightWidth: 1, borderBottomWidth: 1 })
              }
            >
              <Text style={styles.cellText}>Athlete</Text>
            </View>
            <View
              style={
                (styles.tableCell,
                  { width: "5%", borderRightWidth: 1, borderBottomWidth: 1 })
              }
            >
              <Text style={styles.cellText}>NF</Text>
            </View>
            <View
              style={
                (styles.tableCell,
                  { width: "10%", borderRightWidth: 1, borderBottomWidth: 1 })
              }
            >
              <Text style={styles.cellText}>Category</Text>
            </View>
            <View
              style={
                (styles.tableCell,
                  { width: "15%", borderRightWidth: 1, borderBottomWidth: 1 })
              }
            >
              <Text style={styles.cellText}>% of Clears</Text>
              <Text
                style={
                  (styles.cellText,
                    { fontSize: 9, textAlign: "center", marginBottom: 5 })
                }
              >
                (last 10 Runs)
              </Text>
            </View>
            <View
              style={
                (styles.tableCell,
                  { width: "10%", borderRightWidth: 1, borderBottomWidth: 1 })
              }
            >
              <Text style={styles.cellText}>
                {xcpihOrXcpiTitle}
              </Text>
            </View>
          </View>
          {watchlists
            ? watchlists.map((item, index) => {
              return (
                <View style={styles.tableRow}>
                  <View
                    style={
                      (styles.tableCell,
                      {
                        width: "10%",
                        borderRightWidth: 1,
                        borderBottomWidth: 1,
                      })
                    }
                  >
                    <Text style={styles.dataText}>
                      {item.attributes.competition.level}
                    </Text>
                  </View>
                  <View
                    style={
                      (styles.tableCell,
                      {
                        width: "25%",
                        borderRightWidth: 1,
                        borderBottomWidth: 1,
                      })
                    }
                  >
                    <Text style={styles.dataText}>
                      {item.attributes.horse.name}
                    </Text>
                  </View>
                  <View
                    style={
                      (styles.tableCell,
                      {
                        width: "25%",
                        borderRightWidth: 1,
                        borderBottomWidth: 1,
                      })
                    }
                  >
                    <Text style={styles.dataText}>
                      {item.attributes.athlete.name}
                    </Text>
                  </View>
                  <View
                    style={
                      (styles.tableCell,
                      {
                        width: "5%",
                        borderRightWidth: 1,
                        borderBottomWidth: 1,
                      })
                    }
                  >
                    <Text style={styles.dataText}>
                      {item.attributes.athlete.nf}
                    </Text>
                  </View>
                  <View
                    style={
                      (styles.tableCell,
                      {
                        width: "10%",
                        borderRightWidth: 1,
                        borderBottomWidth: 1,
                      })
                    }
                  >
                    <Text style={styles.dataText}>
                      {item.attributes.athlete.category}
                    </Text>
                  </View>
                  <View
                    style={
                      (styles.tableCell,
                      {
                        width: "15%",
                        borderRightWidth: 1,
                        borderBottomWidth: 1,
                      })
                    }
                  >
                    <Text style={styles.dataText}>
                      {round(item.attributes.xcj.xcj, 1, true)}
                    </Text>
                  </View>
                  <View
                    style={
                      (styles.tableCell,
                      {
                        width: "10%",
                        borderRightWidth: 1,
                        borderBottomWidth: 1,
                      })
                    }
                  >
                    <View
                      style={
                        (styles.tableCell,
                        {
                          flex: 1,
                          backgroundColor: hexColourFromRgba(item.attributes.xcpi_grade.rgba),
                          opacity: hexOpacityFromRgba(item.attributes.xcpi_grade.rgba),
                        })
                      }
                    >
                      <Text style={styles.dataText}>
                        {round(item.attributes.xcpi_grade.xcpi, 2) || "-"} (
                        {item.attributes.xcpi_grade.title || "-"})
                      </Text>
                    </View>
                  </View>
                </View>
              );
            })
            : null}
        </View>
      </Page>
    </Document>
  ) : (
    <Document>
      <Page style={styles.body}>
        <View style={styles.titleContainer}>
          <Text
            style={{
              fontSize: 20,
            }}
          >
            EquiRatings Risk Management
          </Text>
          <Image style={{ height: 35, width: 80 }} src={getProviderImage()} />
        </View>
        <Text
          style={{
            fontSize: 15,
            paddingBottom: 3,
          }}
        >
          {`${competitionName} Post Show Watchlist Report`}
        </Text>
        <Text
          style={{
            fontSize: 10,
            paddingBottom: 3,
          }}
        >
          {competitionDate}
        </Text>
        <View style={{ marginTop: 5, marginBottom: 5 }} />
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View
              style={
                (styles.tableCell,
                  { width: "10%", borderRightWidth: 1, borderBottomWidth: 1 })
              }
            >
              <Text style={styles.cellText}>Class</Text>
            </View>
            <View
              style={
                (styles.tableCell,
                  { width: "15%", borderRightWidth: 1, borderBottomWidth: 1 })
              }
            >
              <Text style={styles.cellText}>Horse</Text>
            </View>
            <View
              style={
                (styles.tableCell,
                  { width: "20%", borderRightWidth: 1, borderBottomWidth: 1 })
              }
            >
              <Text style={styles.cellText}>Athlete</Text>
            </View>
            <View
              style={
                (styles.tableCell,
                {
                  width: "15%",
                  borderRightWidth: 1,
                  borderBottomWidth: 1,
                })
              }
            >
              <Text style={styles.cellText}>
                "% of Clears"
              </Text>
              <Text
                style={
                  (styles.cellText,
                    { fontSize: 9, textAlign: "center", marginBottom: 5 })
                }
              >
                (last 10 Runs)
              </Text>
            </View>
            <View
              style={
                (styles.tableCell,
                {
                  width: "10%",
                  borderRightWidth: 1,
                  borderBottomWidth: 1,
                })
              }
            >
              <Text style={styles.cellText}>
                ERQI
              </Text>
            </View>
            <View
              style={
                (styles.tableCell,
                  { width: "10%", borderRightWidth: 1, borderBottomWidth: 1 })
              }
            >
              <Text style={styles.cellText}>
                {xcpihOrXcpiTitle}
              </Text>
            </View>
            <View
              style={
                (styles.tableCell,
                {
                  width: "10%",
                  borderRightWidth: 1,
                  borderBottomWidth: 1,
                })
              }
            >
              <Text style={styles.cellText}>XC Status</Text>
            </View>
            <View
              style={
                (styles.tableCell,
                  { width: "10%", borderRightWidth: 1, borderBottomWidth: 1 })
              }
            >
              <Text style={styles.cellText}>XC Jump</Text>
            </View>
          </View>
          {watchlists
            ? watchlists.map((item, index) => {
              return (
                <View style={styles.tableRow}>
                  <View
                    style={
                      (styles.tableCell,
                      {
                        width: "10%",
                        borderRightWidth: 1,
                        borderBottomWidth: 1,
                      })
                    }
                  >
                    <Text style={styles.dataText}>
                      {item.attributes.competition.level}
                    </Text>
                  </View>
                  <View
                    style={
                      (styles.tableCell,
                      {
                        width: "15%",
                        borderRightWidth: 1,
                        borderBottomWidth: 1,
                      })
                    }
                  >
                    <Text style={styles.dataText}>
                      {item.attributes.horse.name}
                    </Text>
                  </View>
                  <View
                    style={
                      (styles.tableCell,
                      {
                        width: "20%",
                        borderRightWidth: 1,
                        borderBottomWidth: 1,
                      })
                    }
                  >
                    <Text style={styles.dataText}>
                      {item.attributes.athlete.name}
                    </Text>
                  </View>
                  <View
                    style={
                      (styles.tableCell,
                      {
                        width: "15%",
                        borderRightWidth: 1,
                        borderBottomWidth: 1,
                      })
                    }
                  >
                    <Text style={styles.dataText}>
                      {round(item.attributes.xcj.xcj, 1, true)}
                    </Text>
                  </View>

                  <View
                    style={
                      (styles.tableCell,
                      {
                        width: "10%",
                        borderRightWidth: 1,
                        borderBottomWidth: 1,
                        backgroundColor: item.attributes.ews_grade.rgba,
                      })
                    }
                  >
                    <Text style={styles.dataText}>
                      {round(item.attributes.ews_grade.erqi, 2) || "-"}
                    </Text>
                  </View>
                  <View
                    style={
                      (styles.tableCell,
                      {
                        width: "10%",
                        borderRightWidth: 1,
                        borderBottomWidth: 1,
                      })
                    }
                  >
                    <View
                      style={
                        (styles.tableCell,
                        {
                          flex: 1,
                          backgroundColor: hexColourFromRgba(
                            xcpihOrXcpi(
                              store.getState().UserState.providerId,
                              item.attributes.xcpih_grade.rgba,
                              item.attributes.xcpi_grade.rgba
                            )
                          ),
                          opacity: hexOpacityFromRgba(
                            xcpihOrXcpi(
                              store.getState().UserState.providerId,
                              item.attributes.xcpih_grade.rgba,
                              item.attributes.xcpi_grade.rgba
                            )
                          ),
                        })
                      }
                    >
                      <Text style={styles.dataText}>
                        {xcpihOrXcpi(
                          store.getState().UserState.providerId,
                          round(item.attributes.xcpih_grade.xcpih, 2) || "-",
                          round(item.attributes.xcpi_grade.xcpi, 2) || "-"
                        )}{" "}
                        (
                        {xcpihOrXcpi(
                          store.getState().UserState.providerId,
                          item.attributes.xcpih_grade.title || "-",
                          item.attributes.xcpi_grade.title || "-"
                        )}
                        )
                      </Text>
                    </View>
                  </View>
                  <View
                    style={
                      (styles.tableCell,
                      {
                        width: "10%",
                        borderRightWidth: 1,
                        borderBottomWidth: 1,
                      })
                    }
                  >
                    <Text style={styles.dataText}>
                      {item.attributes.result.xc_status}
                    </Text>
                    <Text style={styles.dataText}>
                      ({item.attributes.result.xc_code || "-"})
                    </Text>
                  </View>
                  <View
                    style={
                      (styles.tableCell,
                      {
                        width: "10%",
                        borderRightWidth: 1,
                        borderBottomWidth: 1,
                      })
                    }
                  >
                    <Text style={styles.dataText}>
                      {item.attributes.result.xc_jump}
                    </Text>
                  </View>
                </View>
              );
            })
            : null}
        </View>
      </Page>
    </Document>
  );
};

export default PreCompPDF;
