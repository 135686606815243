import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { navigate } from "@reach/router";
import { connect } from "react-redux";
import { format } from "date-fns";
import { checkUserLoggedIn } from "../helperFunctions";
import NavigationTab from "../components/NavigationTab";
import CompetitionTable from "../components/CompetitionTable";
import LoadingSpinner from "../components/LoadingSpinner";
import PostCompetitionWatchlist from "../components/PostCompetitionWatchlists";
import Explainer from "../components/Explainer";
import { parseXcpiGrades } from "../utilities";

import {
  theme,
  Spacer,
  Text,
  Dropdown,
  BackSVG,
} from "equiratings-components";

import {
  getCompetitionOverview,
  getWatchlists,
  getGrades,
} from "../services/postCompetition.js";

const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  justify-content: space-between;
`;

const PostCompetition = ({ selectedCompetition }) => {
  useEffect(() => {
    checkUserLoggedIn();
  }, []);

  const [compOverview, setOverview] = useState([]);
  const [competition, setCompetition] = useState(undefined);
  const [watchlists, setWatchlists] = useState([]);
  const [watchlistsErr, setWatchlistsErr] = useState(false);
  const [overviewErr, setOverviewErr] = useState(false);
  const [grades, setGrades] = useState([]);

  useEffect(() => {
    getGrades()
      .then((response) => setGrades(parseXcpiGrades(response.data.data)))
      .catch((error) => console.log("ERROR", error));

  }, []);

  useEffect(() => {
    getCompetitionOverview(
      competition && [competition],
      selectedCompetition.attributes.venue_id
    )
      .then((response) => setOverview(response.data.data))
      .catch((error) => setOverviewErr(true));

    getWatchlists(selectedCompetition.id, competition)
      .then((response) => setWatchlists(response.data.data))
      .catch((error) => setWatchlistsErr(true));

  }, [
    competition,
    selectedCompetition.attributes.venue_id,
    selectedCompetition.id,
  ]);

  const dataIsPresent = compOverview.length > 0;

  return dataIsPresent ? (
    console.log(watchlists) || (
      <>
        <NavigationTab />
        <Explainer
          text={
            "The following page shows two tables. The first, is a year on year risk overview of your show. The second, shows restricted lists which display all horses that entered with a red HFI across all competitions, as well as the watchlists which show every competitor at a given competition, and their HFI values going into said competition."
          }
        />

        <Spacer size={theme.spacing.x4} />
        <TitleContainer>
          <Text
            tag="h1"
            size={[theme.fontScale.x5]}
            weight={theme.fontWeights.bold}
            lineHeight={theme.lineHeights.heading}
          >
            {selectedCompetition.attributes.name}{" "}
            {format(selectedCompetition.attributes.start_date, "YYYY")}
          </Text>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/post-competition")}
          >
            <BackSVG />
          </div>
        </TitleContainer>
        <Spacer size={theme.spacing.x2} />
        <Text
          tag="h1"
          size={[theme.fontScale.x4]}
          weight={theme.fontWeights.normal}
          lineHeight={theme.lineHeights.heading}
        >
          {format(selectedCompetition.attributes.start_date, "DD/MM/YYYY")}
        </Text>
        <Spacer size={theme.spacing.x5} />
        <Wrapper>
          <Text
            tag="h1"
            size={[theme.fontScale.x4]}
            weight={theme.fontWeights.bold}
            lineHeight={theme.lineHeights.heading}
          >
            Choose a Competition
          </Text>
          <Spacer size={theme.spacing.x3} />
          <Dropdown
            onChange={(level) => setCompetition(level.er_level)}
            readOnly={true}
            items={selectedCompetition.attributes.er_levels.map((item) => item)}
            itemToString={(level) => level.title}
            initialItem={selectedCompetition.attributes.er_levels[0]}
            handleFilter={() => () => true}
            displaySuggestion={(level) => (
              <Text
                tag="h1"
                size={[theme.fontScale.x4]}
                weight={theme.fontWeights.normal}
                lineHeight={theme.lineHeights.heading}
              >
                {level.title}
              </Text>
            )}
          />
        </Wrapper>
        <Spacer size={theme.spacing.x5} />
        <CompetitionTable
          requestsFailed={overviewErr}
          selectedComp={selectedCompetition}
          rows={compOverview}
        />
        <Spacer size={theme.spacing.x5} />
        <PostCompetitionWatchlist
          requestsFailed={watchlistsErr}
          selectedCompetition={selectedCompetition}
          watchlists={watchlists}
          grades={grades}
        />
      </>
    )
  ) : (
    <LoadingSpinner />
  );
};

export default connect(
  ({ PostCompetitionState }) => ({
    selectedCompetition: PostCompetitionState.selectedCompetition,
  }),
  {}
)(PostCompetition);
