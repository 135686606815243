import axios from "axios";
import { format, differenceInCalendarDays } from "date-fns";

const getAccessToken = () => {
  const tokenIsValid =
    format(
      differenceInCalendarDays(Date.now(), localStorage.getItem("timeOfLogin")),
      "DD"
    ) < 3;
  if (!tokenIsValid) {
    axios
      .post(`${process.env.REACT_APP_API_HOST_URL}/tokens`, {
        refresh_token: localStorage.getItem("refreshToken"),
      })
      .then((response) => {
        localStorage.setItem(
          "accessToken",
          response.data.data.attributes.access_token
        );
        localStorage.setItem(
          "refreshToken",
          response.data.data.attributes.refresh_token
        );
      });
  }
  return localStorage.getItem("accessToken");
};

const instance = () => {
  const API_HOST_URL = process.env.REACT_APP_API_HOST_URL;

  const options = {
    baseURL: `${API_HOST_URL}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };
  const client = axios.create(options);
  return client;
};

export const searchHorses = (searchString) => {
  if (searchString) {
    return instance().get(
      `/dashboard/risk/horse_search?horses_filter[name_contains]=${searchString}&sort_direction=asc`
    );
  } else {
    return instance().get(`/dashboard/risk/horse_search?sort_direction=asc`);
  }
};

export const getHorseErqis = (horseId) => {
  return instance().get(`/dashboard/risk/horse_erqis?horse_id=${horseId}`);
};

export const getHorseXcpis = (horseId) => {
  return instance().get(`/dashboard/risk/horse_xcpis?horse_id=${horseId}`);
};

export const getHorseXcpihs = (horseId) => {
  return instance().get(`/dashboard/risk/horse_xcpihs?horse_id=${horseId}`);
};

export const getHorseOverview = (horseId) => {
  return instance().get("/dashboard/risk/dashboard_risk_metrics", {
    params: {
      horse_id: horseId,
    },
  });
};

export const getHorseResults = (parameters) => {
  return instance().get(`/dashboard/risk/dashboard_risk_metrics_erqi`, {
    params: parameters,
  });
};
