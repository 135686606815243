import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import { theme, Spacer } from "equiratings-components";

import Header from "../components/Header";

const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  body {
    margin: 0;
    padding: 0;
    background: ${theme.colors.offWhite};
  }
`;
const ScreenContainer = styled.div`
  max-width: 1220px;
  width: 100%;
  margin: 0 auto;
  flex: 1 0 auto;
  padding: ${theme.spacing.x3} ${theme.spacing.x3};
`;

const Layout = ({ children }) => (
  <>
    <ScreenContainer>
      <GlobalStyle />
      <Header />
      <main>{children}</main>
    </ScreenContainer>
    <ScreenContainer
      style={{
        width: "100%",
        position: "absolute",
        left: 0,
        right: 0,
        bottom: 0
      }}
    />
    <Spacer size={theme.spacing.x4} />
  </>
);

export default Layout;
