import {
  GET_CPR_OVERVIEW_STARTED,
  GET_CPR_OVERVIEW_SUCCESS,
  GET_CPR_OVERVIEW_FAILURE,
  GET_CPR_BY_DATE_STARTED,
  GET_CPR_BY_DATE_SUCCESS,
  GET_CPR_BY_DATE_FAILURE,
} from "./CPRActions";

const initialState = {
  cprByDate: undefined,
  cprByUpcomingComp: undefined,
  loading: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CPR_OVERVIEW_STARTED:
      return {
        ...state,
        cprByDate: undefined,
      };
    case GET_CPR_OVERVIEW_SUCCESS:
      return {
        ...state,
        cprByDate: action.payload.byDate,
        cprByUpcomingComp: action.payload.byUpcomingComp,
        loading: false,
      };
    case GET_CPR_OVERVIEW_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case GET_CPR_BY_DATE_STARTED:
      return {
        ...state,
        cprByDate: undefined,
      };
    case GET_CPR_BY_DATE_SUCCESS:
      return {
        ...state,
        cprByDate: action.payload,
      };
    case GET_CPR_BY_DATE_FAILURE:
      return {
        ...state,
      };
    default:
      return state;
  }
};
