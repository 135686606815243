import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Spacer, theme } from "equiratings-components";
import { format, subDays } from "date-fns";
import NavigationTab from "../components/NavigationTab";
import CPRByDate from "../components/CPRByDate";
import CPRByUpcomingShow from "../components/CPRByUpcomingShow";
import LoadingSpinner from "../components/LoadingSpinner";
import { getCprOverview, getCprByDate } from "../redux/CPRS/CPRActions";
import moment from "moment";

const CPRS = ({
  getCprOverview,
  getCprByDate,
  cprByDate,
  cprByUpcomingComp,
  loading,
}) => {
  const [dateRange, setDate] = useState({
    startDate: moment(subDays(format(Date.now(), "YYYY-MM-DD"), 14)),
    endDate: moment(format(Date.now(), "YYYY-MM-DD")),
  });

  useEffect(() => {
    if (dateRange.startDate && dateRange.endDate) {
      let dateFrom = format(dateRange.startDate, "YYYY-MM-DD");
      let dateTo = format(dateRange.endDate, "YYYY-MM-DD");
      getCprByDate(dateFrom, dateTo);
    }
  }, [dateRange, getCprByDate]);

  useEffect(() => {
    if (dateRange.startDate && dateRange.endDate) {
      let dateFrom = format(dateRange.startDate, "YYYY-MM-DD");
      let dateTo = format(dateRange.endDate, "YYYY-MM-DD");
      getCprOverview(dateFrom, dateTo);
    }
  }, []);
  return !loading && cprByUpcomingComp && cprByDate ? (
    <>
      <NavigationTab />
      {cprByDate ? (
        <CPRByDate
          rows={cprByDate}
          fetchData={getCprByDate}
          dateRange={dateRange}
          setDate={setDate}
        />
      ) : null}
      <Spacer size={theme.spacing.x4} />
      <Spacer size={theme.spacing.x4} />
      {cprByUpcomingComp ? (
        <CPRByUpcomingShow rows={cprByUpcomingComp} />
      ) : null}
    </>
  ) : (
    <LoadingSpinner />
  );
};

export default connect(
  ({ CPRState }) => ({
    cprByDate: CPRState.cprByDate,
    cprByUpcomingComp: CPRState.cprByUpcomingComp,
    loading: CPRState.loading,
  }),
  {
    getCprOverview,
    getCprByDate,
  }
)(CPRS);
