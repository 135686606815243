import { subDays, addDays, format } from "date-fns";
import {
  cprsByDate,
  cprsByUpcomingCompetition,
} from "./../../services/cprReports";

let upcomingCompDateFrom = format(subDays(Date.now(), 2), "YYYY-MM-DD");
let upcomingCompDateTo = format(addDays(Date.now(), 14), "YYYY-MM-DD");

export const GET_CPR_OVERVIEW_STARTED = "GET_CPR_OVERVIEW_STARTED";
export const GET_CPR_OVERVIEW_SUCCESS = "GET_CPR_OVERVIEW_SUCCESS";
export const GET_CPR_OVERVIEW_FAILURE = "GET_CPR_OVERVIEW_FAILURE";

export const GET_CPR_BY_DATE_STARTED = "GET_CPR_BY_DATE_STARTED";
export const GET_CPR_BY_DATE_SUCCESS = "GET_CPR_BY_DATE_SUCCESS";
export const GET_CPR_BY_DATE_FAILURE = "GET_CPR_BY_DATE_FAILURE";

export const getCprOverviewStarted = () => ({
  type: GET_CPR_OVERVIEW_STARTED,
});

export const getCprOverviewSuccess = (payload) => ({
  type: GET_CPR_OVERVIEW_SUCCESS,
  payload,
});

export const getCprOverviewFailure = () => ({
  type: GET_CPR_OVERVIEW_FAILURE,
});

export const getCprByDateStarted = () => ({
  type: GET_CPR_BY_DATE_STARTED,
});

export const getCprByDateSuccess = (payload) => ({
  type: GET_CPR_BY_DATE_SUCCESS,
  payload,
});

export const getCprByDateFailure = () => ({
  type: GET_CPR_BY_DATE_FAILURE,
});

export const getCprOverview = (dateFrom, dateTo) => {
  return (dispatch) => {
    dispatch(getCprOverviewStarted());
    Promise.all([
      cprsByDate(dateFrom, dateTo),
      cprsByUpcomingCompetition(upcomingCompDateFrom, upcomingCompDateTo),
    ])
      .then((values) => {
        let byDate = values[0].data.data;
        let byUpcompingComp = values[1].data.data;
        dispatch(
          getCprOverviewSuccess({
            byDate: byDate,
            byUpcomingComp: byUpcompingComp,
          })
        );
      })
      .catch(() => dispatch(getCprOverviewFailure()));
  };
};

export const getCprByDate = (dateFrom, dateTo) => {
  return (dispatch) => {
    dispatch(getCprByDateStarted());
    cprsByDate(dateFrom, dateTo)
      .then((response) => {
        const cprByDate = response.data.data;
        dispatch(getCprByDateSuccess(cprByDate));
      })
      .catch(() => {
        dispatch(getCprByDateFailure());
      });
  };
};
