import {
  GET_XCPI_ANALYSIS_STARTED,
  GET_XCPI_ANALYSIS_SUCCESS,
  GET_XCPI_ANALYSIS_FAILURE,
  GET_ERQI_ANALYSIS_STARTED,
  GET_ERQI_ANALYSIS_SUCCESS,
  GET_ERQI_ANALYSIS_FAILURE,
  GET_XCPI_ANALYSIS_DATE_STARTED,
  GET_XCPI_ANALYSIS_DATE_SUCCESS,
  GET_XCPI_ANALYSIS_DATE_FAILURE,
  GET_ERQI_ANALYSIS_DATE_STARTED,
  GET_ERQI_ANALYSIS_DATE_SUCCESS,
  GET_ERQI_ANALYSIS_DATE_FAILURE,
  GET_XCPI_ANALYSIS_CLASS_STARTED,
  GET_XCPI_ANALYSIS_CLASS_SUCCESS,
  GET_XCPI_ANALYSIS_CLASS_FAILURE,
  GET_ERQI_ANALYSIS_CLASS_STARTED,
  GET_ERQI_ANALYSIS_CLASS_SUCCESS,
  GET_ERQI_ANALYSIS_CLASS_FAILURE,
  GET_XCPI_ANALYSIS_CATEGORY_STARTED,
  GET_XCPI_ANALYSIS_CATEGORY_SUCCESS,
  GET_XCPI_ANALYSIS_CATEGORY_FAILURE,
  GET_ERQI_ANALYSIS_CATEGORY_STARTED,
  GET_ERQI_ANALYSIS_CATEGORY_SUCCESS,
  GET_ERQI_ANALYSIS_CATEGORY_FAILURE,
} from './RiskAnalysisActions'

const initialState = {
  xcpiByDate: undefined,
  xcpiByClass: undefined,
  xcpiByCategory: undefined,
  xcpiError: undefined,
  erqiByDate: undefined,
  erqiByClass: undefined,
  erqiByCategory: undefined,
  erqiError: undefined,
  loading: false
}

export default (state = initialState, action) => {
  switch(action.type) {
    case GET_XCPI_ANALYSIS_STARTED:
      return {
        ...state,
        xcpiByDate: undefined, 
        xcpiByClass: undefined,
        xcpiByCategory: undefined,
        loading: true
      }
    case GET_XCPI_ANALYSIS_SUCCESS:
      return {
        ...state,
        xcpiByDate: action.payload.xcpiByDate,
        xcpiByClass: action.payload.xcpiByClass,
        xcpiByCategory: action.payload.xcpiByCategory,
        xcpiError: false,
        loading: false,
      }
    case GET_XCPI_ANALYSIS_FAILURE:
      return {
        ...state,
        xcpiError: true,
        loading: false
      }
    case GET_ERQI_ANALYSIS_STARTED:
      return {
        ...state,
       loading: true
      }
    case GET_ERQI_ANALYSIS_SUCCESS:
      return {
        ...state,
        erqiByDate: action.payload.erqiByDate,
        erqiByClass: action.payload.erqiByClass,
        erqiByCategory: action.payload.erqiByCategory,
        erqiError: false,
        loading: false
      }
    case GET_ERQI_ANALYSIS_FAILURE:
      return {
        ...state,
        erqiError: true, 
        loading: false
      }
    case GET_XCPI_ANALYSIS_DATE_STARTED:
      return {
        ...state,
        loading: true
      }
    case GET_XCPI_ANALYSIS_DATE_SUCCESS: 
      return {
        ...state,
        xcpiByDate: action.payload,
        loading: false
      }
    case GET_XCPI_ANALYSIS_DATE_FAILURE:
      return {
        ...state,
        error: true,
        loading: false
      }
    case GET_ERQI_ANALYSIS_DATE_STARTED:
      return {
        ...state,
        loading: true
      }
    case GET_ERQI_ANALYSIS_DATE_SUCCESS: 
      return {
        ...state,
        erqiByDate: action.payload,
        loading: false
      }
    case GET_ERQI_ANALYSIS_DATE_FAILURE:
      return {
        ...state,
        error: true,
        loading: false
      }
    case GET_XCPI_ANALYSIS_CLASS_STARTED:
      return {
        ...state,
        loading: true
      }
    case GET_XCPI_ANALYSIS_CLASS_SUCCESS: 
      return {
        ...state,
        xcpiByClass: action.payload,
        loading: false
      }
    case GET_XCPI_ANALYSIS_CLASS_FAILURE:
      return {
        ...state,
        error: true,
        loading: false
      }
    case GET_ERQI_ANALYSIS_CLASS_STARTED:
      return {
        ...state,
        loading: true
      }
    case GET_ERQI_ANALYSIS_CLASS_SUCCESS: 
      return {
        ...state,
        erqiByClass: action.payload,
        loading: false
      }
    case GET_ERQI_ANALYSIS_CLASS_FAILURE:
      return {
        ...state,
        error: true,
        loading: false
      }
    case GET_XCPI_ANALYSIS_CATEGORY_STARTED:
      return {
        ...state,
        loading: true
      }
    case GET_XCPI_ANALYSIS_CATEGORY_SUCCESS: 
      return {
        ...state,
        xcpiByCategory: action.payload,
        loading:false
      }
    case GET_XCPI_ANALYSIS_CATEGORY_FAILURE:
      return {
        ...state,
        error: true,
        loading: false
      }
    case GET_ERQI_ANALYSIS_CATEGORY_STARTED:
      return {
        ...state,
        loading: true
      }
    case GET_ERQI_ANALYSIS_CATEGORY_SUCCESS: 
      return {
        ...state,
        erqiByCategory: action.payload,
        loading: false
      }
    case GET_ERQI_ANALYSIS_CATEGORY_FAILURE:
      return {
        ...state,
        error: true,
        loading: false
      }
    default: 
      return state
  }

}
