import React from 'react'
import styled from 'styled-components'
import './loadingSpinner.css'

const Container = styled.div`
  width: 100%;
  display: flex;
  margin: 0 auto;
`

const LoadingSpinner = () => {
  return (
    <Container>
      <div className="loader"></div>
    </Container>
  )
}

export default LoadingSpinner
