import React from 'react';
import styled from 'styled-components';
import { round } from '../helperFunctions.js';
import {
  theme,
  Spacer,
  Text,
  Card,
  Table,
  Th,
  Tr,
  Td,
} from 'equiratings-components';

const NoData = styled.div`
  background: ${theme.colors.white};
  width: 100%;
  display: flex;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: ${theme.spacing.x2}
  padding: ${theme.spacing.x6};
  overflow-x: auto;
`;

const CompetitionTable = ({ rows, selectedComp, requestsFailed }) => {
  return !requestsFailed ? (
    <>
      <Text
        tag="h1"
        size={[theme.fontScale.x4]}
        weight={theme.fontWeights.bold}
        lineHeight={theme.lineHeights.heading}>
        {`${selectedComp.attributes.name} - Risk Overview Year by Year`}
      </Text>
      <Spacer size={theme.spacing.x4} />
      {rows.length > 1 ? (
        <Card>
          <Table>
            <tbody>
              <tr>
                <Th>Year</Th>
                <Th>Starters</Th>
                <Th>XC Runs</Th>
                <Th>% Of XC Runs</Th>
                <Th>XCJ Clear Rates</Th>
                <Th>XC Falls</Th>
                <Th>XC Fall Rates</Th>
                <Th>Rider Falls</Th>
                <Th>Rider Fall Rates</Th>
                <Th>Horse Falls</Th>
                <Th>Horse Fall Rates</Th>
              </tr>
              {rows.map((item, index) => {
                return (
                  <Tr key={index}>
                    <Td>{item.attributes.group_value}</Td>
                    <Td>{item.attributes.starters}</Td>
                    <Td>{item.attributes.xc_runs}</Td>
                    <Td>
                      {round(
                        item.attributes.percentage_of_all_xc_runs,
                        1,
                        true,
                      )}
                    </Td>
                    <Td>{round(item.attributes.xcj_clear_rate, 1, true)}</Td>
                    <Td>{item.attributes.all_falls}</Td>
                    <Td>{round(item.attributes.all_fall_rate, 2, true)}</Td>
                    <Td>{item.attributes.rider_falls}</Td>
                    <Td>{round(item.attributes.rider_fall_rate, 2, true)}</Td>
                    <Td>{item.attributes.horse_falls}</Td>
                    <Td>{round(item.attributes.horse_fall_rate, 2, true)}</Td>
                  </Tr>
                );
              })}
            </tbody>
          </Table>
        </Card>
      ) : (
        <NoData>
          <Text
            tag="h1"
            size={[theme.fontScale.x4]}
            weight={theme.fontWeights.normal}
            lineHeight={theme.lineHeights.heading}
            style={{
              color: '#808080',
              margin: '0 auto',
            }}>
            {`We're sorry, we don't seem to have sufficient data for ${selectedComp.attributes.name}.`}
          </Text>
        </NoData>
      )}
    </>
  ) : (
    <>
      <Text
        tag="h1"
        size={[theme.fontScale.x4]}
        weight={theme.fontWeights.bold}
        lineHeight={theme.lineHeights.heading}>
        {`${selectedComp.attributes.name} - Risk Overview Year by Year`}
      </Text>
      <Spacer size={theme.spacing.x4} />
      <NoData>
        <Text
          tag="h1"
          size={[theme.fontScale.x4]}
          weight={theme.fontWeights.normal}
          lineHeight={theme.lineHeights.heading}
          style={{
            color: 'red',
            margin: '0 auto',
          }}>
          Uh Oh! It looks like you've encountered an issue. If it persists
          please contact harry@equiratings.com.
        </Text>
      </NoData>
    </>
  );
};

export default CompetitionTable;
