import React from "react";
import { store } from "../redux/store";
import styled from "styled-components";
import { theme, Text, Spacer } from "equiratings-components";
import { xcpihOrXcpi } from "../utilities";

const CardContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  flex-direction: column;
  @media (${theme.breakpoints.mediumAndUp}) {
    flex-direction: row;
  }
`;

const Card = styled.div`
  width: 25%;
  background: ${theme.colors.white};
  border-radius: ${theme.spacing.x2};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  padding: ${theme.spacing.x2} ${theme.spacing.x2} ${theme.spacing.x2}
    ${theme.spacing.x3};
  margin-bottom: ${theme.spacing.x3};
  &:last-child {
    margin-bottom: 0;
  }
  border-left: ${({ leftBorder }) => `6px solid ${leftBorder}`};
  @media (${theme.breakpoints.mediumAndUp}) {
    margin-right: ${theme.spacing.x3};
    &:last-child {
      margin-right: 0;
    }
    margin-bottom: 0;
  }
`;

const XCPIBreakdown = ({ showErqi2 }) => {
  const xcpihOrXcpiTitle = xcpihOrXcpi(
    store.getState().UserState.providerId,
    "ERQI 2.0",
    "HFI"
  )

  return (
    <>
      <CardContainer>
        <Card leftBorder={"rgba(97, 202, 49, 0.8)"}>
          <Text
            tag="h1"
            size={[theme.fontScale.x3]}
            weight={theme.fontWeights.normal}
            lineHeight={theme.lineHeights.heading}
          >
            <strong>A++</strong> category {xcpihOrXcpiTitle}s are the lowest
            level of risk category, and signify a {xcpihOrXcpiTitle} greater
            than <strong>5</strong>.
          </Text>
        </Card>
        <Card leftBorder={"rgba(97, 202, 49, 0.65)"}>
          <Text
            tag="h1"
            size={[theme.fontScale.x3]}
            weight={theme.fontWeights.normal}
            lineHeight={theme.lineHeights.heading}
          >
            <strong>A+</strong> category {xcpihOrXcpiTitle}s signify a{" "}
            {xcpihOrXcpiTitle} between <strong>4</strong> and <strong>5</strong>
            . Most of your XC runs should fall within this range.
          </Text>
        </Card>
        <Card leftBorder={"rgba(97, 202, 49, 0.5)"}>
          <Text
            tag="h1"
            size={[theme.fontScale.x3]}
            weight={theme.fontWeights.normal}
            lineHeight={theme.lineHeights.heading}
          >
            <strong>A</strong> category {xcpihOrXcpiTitle}s signify a{" "}
            {xcpihOrXcpiTitle} between <strong>3</strong> and <strong>4</strong>.
          </Text>
        </Card>
        <Card leftBorder={"rgba(97, 202, 49, 0.3)"}>
          <Text
            tag="h1"
            size={[theme.fontScale.x3]}
            weight={theme.fontWeights.normal}
            lineHeight={theme.lineHeights.heading}
          >
            <strong>B</strong> category {xcpihOrXcpiTitle}s signify a{" "}
            {xcpihOrXcpiTitle} between <strong>2</strong> and <strong>3</strong>.
          </Text>
        </Card>
      </CardContainer>
      <Spacer size={theme.spacing.x3} />
      <CardContainer>
        <Card leftBorder={"rgba(97, 202, 49, 0.15)"}>
          <Text
            tag="h1"
            size={[theme.fontScale.x3]}
            weight={theme.fontWeights.normal}
            lineHeight={theme.lineHeights.heading}
          >
            <strong>C</strong> category {xcpihOrXcpiTitle}s signify a{" "}
            {xcpihOrXcpiTitle} between <strong>1</strong> and <strong>2</strong>.
          </Text>
        </Card>
        <Card leftBorder={"rgba(255, 188, 71, 0.8)"}>
          <Text
            tag="h1"
            size={[theme.fontScale.x3]}
            weight={theme.fontWeights.normal}
            lineHeight={theme.lineHeights.heading}
          >
            <strong>D</strong> category {xcpihOrXcpiTitle}s signify a{" "}
            {xcpihOrXcpiTitle} between <strong>0</strong> and <strong>1</strong>.
          </Text>
        </Card>
        <Card leftBorder={"rgb(239, 124, 124)"}>
          <Text
            tag="h1"
            size={[theme.fontScale.x3]}
            weight={theme.fontWeights.normal}
            lineHeight={theme.lineHeights.heading}
          >
            <strong>E</strong> category {xcpihOrXcpiTitle}s signify a{" "}
            {xcpihOrXcpiTitle} less than <strong>0</strong>.
          </Text>
        </Card>
        <Card leftBorder={`${theme.colors.lightGrey}`}>
          <Text
            tag="h1"
            size={[theme.fontScale.x3]}
            weight={theme.fontWeights.normal}
            lineHeight={theme.lineHeights.heading}
          >
            White signifies horses who <strong>we do not yet have sufficient data
            on</strong> to calculate a {xcpihOrXcpiTitle}
          </Text>
        </Card>
      </CardContainer>
    </>
  );
};

export default XCPIBreakdown;
